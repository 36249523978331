import React from 'react'
import styled from 'styled-components'

const SelectButtonWrapperWrapper = styled.div`
  .p-button {
    padding: 0.45rem;
    background: transparent !important;
    color: var(--gray-500) !important;
    border-color: var(--gray-100) !important;
    box-shadow: none;

    :hover {
      background: var(--primary-50) !important;
      color: var(--primary-500) !important;
      border-color: var(--primary-500) !important;
    }
  }

  .p-button.p-highlight {
    border-color: var(--gray-500) !important;
    border-right: 1px solid;

    :hover {
      cursor: default;
      background: transparent !important;
      color: var(--gray-500) !important;
      border-color: var(--gray-500) !important;
    }

    + .p-button {
      border-left: none;
    }
  }
`

type SelectButtonWrapperProps = {
  children?: React.ReactNode,
}
export const SelectButtonWrapperLayout = ({
  children
}: SelectButtonWrapperProps) => (
  <SelectButtonWrapperWrapper>
    { children }
  </SelectButtonWrapperWrapper>
)
