import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { REMOVE_ITEM_FOLDER, REMOVE_PRODUCT } from '../../../graphql/mutation'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { DeleteEntityComponent } from '../../component/dialog/delete-entity.component'

type TDeleteProductContainerProps = {
  afterDeleteProduct: () => void
}

export const DeleteEntityContainer = ({
  afterDeleteProduct
}: TDeleteProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const { showDeleteProductDialog = false, selectedEntity, updateProduct } = useContext<TProductContext>(ProductContext)
  const [
    removeProduct,
    {
      error: failedRemovingProduct,
      data: removeProductData,
      loading: removingProduct
    }
  ] = useMutation(REMOVE_PRODUCT)
  const productRemoved = removeProductData?.removeProduct

  useEffect(() => {
    if (!removingProduct && (productRemoved || failedRemovingProduct)) {
      removeFolderCallback()
    }
  }, [ removingProduct, productRemoved, failedRemovingProduct ])

  const [
    removeItemFolder,
    {
      error: failedRemovingItemFolder,
      data: removeItemFolderData,
      loading: removingItemFolder
    }
  ] = useMutation(REMOVE_ITEM_FOLDER)
  const removedItemFolder = removeItemFolderData?.removeItemFolder

  useEffect(() => {
    if (!removingItemFolder && (removedItemFolder || failedRemovingItemFolder)) {
      removeFolderCallback()
    }
  }, [ removingItemFolder, removedItemFolder, failedRemovingItemFolder ])

  const closeDeleteProductDialog = () => {
    updateProduct({ selectedEntity: null, showDeleteProductDialog: false })
  }

  const handleDeleteProduct = () => {
    const productID = selectedEntity?.id
    productID && removeProduct({ variables: { productID } })
  }

  const handleDeleteFolder = () => {
    const folderID = selectedEntity?.id
    folderID && removeItemFolder({ variables: { folderID } })
  }

  const removeFolderCallback = () => {
    try {
      if (failedRemovingProduct) {
        throw failedRemovingProduct
      } else if (failedRemovingItemFolder) {
        throw failedRemovingItemFolder
      } else if (productRemoved || removedItemFolder) {
        afterDeleteProduct()
        closeDeleteProductDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'removeProduct', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'removeProduct', ns: 'product' }), error?.graphQLErrors)
    }
  }

  return (
    <>
      <DeleteEntityComponent
        removingProduct={removingProduct}
        handleDeleteProduct={handleDeleteProduct}
        handleDeleteFolder={handleDeleteFolder}
        closeDeleteProductDialog={closeDeleteProductDialog}
        showDeleteProductDialog={showDeleteProductDialog}
      />

      <Toast data-testid="delete-product-status" ref={toast} position="top-right" />
    </>
  )
}
