import React, { useState } from 'react'

import { ILifecycleProvider } from '../interface/lifecycle-context.type'
import LifecycleContext from './context/lifecycle.context'
import { ViewType } from '../../shared/enum'
import { LifeCycleSortField, Order } from '../../../__generated__/graphql'

type LifecycleProviderProp = {
  children?: React.ReactNode,
  cardPageSize?: number,
  tablePageSize?: number,
  selectedViewType?: ViewType,
  selectedSortBy?: LifeCycleSortField,
  selectedSortOrder?: Order
}
export const LifecycleProvider = ({
  children,
  cardPageSize,
  tablePageSize,
  selectedViewType = ViewType.Grid,
  selectedSortBy = LifeCycleSortField.Name,
  selectedSortOrder = Order.Asc
}: LifecycleProviderProp) => {
  const [ lifecycle, setLifecycle ] = useState<ILifecycleProvider>({
    loadingLifeCycleOverview: true,
    allLifecycles: [],
    totalItems: 0,
    currentPage: 1,
    cardPageSize,
    tablePageSize,
    selectedViewType,
    selectedSortBy,
    selectedSortOrder,
  })

  const updateLifecycle = (newState: ILifecycleProvider = {}) => {
    setLifecycle(prevState => ({ ...prevState, ...newState }))
  }

  return (
    <LifecycleContext.Provider value={{ ...lifecycle, updateLifecycle }}>
      { children }
    </LifecycleContext.Provider>
  )
}
