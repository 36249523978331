import { Tooltip } from 'primereact/tooltip'
import React from 'react'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { DecimalViewType } from '../../../shared/enum'
import { ImpactType } from '../../../shared/enum/impact'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { TDonutItem } from '../../interface/donut-chart.type'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

type TDonutLegendItemProps = {
  donutItem: TDonutItem
}

export const DonutLegendItemComponent = ({
  donutItem
}:TDonutLegendItemProps) => {
  const [ nameRef, hasNameOverflow, nameDom ] = useRefOverflow()
  const { lifecycleTotalImpact } = React.useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { donutItem.name }
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <>
      <Tooltip target={nameDom} onBeforeShow={() => hasNameOverflow} position="bottom">{ getTooltipContent() }</Tooltip>
      <div ref={nameRef} className="flex-grow-1 text-base text-grey-500 white-space-nowrap overflow-hidden text-overflow-ellipsis pr-2">
        { donutItem.name }
      </div>
      <div className="flex white-space-nowrap text-base text-grey-500">
        { donutItem.impactType === ImpactType.Negative && <>-</> }
        <DecimalPointComponent
          value={donutItem.impactAmount}
          enableTooltip
          decimalViewType={DecimalViewType.NumericValue}
        />
        { lifecycleTotalImpact?.unit }
      </div>
    </>
  )
}
