import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'

import { REMOVE_CONVERSION_ITEM } from '../../../graphql/mutation'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { DeleteTreeItemComponent } from '../../component/dialog/delete-tree-item.component'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { ScenarioAction } from '../../../analysis/enum/scenario-action'

type TDeleteProductContainerProps = {
  afterDeleteTreeItem: () => void
}

export const DeleteTreeItemContainer = ({
  afterDeleteTreeItem
}: TDeleteProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const {
    selectedNode,
    analysisMode,
    updateProductDetail,
    handleScenarioAction,
    showDeleteTreeItem = false
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const { data: { inventoryItem = null } = {}, key } = selectedNode || {}
  const { product, conversionID } = inventoryItem || {}
  const [
    removeConversionItem,
    {
      error: failedRemovingConversionItem,
      data: removeConversionItemData,
      loading: removingConversionItem
    }
  ] = useMutation(REMOVE_CONVERSION_ITEM)
  const removedConversionItem = removeConversionItemData?.removeConversionItem

  useEffect(() => {
    if (!removingConversionItem && (removedConversionItem || failedRemovingConversionItem)) {
      removeTreeItemCallback(failedRemovingConversionItem, removedConversionItem)
    }
  }, [ removingConversionItem, removedConversionItem, failedRemovingConversionItem ])

  const closeDeleteTreeItemDialog = () => {
    updateProductDetail({ selectedNode: null, showDeleteTreeItem: false })
  }

  const handleDeleteTreeItem = () => {
    if (!conversionID || !product?.id) return

    const variables = { conversionID, productID: product.id }
    if (analysisMode === AnalysisMode.Scenario && key) {
      handleScenarioAction && handleScenarioAction(ScenarioAction.RemoveConversionItem, variables, key, removeTreeItemCallback)
    } else {
      removeConversionItem({ variables })
    }
  }

  const removeTreeItemCallback = (error?: any, removedItem?: any) => {
    try {
      if (error) {
        throw error
      } else if (removedItem) {
        afterDeleteTreeItem()
        closeDeleteTreeItemDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'removeInventoryItem', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'removeInventoryItem', ns: 'product' }), error?.graphQLErrors)
    }
  }

  return (
    <>
      <DeleteTreeItemComponent
        removingConversionItem={removingConversionItem}
        handleDeleteTreeItem={handleDeleteTreeItem}
        closeDeleteTreeItemDialog={closeDeleteTreeItemDialog}
        showDeleteTreeItem={showDeleteTreeItem}
      />

      <Toast data-testid="delete-product-status" ref={toast} position="top-right" />
    </>
  )
}
