export enum TrackEventType {
  // IDENTIFY EXTRA PROPERTIES
  API_SOURCE = 'Mobius API first_account',
  ADDED_SUBITEM = 'added_subitem',
  ADDED_DATASET = 'added_dataset',
  ADDED_PAYMENT = 'added_payment',
  ADDED_CUSTOM_IMPACT = 'added_custom_impact',
  ADDED_ELEMENTARY_FLOW ='added_elementary_flow',

  // EVENTS
  LOGOUT = 'logout',
  FIRST_ACCOUNT = 'first_account',

  CREATED_ACCOUNT = 'created_account',
  CREATED_WORKSPACE = 'created_workspace',
  CREATED_PHASE = 'created_phase',
  CREATED_PRODUCT = 'created_product',
  CREATED_FOLDER = 'created_folder',
  CREATED_SUBITEM = 'created_subitem',
  CREATED_LIFECYCLE = 'create_lifecycle',
  CREATED_ROOT_PROCESS = 'create_root_process',
  CREATED_SUB_PROCESS = 'created_sub_process',
  CREATED_INPUT_PRODUCT = 'created_input_product',
  CREATED_OUTPUT_PRODUCT = 'created_output_product',
  CHANGED_PHASE_TYPE = 'changed_phase_type',
  CREATED_PRODUCT_SCENARIO = 'created_product_scenario',
  CREATED_LIFECYCLE_SCENARIO = 'created_lifecycle_scenario',

  CHANGED_IMPACT_SETTINGS = 'changed_impact_settings',
  CHANGED_CUSTOM_IMPACT = 'changed_custom_impact',
  CHANGED_IMPACT_SETTINGS_CATEGORY = 'changed_impact_settings_category',

  DELETED_PHASE = 'deleted_phase',
  DELETED_WORKSPACE = 'deleted_workspace',
  DELETED_PRODUCT = 'deleted_product',
  DELETED_SUBITEM = 'deleted_subitem',

  DOWNLOADED_EXCEL_RESULTS = 'downloaded_excel_results',
  DOWNLOADED_PDF_RESULTS = 'downloaded_pdf_results',
  DOWNLOADED_IMAGE_TABLE = 'downloaded_image_table',
  DOWNLOADED_IMAGE_BAR_CHART = 'downloaded_image_bar_chart',
  DOWNLOADED_IMAGE_DONUT_CHART = 'downloaded_image_donut_chart',

  EXPORTED_INVENTORY = 'exported_inventory',

  INVITED_USER = 'invited_user',

  OPENED_PRODUCT_SCENARIO = 'opened_product_scenario',
  OPENED_LIFECYCLE_SCENARIO = 'opened_lifecycle_scenario',
  OPENED_PRODUCT_LIFECYCLE = 'opened_product_lifecycle',
  OPENED_PERSONAL_WORKSPACE = 'opened_personal_workspace',
  OPENED_DATABASE_SEARCH_WINDOW = 'opened_database_search_window',
  OPENED_DEMO_WORKSPACE = 'opened_demo_workspace',
  OPENED_COMPARISON = 'opened_comparison',
  OPENED_ROOT_PROCESS = 'opened_root_process',
  MOVED_TO_FOLDER = 'moved_to_folder',
  MOVED_TO_PRODUCT = 'moved_to_product',
  MOVED_TO_OBJECT = 'moved_to_object',
  VIEWED_FOLDER = 'viewed_folder',
  VIEWED_PRODUCT = 'viewed_product',
  VIEWED_LIFECYCLE = 'viewed_lifecycle',

  REMOVED_USER = 'removed_users',
  REORDERED_PHASE = 'reordered_phase',
  RENAMED_PHASE = 'renamed_phase',

  SUBSCRIPTION_PURCHASED = 'subscription_purchased',
  SUBSCRIPTION_CANCELED = 'subscription_canceled',
  SUBSCRIPTION_CHANGED = 'subscription_changed',

  VIEWED_HELP_CENTER = 'viewed_help_center',
  VIEWED_HELP_CENTER_LINK_1 = 'viewed_help_center_link_1',
  VIEWED_HELP_CENTER_LINK_2 = 'viewed_help_center_link_2',
  VIEWED_HELP_CENTER_LINK_3 = 'viewed_help_center_link_3',
  VIEWED_HELP_CENTER_LINK_4 = 'viewed_help_center_link_4',
  VIEWED_HELP_CENTER_LINK_5 = 'viewed_help_center_link_5',
  VIEWED_CHANGELOG = 'viewed_changelog',
  COPIED_LIFECYCLE = 'copied_lifecycle',
  SWITCH_ACCOUNT = 'switch_account',
  SWITCH_WORKSPACE = 'switch_workspace',
  OPENED_SETTINGS = 'opened_settings',
  OPENED_USER_PROFILE = 'opened_user_profile',
  OPENED_WORKSPACE_HOME = 'opened_workspace_home',
  TRIAL_EXTENDED = 'trial_extended',
  IMPORTED_PRODUCT = 'imported_product',
  SELECTED_INDUSTRY_TYPE = 'selected_industry_type',

  CLICKED_CREATE_ACCOUNT = 'clicked_create_account',
  CLICKED_PRODUCT_TEMPLATE = 'clicked_product_template',

  // EVENT VIEWS
  VIEWED_TREEVIEW = 'viewed_treeview',
  VIEWED_FLATVIEW = 'viewed_flatview',
  VIEWED_BARCHART = 'viewed_barchart',
  VIEWED_SUNBURST = 'viewed_sunburst',
  VIEWED_MODEL = 'viewed_model',

  VIEWED_PRODUCT_TABLE = 'viewed_product_table',
  VIEWED_PRODUCT_BAR_CHART = 'viewed_product_bar_chart',
  VIEWED_PRODUCT_DONUT_CHART = 'viewed_product_donut_chart',
  VIEWED_PRODUCT_STACKED_CHART = 'viewed_product_stacked_chart',

  VIEWED_LIFECYCLE_TABLE = 'viewed_lifecycle_table',
  VIEWED_LIFECYCLE_BAR_CHART = 'viewed_lifecycle_bar_chart',
  VIEWED_LIFECYCLE_DONUT_CHART = 'viewed_lifecycle_donut_chart',
  VIEWED_LIFECYCLE_MODEL = 'viewed_lifecycle_model',

  VIEWED_REFERENCE_SEARCH = 'viewed_reference_search',
  VIEWED_ELEMENTARY_FLOWS_SEARCH = 'viewed_elementary_flows_search',
  VIEWED_CUSTOM_IMPACT_FORM = 'viewed_custom_impact_form',
  VIEWED_WORKSPACE_TOUR = 'viewed_workspace_tour',
  VIEWED_WORKSPACE_EXERCISE = 'viewed_workspace_exercise',
  VIEWED_WORKSPACE_APPOINTMENT_TRIAL = 'viewed_workspace_appointment_trial',
  VIEWED_WORKSPACE_APPOINTMENT_PAID = 'viewed_workspace_appointment_paid',

  // PAGE VIEWS
  VIEWED_WORKSPACE = 'viewed_workspace',
  VIEWED_PRODUCTS_OVERVIEW = 'viewed_products_overview',
  VIEWED_OBJECTS_OVERVIEW = 'viewed_objects_overview',
  VIEWED_PROCESSES_OVERVIEW = 'viewed_processes_overview',
  VIEWED_USERS_SETTINGS = 'viewed_users_settings',
  VIEWED_ROLES_SETTINGS = 'viewed_roles_settings',
  VIEWED_PERMISSIONS_SETTINGS = 'viewed_permissions_settings',
  VIEWED_API_CLIENTS_SETTINGS = 'viewed_api_clients_settings',
  VIEWED_BILLING_SETTINGS = 'viewed_billing_settings',
  VIEWED_USER_PROFILE = 'viewed_user_profile',
  VIEWED_ACCOUNT_OVERVIEW = 'viewed_account_overview',
  VIEWED_LIFECYCLE_OVERVIEW = 'viewed_lifecycle_overview',
  VIEWED_PRODUCT_OVERVIEW = 'viewed_product_overview',
  VIEWED_WORKSPACE_WELCOME = 'viewed_workspace_welcome',
}
