import { createContext } from 'react'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'

const DashboardContext = createContext<IDashboardContext>({
  openedSidebar: false,
  collapsedNavigation: false,
  openWorkspaceSetting: () => {},
  openTalkToExpert: () => {},
  updateDashboard: () => {},
  openSidebar: () => {},
  closeSidebar: () => {}
})

export default DashboardContext
