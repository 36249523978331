import React, { useState } from 'react'
import AnalysisContext from './context/analysis.context'
import { TAnalysisProvider } from '../interface/analysis.context'
import { AnalysisMode, AnalysisType } from '../enum/analysis'
import { ImpactType } from '../../shared/enum/impact'
import { getChangeRatio } from '../../shared/util/decimal'
import { ComparisonEntity } from '../enum/entity'
import { ProductScenarioMutations } from '../../../utils/ProductMutator'

type TAnalysisProviderProps = {
  analysisMode: AnalysisMode,
  analysisType: AnalysisType,

  primaryEntityId?: string,
  secondaryEntityId?: string,
  secondaryEntityType?: string | null,

  children?: React.ReactNode
}

export const AnalysisProvider = ({
  analysisMode,
  analysisType,
  primaryEntityId,
  secondaryEntityId,
  secondaryEntityType,
  children
}: TAnalysisProviderProps) => {
  const [ analysis, setAnalysis ] = useState<TAnalysisProvider>({
    entity: ComparisonEntity.Primary,
    primaryEntityId,
    secondaryEntityId,
    secondaryEntityType
  })

  const updateAnalysis = (newState: TAnalysisProvider) => {
    setAnalysis(prevState => ({ ...prevState, ...newState }))
  }

  const getImpactEffect = (isPrimary: boolean) => {
    const {
      primaryTotalImpact: { amount: primaryTotalImpactAmount = '' } = {},
      secondaryTotalImpact: { amount: secondaryTotalImpactAmount = '' } = {}
    } = analysis
    const fSecondaryTotalImpactAmount = parseFloat(secondaryTotalImpactAmount)
    const fPrimaryTotalImpactAmount = parseFloat(primaryTotalImpactAmount)
    if (fSecondaryTotalImpactAmount === fPrimaryTotalImpactAmount) return null

    const isGreaterThanPrimary = fSecondaryTotalImpactAmount > fPrimaryTotalImpactAmount

    if (isPrimary) {
      return !isGreaterThanPrimary ? ImpactType.Positive : ImpactType.Negative
    }

    return isGreaterThanPrimary ? ImpactType.Positive : ImpactType.Negative
  }

  const getImpactChangeRatio = (isPrimary: boolean) => {
    const {
      primaryTotalImpact: { amount: primaryTotalImpactAmount = '' } = {},
      secondaryTotalImpact: { amount: secondaryTotalImpactAmount = '' } = {}
    } = analysis

    const fPrimaryTotalImpactAmount = parseFloat(primaryTotalImpactAmount)
    const fSecondaryTotalImpactAmount = parseFloat(secondaryTotalImpactAmount)
    if (isPrimary) {
      return getChangeRatio({
        finalValue: fPrimaryTotalImpactAmount,
        startingValue: fSecondaryTotalImpactAmount
      })
    }

    return getChangeRatio({
      finalValue: fSecondaryTotalImpactAmount,
      startingValue: fPrimaryTotalImpactAmount
    })
  }

  // TODO : V0.9 To be removed
  const getProductScenarioMutatorV1 = (productId: string) => {
    const { secondaryEntityId, scenarioSelectedKey } = analysis

    if (secondaryEntityId && scenarioSelectedKey) {
      return new ProductScenarioMutations(secondaryEntityId, scenarioSelectedKey, productId)
    }
  }

  return (
    <AnalysisContext.Provider value={{
      ...analysis,
      analysisMode,
      analysisType,
      updateAnalysis,
      getImpactEffect,
      getImpactChangeRatio,
      getProductScenarioMutatorV1
    }}
    >
      { children }
    </AnalysisContext.Provider>
  )
}
