import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft, faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FiExternalLink } from 'react-icons/fi'
import styled from 'styled-components'
import { SimpleCardTemplateComponent } from '../../shared/component/general/simple-card-template.component'
import { ExternalSources } from '../../shared/enum'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const ItemWrapper = styled.div`
  &.w-full {
    height: 2.625rem;
  }

  &:hover {
    cursor: pointer;
    background: var(--gray-50)
  }
`

export const WorkspaceHelpCenterComponent = () => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const [ trackEventInSegment ] = getSegmentTrack()

  const openHelpCenter = (path: ExternalSources, eventType: TrackEventType) => {
    trackEventInSegment(eventType)
    window.open(path)
  }

  const header = (
    <div className="text-base font-semibold">
      { t('labels.helpCenter', { ns: 'common' }) }
    </div>
  )

  const helpCenterContentList = [
    {
      icon: <FontAwesomeIcon className="mt-1" icon={faAlignLeft} size="lg" />,
      iconClassName: 'border-round-xl bg-gray-50 text-gray-900 py-2 px-3',
      label: t('labels.helpCenter', { context: 'beginnerGuide' }),
      duration: t('labels.helpCenter', { context: 'duration', min: '8' }),
      onclick: () => openHelpCenter(ExternalSources.HelpCenterBeginnerGuid, TrackEventType.VIEWED_HELP_CENTER_LINK_1)
    },
    {
      icon: <FontAwesomeIcon icon={faPlay} size="lg" style={{ margin: '0.45rem 0 0 0.125rem' }} />,
      iconClassName: 'border-round-xl bg-red-600 text-white py-1 px-3',
      label: t('labels.helpCenter', { context: 'gettingStarted' }),
      duration: t('labels.helpCenter', { context: 'durationEach', min: '~3' }),
      onclick: () => openHelpCenter(ExternalSources.HelpCenterGettingStarted, TrackEventType.VIEWED_HELP_CENTER_LINK_2)
    },
    {
      icon: <FontAwesomeIcon className="mt-1" icon={faAlignLeft} size="lg" />,
      iconClassName: 'border-round-xl bg-gray-50 text-gray-900 py-2 px-3',
      label: t('labels.helpCenter', { context: 'lcaDatabases' }),
      duration: t('labels.helpCenter', { context: 'duration', min: '6' }),
      onclick: () => openHelpCenter(ExternalSources.HelpCenterLCADatabases, TrackEventType.VIEWED_HELP_CENTER_LINK_3)
    },
    {
      icon: <FontAwesomeIcon icon={faPlay} size="lg" style={{ margin: '0.45rem 0 0 0.125rem' }} />,
      iconClassName: 'border-round-xl bg-red-600 text-white py-1 px-3',
      label: t('labels.helpCenter', { context: 'nextSteps' }),
      duration: t('labels.helpCenter', { context: 'durationEach', min: '~5' }),
      onclick: () => openHelpCenter(ExternalSources.HelpCenterNextSteps, TrackEventType.VIEWED_HELP_CENTER_LINK_4)
    },
    {
      icon: <FontAwesomeIcon icon={faPlay} size="lg" style={{ margin: '0.45rem 0 0 0.125rem' }} />,
      iconClassName: 'border-round-xl bg-red-600 text-white py-1 px-3',
      label: t('labels.helpCenter', { context: 'fundamentals' }),
      duration: t('labels.helpCenter', { context: 'durationEach', min: '~3' }),
      onclick: () => openHelpCenter(ExternalSources.HelpCenterFundamentals, TrackEventType.VIEWED_HELP_CENTER_LINK_5)
    },
  ]

  return (
    <SimpleCardTemplateComponent
      header={header}
      boxShadow="0px 6px 30px 6px rgba(0, 0, 0, 0.05)"
      className="bg-white border-round-lg text-base font-medium text-gray-800 p-4"
      headerClassName="border-gray-200 border-bottom-1"
    >

      <div data-testid="help-center-card" className="flex flex-column w-full h-full pt-2">
        {
          helpCenterContentList.map((item, index) => (
            <ItemWrapper data-testid={`help-center-link-${index}`} onClick={item.onclick} key={`help-center-${index}`} className="flex w-full my-2 p-0 border-round-xl">
              <div className={`flex-none w-3rem h-full ${item.iconClassName}`}>
                {item.icon}
              </div>
              <div className="flex flex-column flex-grow-1 pl-3">
                <div className="w-full text-sm font-normal text-gray-700">{item.label}</div>
                <div className="w-full text-xs font-normal text-gray-300">{item.duration}</div>
              </div>
            </ItemWrapper>
          ))
        }
        <div className="flex w-full pt-3 pb-2">
          <Button
            onClick={() => openHelpCenter(ExternalSources.HelpCenterMobius, TrackEventType.VIEWED_HELP_CENTER)}
            icon={<FiExternalLink className="mr-1" size={20} />}
            label={t('labels.helpCenter', { context: 'moreOnHelpCenter' })}
            className="p-button-link text-primary-500 p-0 cursor-pointer line-height-2 text-sm"
          />
        </div>
      </div>
    </SimpleCardTemplateComponent>
  )
}
