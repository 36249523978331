import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import { LinkBoxComponent } from '../../shared/component/general/link-box.component'
import { IProductTemplate, IProductTemplateCategory } from '../interface/product-template'
import { ProductTemplate } from '../../../__generated__/graphql'

const Wrapper = styled.div`
  background: var(--primary-50);
  border: 1px solid var(--primary-100);

  .product-templates-list{
    height: 6.6rem;
    overflow-y: hidden;
    transition: height 0.2s;
    &.expanded {
      height: 30rem;
      overflow-y: auto;
    }
  }
`
type TProductTemplatesComponentProps = {
  templatesByCategory: IProductTemplateCategory[]
  isListExpanded?: boolean
  setIsListExpanded?: (status: boolean) => void
  setActiveTemplate?: (activeTemplate: IProductTemplate) => void
}

export const ProductTemplatesLayout = ({
  templatesByCategory = [],
  isListExpanded = false,
  setIsListExpanded = () => {},
  setActiveTemplate = () => {}
}: TProductTemplatesComponentProps) => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const divRef = useRef<HTMLDivElement>(null)

  const toggleAllTemplates = () => {
    if (divRef?.current && isListExpanded) {
      divRef.current.scroll({
        top: 0
      })
    }
    setIsListExpanded(!isListExpanded)
  }

  return (
    <div className="px-4">
      <Wrapper className="w-full border-round-xl px-3 pt-4 pb-1">
        <div className="px-2 text-base font-semibold">
          {t('labels.productTemplates', { context: 'title' })}
        </div>
        <div ref={divRef} className={classNames('pt-2 product-templates-list', { expanded: isListExpanded })}>
          {templatesByCategory.map((category:IProductTemplateCategory, i: number) => (
            <React.Fragment key={i}>
              <div className="text-base px-2" data-testid={`template-category-${category.industry}`}>{t('labels.industry', { context: category.industry, ns: 'common' })}</div>
              <div className="flex flex-wrap align-content-start align-items-start pb-2">
                {category.templates.map((template:IProductTemplate) => (
                  <div key={`product-template-${template.key}`} className="w-11rem p-2 h-5rem">
                    <LinkBoxComponent
                      type={template.key === ProductTemplate.ProductStructure ? 'primary-2' : 'primary-1'}
                      dataTestId={`product-template-${template.key}`}
                      onClick={() => setActiveTemplate(template)}
                      className="border-round-lg p-3"
                      icon={<FontAwesomeIcon icon={template.iconName} />}
                      iconClassName="border-circle"
                      subtitle={t('labels.productTemplateName', { context: template.name })}
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="text-center pt-1">
          <FontAwesomeIcon
            className={classNames('cursor-pointer', { 'rotate-180': isListExpanded })}
            icon={faChevronDown}
            onClick={toggleAllTemplates}
          />
        </div>
      </Wrapper>
    </div>
  )
}
