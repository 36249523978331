import React, { useContext, useRef, useState } from 'react'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import {
  // faCircleInfo,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createGlobalStyle } from 'styled-components'
import { OverlayPanel } from 'primereact/overlaypanel'

import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'

const GlobalStyle = createGlobalStyle`
  .p-inputtext {
    width: 12rem !important;
    border-color: var(--gray-100) !important;
  }
  .p-inputtext::placeholder {
    color: var(--gray-300);
    opacity: 1; /* Firefox */
  }
  .p-inputtext:focus {
    box-shadow: none !important;
  }
`

type TGeographyFilterComponentProps = {
  label?: string,
}

export const GeographyFilterComponent = ({
  label = '',
}: TGeographyFilterComponentProps) => {
  const {
    locationQuery = '',
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { t } = useTranslation([ 'common' ])
  const overlayPanel = useRef<OverlayPanel>(null)
  const [ searchValue, setSearchValue ] = useState<string>(locationQuery)

  const filterByLocation = (locationQuery: string = '') => {
    updateFilterValue({ locationQuery })
    overlayPanel.current?.hide()
  }

  const clearFilter = () => {
    filterByLocation()
    setSearchValue('')
  }

  return (
    <>
      <GlobalStyle />

      <OverlayPanel ref={overlayPanel}>
        <div className="flex flex-column w-full">
          <div className="flex w-full pb-3 line-height-1 text-sm font-medium border-bottom-1 border-gray-100">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
          </div>
          <div className="flex align-items-center pt-3 w-15rem">
            <div className="p-inputgroup">

              <span className="p-inputgroup-addon bg-white px-2 min-w-min">
                <i className="pi pi-search text-sm" />
              </span>

              <InputText
                data-testid="filter-location-input"
                autoFocus
                value={searchValue}
                onChange={(event: any) => setSearchValue(event.target.value)}
                className="w-full h-2rem border-left-none border-right-none  text-xs px-1"
                placeholder="Search by location"
              />

              <span className="p-inputgroup-addon border-left-none bg-white px-2 min-w-min h-2rem">
                { searchValue && <FontAwesomeIcon data-testid="clear-search-fields" icon={faXmark} className="text-basis text-gray-300 cursor-pointer" onClick={clearFilter} /> }
              </span>
            </div>
          </div>
          <div className="flex justify-content-end w-full border-top-1 border-gray-100 pt-3 mt-3">
            <Button
              label={t('labels.apply')}
              data-testid="filter-location-apply"
              onClick={() => filterByLocation(searchValue)}
              className="p-button-primary h-2rem"
            />
          </div>
        </div>
      </OverlayPanel>

      <span
        data-testid="table-header-geography-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {
          'text-primary-500': locationQuery
        })}
      >
        { label }
        <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
      </span>
    </>
  )
}
