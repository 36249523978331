import { Card, Col, Row } from 'antd'
import { EuroOutlined, SettingOutlined, TagOutlined, UserOutlined, CrownOutlined } from '@ant-design/icons'
import React from 'react'
import classnames from 'classnames'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { safeArray, returnNested } from '../../utils/tools'
import { Frame } from '../layout'
import WorkspaceDetails from '../model/details-panel/workspace-details/workspaceDetails.container'
import PageHeader from '../model/page-header/page-header'
import {
  AccountSettingPageLocation,
  tabUsers,
  tabAccount,
  tabRoles,
  tabPermissions,
  tabBilling
} from '../shared/locations'
import Billing from './billing.container'
import PermissionManager from './permissionManager.container'
import RoleManagement from './roleManagement.container'
import UserManagement from './userManagement.container'
import AccountManagement from './accountManagement.container'
import DetailsPanel from '../model/details-panel/detailsPanel.container'
import DashboardContext from '../../v1/dashboard/context/dashboard.context'

class AccountSettings extends React.Component {
  static contextType = DashboardContext

  constructor(props) {
    super(props)
    this.state = {
      showPermission: false,
    }
    this.componentCleanup = this.componentCleanup.bind(this)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup)

    const {
      accountId,
      sessionId,
      stripeSuccess,
      stripeMessage,
      canManageAccount,
      canManageBilling,
      loadAccountSettingsSaga,
      setIsDetailsPanelOpenAction,
      setAccountUsersAndRolesSaga
    } = this.props
    setIsDetailsPanelOpenAction(false)
    if (sessionId === undefined && (canManageAccount || canManageBilling)) {
      loadAccountSettingsSaga(accountId)
      if (canManageAccount) setAccountUsersAndRolesSaga(accountId)
    }

    if (stripeSuccess !== undefined && stripeMessage !== undefined) {
      const notificator = stripeSuccess
        ? this.props.addSuccessNotificationAction
        : this.props.addErrorNotificationAction
      notificator(stripeMessage)
      if (sessionId !== undefined && (canManageAccount || canManageBilling)) {
        loadAccountSettingsSaga(accountId, sessionId)
        if (canManageAccount) setAccountUsersAndRolesSaga(accountId)
      }
    }
  }

  componentWillUnmount() {
    this.componentCleanup()
    window.removeEventListener('beforeunload', this.componentCleanup)
  }
  componentCleanup = () => {
    this.props.setSubscriptionProductsAction([])
    this.props.setAccountSubscriptionAction(null)
    this.props.setAccountInvoicesAction([])
    // TODO add set all billing info to null
  }
  handleTabsOnChange = activeKey => {
    this.props.navigate(AccountSettingPageLocation.toUrl({
      activeTabKey: activeKey,
    }))
  }

  getTabList = () => {
    const { canManageAccount, canManageBilling, t } = this.props
    const accountManagerTabs = canManageAccount && [
      { key: tabUsers, tab: <span data-cy='user_management_tab'><UserOutlined /> {t('account_settings.user_management')}</span> },
      { key: tabRoles, tab: <span data-cy='role_management_tab'><TagOutlined /> {t('account_settings.role_management')}</span> },
      { key: tabPermissions, tab: <span data-cy='permission_management_tab'><SettingOutlined /> {t('account_settings.permission_manager')}</span> },
    ]

    const billingManagerTabs = (canManageAccount || canManageBilling) &&[
      { key: tabBilling, tab: <span data-cy='billing_management_tab'><EuroOutlined data-cy='billing_tab' /> {t('account_settings.billing')}</span> }
    ]

    return [
      ...safeArray(accountManagerTabs),
      ...safeArray(billingManagerTabs),
      { key: tabAccount, tab: <span data-cy='account_management_tab'><CrownOutlined /> {t('account_settings.account')}</span> },
    ]
  }

  getTabContent = activeTabKey => {
    const {
      accountId,
      accountRolesList,
      accountUsersList,
    } = this.props
    const contentList = {
      [tabUsers]: <UserManagement
        accountId={accountId}
        accountUsersList={accountUsersList}
        accountRolesList={accountRolesList}
      />,
      [tabRoles]: <RoleManagement
        accountId={accountId}
        accountUsersList={accountUsersList}
        accountRolesList={accountRolesList}
      />,
      [tabPermissions]: <PermissionManager
        accountId={accountId}
        accountUsersList={accountUsersList}
        accountRolesList={accountRolesList}
      />,
      [tabAccount]: <AccountManagement />,
      [tabBilling]: <Billing />
    }
    return contentList[activeTabKey]
  }

  render() {
    const {
      t,
      accountName,
      activeTabKey
    } = this.props
    const { showTopBanner } = this.context
    const currentActiveTabKey = activeTabKey || returnNested(this.getTabList(), 0, 'key')

    return (
      <Frame>
        {() =>
          <>
            <Row>
              <Col span={24} className={classnames('middle-panel', {
                'middle-panel--withBanner': showTopBanner
              })}>
                <PageHeader headerTitle={`${t('account_settings.title')}: ${accountName}`}/>
                <div className="account-settings">
                  <Card
                    className="settings-card"
                    tabList={this.getTabList()}
                    activeTabKey={currentActiveTabKey}
                    onTabChange={this.handleTabsOnChange}
                    tabProps={{ size: 'small' }}
                  >
                    {this.getTabContent(currentActiveTabKey)}
                  </Card>
                </div>
              </Col>
            </Row>
            <DetailsPanel>
              <WorkspaceDetails />
            </DetailsPanel>
          </>
        }
      </Frame>
    )
  }
}
export { AccountSettings }
export default compose(
  withTranslation(),
)(AccountSettings)
