import React, { useContext, useEffect, useRef } from 'react'
import { classNames } from 'primereact/utils'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'

import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'

import { IPhase } from '../../model'
import { PhaseItemComponent } from '../component/phase/phase-item.component'
import { REORDER_PHASES } from '../../graphql/mutation'
import { AddPhaseContainer } from './add-phase.container'
import { displayGraphqlErrors } from '../../shared/util/error'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const PhaseListWrapper = styled.div`
  .draggable-overlay {
    background: var(--green-100);
  }

  .clear {
    clear: both;
  }
`
export const PhaseListContainer = () => {
  const { t } = useTranslation([ 'lifecycle', 'common' ])
  const toast = useRef<Toast>(null)
  const {
    lifecycleDetail = {},
    updateLifecycleDetail,
    refetchLifecycle,
    enableAddingPhase,
    enabledDragPhaseId,
    expandedPhases = [],
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { phases = [] } = lifecycleDetail

  const [
    reorderPhases,
    {
      error: failedReorderingPhases,
      data: reorderedPhasesData,
      loading: reorderingPhases
    }
  ] = useMutation(REORDER_PHASES)

  useEffect(() => {
    if (!reorderingPhases && (reorderedPhasesData?.reorderPhases || failedReorderingPhases)) {
      reorderPhaseCallback()
    }
  }, [ reorderingPhases, reorderedPhasesData, failedReorderingPhases ])

  useEffect(() => {
    if (phases.length > 0) {
      changeDroppableHeight(phases.length * 65)
    }
  }, [ phases.length ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const reorderPhaseCallback = () => {
    try {
      if (failedReorderingPhases) {
        throw failedReorderingPhases
      } else if (reorderedPhasesData?.reorderPhases) {
        trackEventInSegment(TrackEventType.REORDERED_PHASE)
        refetchLifecycle && refetchLifecycle()
        updateLifecycleDetail({ enableAddingPhase: false })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.reorderPhase', { context: 'success' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.reorderPhase', { context: 'error' }), error?.graphQLErrors)
    }
  }

  type StyleProps = { height?: string, transform?: string, background?: string }
  const setOverlayStyle = (index: number, { height = '0', transform, background }: StyleProps = {}) => {
    const overlayDom = document.querySelector<HTMLDivElement>(`.draggable-overlay-${index}`)

    if (overlayDom) {
      overlayDom.style.height = height
      if (transform) overlayDom.style.transform = transform
      if (background) overlayDom.style.background = background
    }
  }

  const setPanelDimension = (index: number, { width, height }: { width?: string, height?: string } = {}) => {
    const panelDom = document.querySelector<HTMLDivElement>(`.draggable-panel-${index}`)
    if (!panelDom) return

    if (width) panelDom.style.width = width
    if (width !== '100%') panelDom.style.left = '380px'

    if (height) panelDom.style.height = height
  }

  const changeDroppableHeight = (height: number) => {
    const droppableDom = document.querySelector<HTMLDivElement>('.droppable-panel')
    if (!droppableDom) return

    droppableDom.style.minHeight = `${height}px`
  }

  const removePanelStyle = (index: number) => {
    const panelDom = document.querySelector<HTMLDivElement>(`.draggable-panel-${index}`)
    panelDom && panelDom.removeAttribute('style')
  }

  const overrideTransform = (sourceIndex: number, destinationIndex: number, onStart: boolean = false) => {
    let i = 0
    while (i < phases.length) {
      const panelDom = document.querySelector<HTMLDivElement>(`.draggable-panel-${i}`)
      if (!panelDom) break

      if (onStart && i > sourceIndex) {
        panelDom.style.transform = 'translate(0, 58px)'
      } else if (!onStart) {
        if (destinationIndex < sourceIndex && i < sourceIndex && i >= destinationIndex) {
          panelDom.style.transform = 'translate(0, 58px)'
        } else if (destinationIndex > sourceIndex && i > sourceIndex && i <= destinationIndex) {
          panelDom.style.transform = 'translate(0, 0)'
        }
      }
      i++
    }
  }

  const clearOverlay = () => {
    let i = 0
    const emptyOverlayStyle = { height: '0', background: 'var(--green-100)' }
    while (i < phases.length) {
      setOverlayStyle(i, emptyOverlayStyle)
      i++
    }
  }

  const handleOnDragStart = (result: any) => {
    if (!result?.source) return

    updateLifecycleDetail({ isDraggingPhase: true })

    clearOverlay()
    changeDroppableHeight(phases.length * 65)
    overrideTransform(result.source.index, 0, true)

    setTimeout(() => {
      setPanelDimension(result.source.index, { width: 'calc(100vw - 500px)', height: '2.5rem' })
      setOverlayStyle(result.source.index, { height: '3rem', transform: 'translate(0, -5px)' })
    })
  }

  const handleOnDragUpdate = (result: any) => {
    if (!result?.source && !result?.destination) return

    clearOverlay()

    const { index: sourceIndex } = result.source
    const { index: destinationIndex } = result.destination

    const transform = sourceIndex > destinationIndex ? 'translate(0, -45px)' : 'translate(0, 20px)'
    setOverlayStyle(destinationIndex, { height: '0.25rem', transform, background: 'var(--green-300)' })
    overrideTransform(sourceIndex, destinationIndex)
  }

  const handleOnDragEnd = (result: any) => {
    if (!result?.destination || !result?.source) return
    updateLifecycleDetail({ isDraggingPhase: false })

    const tempPhases = Array.from(phases)
    const [ draggedItem ] = tempPhases.splice(result?.source?.index, 1)

    clearOverlay()

    setPanelDimension(result?.source?.index, { width: '100%' })
    removePanelStyle(result.source.index)

    if (draggedItem && result.destination.index !== result.source.index) {
      draggedItem && tempPhases.splice(result?.destination?.index, 0, draggedItem)
      lifecycleDetail.id && reorderPhases({ variables: { lifecycleID: lifecycleDetail.id, phases: tempPhases.map(phase => phase.id) } })
      updateLifecycleDetail({ lifecycleDetail: { ...lifecycleDetail, phases: tempPhases }, enabledDragPhaseId: null })
    }
  }

  const isDragDisabled = (phase: IPhase) => (expandedPhases.length > 0 || !enabledDragPhaseId || phase.id !== enabledDragPhaseId)

  return (
    <DragDropContext
      onDragStart={handleOnDragStart}
      onDragUpdate={handleOnDragUpdate}
      onDragEnd={handleOnDragEnd}
    >
      <Toast ref={toast} position="top-right" />

      <Droppable droppableId="lifecycle-phases">
        { (droppableProvided: DroppableProvided) => (
          <PhaseListWrapper data-testid="phase-list-wrapper" className="relative w-full overflow-visible droppable-panel" ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            { phases.map((phase: IPhase, index: number) => (
              <Draggable draggableId={`lifecycle-draggable-${index}`} index={index} key={index} isDragDisabled={isDragDisabled(phase)}>
                { (draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                  <>
                    <div
                      key={`draggable-panel-${index}`}
                      className={`mt-3 z-3 draggable-panel-${index}`}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <PhaseItemComponent phase={phase} currentItemIsBeingDrag={snapshot.isDragging} />
                    </div>
                    <div className={classNames(`absolute w-full mt-3 border-round-sm draggable-overlay draggable-overlay-${index}`)}></div>
                  </>
                ) }
              </Draggable>
            )) }
          </PhaseListWrapper>
        )}
      </Droppable>

      <>
        { !enableAddingPhase && <AddPhaseContainer handleAddSubItem={() => updateLifecycleDetail({ enableAddingPhase: true })} /> }

        { enableAddingPhase && <div data-testid="new-phase-placeholder" className="flex w-full mt-3"><PhaseItemComponent /></div> }
      </>
    </DragDropContext>
  )
}
