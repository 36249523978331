import { LciDatabase } from '../../../__generated__/graphql'

export const getImpactBarAttr = ({
  impactAmount,
  totalImpactAmount,
  maxImpactAmount,
}: {
  impactAmount: string,
  totalImpactAmount: string,
  maxImpactAmount: number,
}) => {
  const fImpactAmount = parseFloat(impactAmount)
  const fTotalImpactAmount = parseFloat(totalImpactAmount)

  if (fTotalImpactAmount === 0 || Number.isNaN(fTotalImpactAmount) || Number.isNaN(fImpactAmount)) {
    return { impactType: 'negative', impactPercent: 0 }
  }

  const ratio = fImpactAmount / maxImpactAmount
  const ratioStatus = ratio > 0 ? 'positiveBar' : 'negativeBar'

  return {
    impactType: ratioStatus,
    impactRatioRelativeToTotal: fImpactAmount / fTotalImpactAmount,
    impactPercent: `${(Math.abs(ratio) * 100).toFixed(2)}%`
  }
}

export const getSelectedDatabasesStorageItem = () => {
  const selectedDatabases = localStorage.getItem('SELECTED_DATABASES')
  if (selectedDatabases) {
    return JSON.parse(selectedDatabases)
  }
}

export const setSelectedDatabasesStorageItem = (selectedDatabases: LciDatabase[]) => {
  localStorage.setItem('SELECTED_DATABASES', JSON.stringify(selectedDatabases))
}
