import { gql } from '@apollo/client'

const process = gql`
  fragment processFragment on Process {
    id
    name
    slug
    createdAt
    updatedAt
    description
    parents(limit: 10) {
      id
      name
      slug
    }
    conversions {
      id
      inputs {
        product {
          id
          name
          unit
        }
        value
      }
      outputs {
        product {
          id
          name
          unit
        }
        value
      }
    }
    inputs {
      id
      name
    }
    outputs {
      id
      name
    }
    processes {
      id
      name
      slug
      parents(limit: 10) {
        id
        name
        slug
      }
      conversions {
        id
        inputs {
          product {
            id
            name
            unit
          }
          value
        }
        outputs {
          product {
            id
            name
            unit
          }
          value
        }
      }
      inputs {
        id
        name
      }
      outputs {
        id
        name
      }
      processes {
        id
        name
        slug
        parents(limit: 10) {
          id
          name
          slug
        }
        inputs {
          id
          name
        }
        outputs {
          id
          name
        }
        conversions {
          id
          inputs {
            product {
              id
              name
              unit
            }
            value
          }
          outputs {
            product {
              id
              name
              unit
            }
            value
          }
        }
      }
    }
  }
`

export default process
