import { gql } from '../../../__generated__'

export const ENTITIES_PAGINATED = gql(`
  query entitiesPaginated(
    $query: String,
    $spaceID: ID!,
    $pageSize: Int,
    $skipLifeCycles: Boolean!
    $productSortBy: ProductSortBy,
    $lifeCycleSortBy: LifeCycleSortBy
  ) {
    lifecycles: lifecyclesPaginated(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      sortBy: $lifeCycleSortBy
    ) @skip(if: $skipLifeCycles) {
      items {
        id
        name
      }
    }
    products(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      labels: ["product"],
      sortBy: $productSortBy
    ) {
      items {
        ... on Product {
          id
          name
        }
      }
    }
    objects: products(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      labels: ["object"],
      sortBy: $productSortBy
    ) {
      items {
        ... on Product {
          id
          name
        }
      }
    }
  }
`)
