import React, { useContext, useEffect } from 'react'

import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { useInsightQuery } from '../../shared/hook/query/use-insight.query'

import { transformLeafItems, transformToMultiLeafItems } from '../../shared/util/transform'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'

type TAnalysisInsightContainerProps = {
  children?: React.ReactNode
}
export const AnalysisInsightContainer = ({
  children
}: TAnalysisInsightContainerProps) => {
  const {
    primaryEntityId = '',
    secondaryEntityId = '',
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext)
  const {
    loading: secondaryLeafLoading,
    data: secondaryLeafItemsData,
    refetch: secondaryLeafItemsRefetch
  } = useInsightQuery({
    productID: secondaryEntityId
  })
  const {
    loading: primaryLeafLoading,
    data: primaryLeafItemsData,
    refetch: primaryLeafItemsRefetch
  } = useInsightQuery({
    productID: primaryEntityId
  })

  const {
    product: primaryProduct,
    leafInventoryItems: primaryInventoryLeafItems = null,
    totalImpact: primaryTotalImpact = null
  } = primaryLeafItemsData?.inventory || {}
  const {
    product: secondaryProduct,
    leafInventoryItems: secondaryInventoryLeafItems = null,
    totalImpact: secondaryTotalImpact = null
  } = secondaryLeafItemsData?.inventory || {}
  const loadingLeafItems = secondaryLeafLoading || primaryLeafLoading

  useEffect(() => {
    if (!primaryLeafLoading && primaryInventoryLeafItems && primaryTotalImpact && primaryProduct) {
      const primaryLeafItems = transformLeafItems({
        leafInventoryItems: primaryInventoryLeafItems,
        totalImpactAmount: primaryTotalImpact?.amount
      })
      const {
        name: primaryEntityName,
        unit: primaryEntityUnit,
        hasInventory: primaryHasInventory,
        referenceProduct: primaryReferenceProduct
      } = primaryProduct || {}
      updateAnalysis({
        primaryEntityName,
        primaryEntityUnit,
        primaryLeafItems,
        primaryTotalImpact,
        primaryHasInventory,
        primaryReferenceProduct,
        primaryLeafItemsRefetch
      })
    }
  }, [ primaryLeafLoading, primaryInventoryLeafItems ])

  useEffect(() => {
    if (!secondaryLeafLoading && secondaryInventoryLeafItems) {
      const secondaryLeafItems = transformLeafItems({
        leafInventoryItems: secondaryInventoryLeafItems,
        totalImpactAmount: secondaryTotalImpact?.amount
      })
      const {
        name: secondaryEntityName,
        unit: secondaryEntityUnit,
        hasInventory: secondaryHasInventory,
        referenceProduct: secondaryReferenceProduct,
      } = secondaryProduct || {}
      updateAnalysis({
        secondaryEntityName,
        secondaryEntityUnit,
        secondaryLeafItems,
        secondaryHasInventory,
        secondaryReferenceProduct,
        secondaryTotalImpact,
        secondaryLeafItemsRefetch
      })
    }
  }, [ secondaryInventoryLeafItems, secondaryLeafLoading ])

  useEffect(
    () => {
      if (!loadingLeafItems && secondaryInventoryLeafItems && primaryInventoryLeafItems) {
        const primaryLeafItems = transformLeafItems({
          leafInventoryItems: primaryInventoryLeafItems,
          totalImpactAmount: primaryTotalImpact?.amount
        })
        const secondaryLeafItems = transformLeafItems({
          leafInventoryItems: secondaryInventoryLeafItems,
          totalImpactAmount: secondaryTotalImpact?.amount
        })
        const multiLeafItems = transformToMultiLeafItems(primaryLeafItems, secondaryLeafItems)
        updateAnalysis({ multiLeafItems, loadingLeafItems })
      }
    },
    [ loadingLeafItems ]
  )

  return (
    <>
      { loadingLeafItems && <ProgressSpinnerComponent dataTestId="loading-analysis-insight" size={2} /> }

      { !loadingLeafItems && primaryInventoryLeafItems && secondaryInventoryLeafItems && children }
    </>
  )
}
