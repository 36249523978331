import { gql } from '../../../__generated__'

export const REFERENCE_PRODUCT = gql(`
  query referenceProducts($query: String!, $locationQuery: String!, $categoryId: String!, $types: [String!]!, $databases: [String!]!,$currentPage: Int!, $pageSize: Int!, $fuzzyType: FuzzyType, $impactMethodId: String!) {
    referenceProducts(query: $query, locationQuery: $locationQuery, categoryId: $categoryId, types: $types, databases: $databases, currentPage: $currentPage, pageSize: $pageSize, fuzzyType: $fuzzyType, impactMethodId: $impactMethodId) {
        items{
            id
            name
            description
            databaseID
            databaseName
            type
            referenceUnit {
                id
                name
            }
            referenceProperty {
                id
                name
            }
            amount
            location {
              name
            }
            category {
              name
            }
            parentCategory {
              name
            }
        }
        total
    }
  }
`)

export const REFERENCE_DATABASE = gql(`
  query referenceDatabases($methodId: ID!) {
    referenceDatabases(methodId: $methodId) {
        id
        name
        version
    }
  }
`)

export const CATEGORIES = gql(`
  query categories($parentId: String!, $types: [String!]!) {
    categories(parentId: $parentId, types: $types) {
        id
        name
        description
        isLeaf
    }
  }
`)

export const REFERENCE_PROPERTIES = gql(`
  query referenceProperties {
    referenceProperties {
      id
      name
      referenceUnits {
        id
        name
        conversionUnitID
      }
    }
  }
`)
