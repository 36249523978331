import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'primereact/tooltip'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { store } from '../../../../configureStore'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import { setSelectedLifecycleAction } from '../../../../redux/actions/lifecycle.actions'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { DecimalViewType } from '../../../shared/enum'

export const TableFooterComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { lifecycleDetail, lifecycleTotalImpact } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ lifecycleRef, hasLifecycleOverflow, lifecycleDom ] = useRefOverflow()

  const openDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedLifecycleId = LifecycleSelector.lifecycleId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (lifecycleDetail?.id === selectedLifecycleId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    store.dispatch(setIsDetailsPanelOpenAction(true))
    store.dispatch(setSelectedLifecycleAction(lifecycleDetail))
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { lifecycleDetail?.name }
      {' '}
    </TooltipOverflowContent>
  )

  const footerTitle = lifecycleDetail?.unit
    ? t('labels.unitOfEntityWithAmount', {
      ns: 'common',
      amount: lifecycleDetail?.amount || '1',
      unit: lifecycleDetail?.unit,
      name: lifecycleDetail?.name
    })
    : lifecycleDetail?.name

  return (
    <div className="flex w-full justify-content-end">
      <Tooltip target={lifecycleDom} onBeforeShow={() => hasLifecycleOverflow} position="bottom">{ getTooltipContent() }</Tooltip>

      <div className="flex-none flex align-items-center justify-content-center text-primary-500 bg-primary-50 border-primary-100 border-1 border-round tag-icon">
        <FontAwesomeIcon data-testid="table-footer-component-icon" icon={faRecycle} className="text-base footer-product-icon" />
      </div>
      <div data-testid="table-footer-title" ref={lifecycleRef} onClick={openDetailPanel} className="flex-grow-1 align-items-center text-sm font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer product-name">
        { footerTitle }
      </div>

      <div data-testid="table-footer-impactAmount" className="flex-none flex align-items-center font-medium text-sm pr-2">
        { lifecycleTotalImpact?.amount && (
          <>
            <DecimalPointComponent value={lifecycleTotalImpact?.amount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
            { lifecycleTotalImpact?.unit }
          </>
        ) }
      </div>

      <div className="flex-none flex align-items-center p-2 w-9rem"></div>
    </div>
  )
}
