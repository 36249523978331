import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { SelectButton } from 'primereact/selectbutton'
import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { formatCurrency, isEmpty, returnNested, safeArray, handleModal } from '../../../utils/tools'
import { AccountSettingPageLocation, buildFullUrl } from '../../shared/locations'
import CustomerModal from './customerModal'
import { NAV_EXTERNAL } from '../../../utils/const'
import { extractStripePriceKey } from '.././../../utils/tools'

const Intervals = {
  monthly: {
    id: 'month',
    label: 'Monthly'
  },
  yearly: {
    id: 'year',
    label: 'Annually (save 24%)'
  }
}

const HIGHLIGHTED_PLAN_ID = 'mobius_business'
const HIGHLIGHT_HEIGHT = 60

const SelectButtonWrapper = styled.div`
  .p-button {
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 0.25rem;
  }
  .p-button.p-highlight {
    background: var(--green-500);
    border-color: var(--green-500);

    :hover {
      background: var(--green-500) !important;
      border-color: var(--green-500);
    }
  }
`

const HighlightedPlanSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HIGHLIGHT_HEIGHT}px;
  border-radius: 16px 16px 0 0;
  background: var(--green-500);
  color: #fff;
  font-size: 16px;
`

const IntervalOptions = [
  { label: Intervals.yearly.label, value: Intervals.yearly.id },
  { label: Intervals.monthly.label, value: Intervals.monthly.id }
]
class SubscriptionChoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCustomerModal: false,
      selectedSubscriptionProduct: undefined,
      planInterval: Intervals.yearly.id
    }
  }

  createEnterpriseProduct = () => {
    const { t } = this.props
    const context = 'mobius_enterprise'
    return {
      amount: t('billing.subscription.planAmount', { context }),
      currency: null,
      actionLabel: t('billing.subscription.planActionLabel', { context }),
      actionHandler: this.onCustomSubscriptionProductClick.bind(this),
      id: context,
      title: t('billing.subscription.planTitle', { context }),
      description: t('billing.subscription.planDescription', { context }),
      includes: t('billing.subscription.planIncludes', { context }),
      features: t('billing.subscription.planFeatures', { context, returnObjects: true }),
    }
  }

  mapppedSubscriptionProducts = () => {
    const { subscriptionProducts = [], selectedAccount, isUpdate, t } = this.props
    const { planInterval } = this.state

    const mappedProducts = []
    const firstPlan = returnNested(selectedAccount, 'subscription', 'items', 0, 'plan')
    const isYearPlan = planInterval === Intervals.yearly.id

    subscriptionProducts.forEach(product => {
      const productPriceFiltered = product.prices.filter(price => price.interval === planInterval)
      productPriceFiltered.forEach(price => {
        const priceKey = extractStripePriceKey(price.lookupKey)
        const hasAction = returnNested(firstPlan, 'lookupKey') !== priceKey || !isUpdate
        const subscriptionProductFields = {
          id: priceKey,
          title: t('billing.subscription.planTitle', { context: priceKey }),
          description: t('billing.subscription.planDescription', { context: priceKey }),
          includes: t('billing.subscription.planIncludes', { context: priceKey }),
          features: t('billing.subscription.planFeatures', { context: priceKey, returnObjects: true }),
          amount: isYearPlan ? Number(price.amount) / 12 : price.amount,
          paymentPeriod: t('billing.pay_per_month'),
          currency: price.currency,
          defaultQuantity: price.defaultQuantity,
          interval: t(`billing.plan.interval.${isYearPlan ? 'year' : 'month'}`),
          actionLabel: hasAction && t('billing.subscription.planActionLabel', { context: priceKey }),
          actionHandler: () => hasAction && this.onSubscriptionProductClickHandler(price)
        }
        return mappedProducts.push(subscriptionProductFields)
      })
    })

    return mappedProducts.sort((a, b) => a.amount - b.amount)
  }

  onSubscriptionProductClickHandler = subscriptionProduct => {
    const { selectedAccount, startCheckoutSubscriptionSaga, isUpdate, t } = this.props
    this.setState({ selectedSubscriptionProduct: subscriptionProduct })
    const selectedAccountId = returnNested(selectedAccount, 'id')
    const customer = returnNested(selectedAccount, 'customer')

    if (!returnNested(customer, 'isComplete')) {
      this.setState({
        showCustomerModal: true
      })

      return
    }
    if (!isUpdate) {
      startCheckoutSubscriptionSaga(
        selectedAccountId,
        subscriptionProduct?.id,
        buildFullUrl(AccountSettingPageLocation, {
          activeTabKey: 'billingManagement',
          stripe_success: true,
          stripe_message: 'billing.subscription_success'
        }),
        buildFullUrl(AccountSettingPageLocation, {
          activeTabKey: 'billingManagement',
          stripe_success: false,
          stripe_message: 'err.subscription_failure'
        }),
        subscriptionProduct?.defaultQuantity
      )
    } else {
      handleModal({
        title: t('billing.change_plan'),
        content: t('billing.confirm_change_plan'),
        onOk: () => this.props.changeSubscriptionProductSaga(
          selectedAccountId,
          subscriptionProduct?.id,
          this.props.changeSubscriptionProductCallback,
          subscriptionProduct?.defaultQuantity
        )
      })
    }
  }

  onCustomSubscriptionProductClick = () => {
    window.open(NAV_EXTERNAL.TALK_TO_SALES)
  }

  formatPrice = subscriptionProduct => {
    const { t } = this.props
    return isNaN(subscriptionProduct.amount)
      ? t(subscriptionProduct.amount)
      // Based on https://stripe.com/docs/api/plans/object#plan_object-interval
      // TODO For now assumed monthly
      : formatCurrency(subscriptionProduct.amount, subscriptionProduct.currency)
  }

  formatRate = subscriptionProduct => {
    const { t } = this.props
    // Based on https://stripe.com/docs/api/plans/object#plan_object-interval
    // TODO For now assumed monthly
    return isNaN(subscriptionProduct.amount)
      ? ''
      : t('billing.plan.interval.month')
  }

  customerOkCallback = values => {
    const { selectedAccountId, updateBillingCustomerSaga } = this.props
    const { selectedSubscriptionProduct } = this.state

    this.setState({ showCustomerModal: false }, () => {
      updateBillingCustomerSaga({
        accountId: selectedAccountId,
        name: values.name,
        email: values.email,
        phone: values.phone,
        country: values.country,
        line1: values.line1,
        line2: values.line2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        vat: values.vat,
        cb: () => {
          this.onSubscriptionProductClickHandler(selectedSubscriptionProduct)
        }
      })
    })
  }

  getContentForCustomerUpdate() {
    const { selectedAccount } = this.props
    const { showCustomerModal } = this.state
    const customer = returnNested(selectedAccount, 'customer')
    return <CustomerModal
      customer={customer}
      showCustomerModal={showCustomerModal}
      okCallback={this.customerOkCallback}
      cancelCallback={() => {
        this.setState({ showCustomerModal: false })
      }}
    />
  }

  planIntervalToggler = e => {
    if (e.value) {
      this.setState({ planInterval: e.value })
    }
  }

  render() {
    const { subscriptionProducts = [], t } = this.props
    const { planInterval } = this.state
    if (isEmpty(subscriptionProducts)) return null
    const mappedSubscriptionProducts = this.mapppedSubscriptionProducts()
    mappedSubscriptionProducts.push(this.createEnterpriseProduct())
    return <div className="payment" data-cy="subscriptions-panel">
      {this.getContentForCustomerUpdate()}

      <Row type="flex" justify="center" gutter={ 16 }>
        <Col span={ 24 } flex>
          <SelectButtonWrapper
            className="flex justify-content-center"
            data-cy="subscription-period-toggle"
          >
            <SelectButton
              className="mb-3"
              value={planInterval || Intervals.monthly.id}
              options={IntervalOptions}
              optionLabel="label"
              onChange={e => this.planIntervalToggler(e)}
            />
          </SelectButtonWrapper>
        </Col>
        {
          mappedSubscriptionProducts.map(subscriptionProduct => {
            const isHighlighted = subscriptionProduct.id.includes(HIGHLIGHTED_PLAN_ID)

            return (
              <Col
                span={ 8 }
                key={ subscriptionProduct.id }
                style={{ marginTop: isHighlighted ? 0 : HIGHLIGHT_HEIGHT }}
              >
                {isHighlighted && <HighlightedPlanSection>Most popular</HighlightedPlanSection>}
                <Card
                  className={ classnames(`plan-item plan-${ subscriptionProduct.id }`, {
                    ['border-noround-top']: isHighlighted,
                    ['border-green-500']: isHighlighted,
                    ['border-x-2']: isHighlighted,
                    ['border-bottom-2']: isHighlighted
                  }) }
                  key={ subscriptionProduct.id }
                  actions={ [ subscriptionProduct.actionHandler ?
                    <Button  type="primary" onClick={ () => subscriptionProduct.actionHandler(subscriptionProduct.id) } size="large" className="button plan-button">{ subscriptionProduct.actionLabel}</Button>
                    :
                    <Button disabled={true} type="button" size="large">{ t('billing.current_plan') }</Button>
                  ]}
                  title={
                    <React.Fragment>
                      <h3 className="name text-center">{ subscriptionProduct.title}</h3>
                      <div className="price text-center">
                        <span>{ this.formatPrice(subscriptionProduct) }</span>
                        {subscriptionProduct.paymentPeriod && <span className="text-base">{subscriptionProduct.paymentPeriod}</span>}
                      </div>
                      <div className="pay-rate text-center">{ subscriptionProduct.interval ? subscriptionProduct.interval : this.formatRate(subscriptionProduct) }</div>
                    </React.Fragment>
                  }
                >
                  <div className="description text-center">{ subscriptionProduct.description}</div>
                  <div className="features">
                    <span className={classnames({ title: !isEmpty(safeArray(subscriptionProduct.features)) })}>{ t('billing.includes')}</span>
                    { safeArray(subscriptionProduct.features)
                      .map(feature => <Row key={feature} className="features-item flex-nowrap">
                        <Col flex="24px">
                          <CheckCircleFilled />
                        </Col>
                        <Col flex="auto">{ feature }</Col>
                      </Row>
                      )
                    }

                  </div>
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </div>
  }
}

export default compose(
  withTranslation()
)(SubscriptionChoice)
