import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { OverviewListLayout } from '../../shared/layout/overview-list.layout'
import { ILifecycle } from '../../model'
import { TListItem } from '../../shared/interface/list-item'
import { OverviewCardTemplateLayout, OverviewCardTemplateType } from '../../shared/layout/overview-card-template.component'
import { OverviewCreateComponent } from '../../shared/component/overview/overview-create.component'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'
import LifecycleContext from '../provider/context/lifecycle.context'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import { CardItemTemplateComponent } from '../component/view-template/card-item-template.component'

import { setOverviewSetting } from '../../shared/util/overview'
import { LifeCycleSortField, Order } from '../../../__generated__/graphql'

export const CardViewLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const menu = useRef<Menu>(null)
  const {
    totalItems = 0,
    currentPage = 1,
    cardPageSize = 0,
    loadingLifeCycleOverview,
    allLifecycles,
    selectedSortBy = LifeCycleSortField.Name,
    selectedSortOrder = Order.Asc,
    updateLifecycle
  } = useContext<TLifecycleContext>(LifecycleContext)
  const { updateDashboard } = useContext(DashboardContext)
  const [ firstProduct, setFirstProduct ] = useState(0)
  useEffect(() => {
    if (currentPage === 1) {
      setFirstProduct(0)
    }
  }, [ currentPage ])

  const hasMore = () => totalItems > cardPageSize

  const openCreateProductDialog = () => {
    updateDashboard({ showCreateLifeCycleDialog: true })
  }

  const getLifecycleListItem = (items: ILifecycle[] = []): TListItem[] => {
    if (items.length === 0) {
      return [ {
        key: 0,
        itemComponent: (
          <OverviewCardTemplateLayout clickHandler={openCreateProductDialog} type={OverviewCardTemplateType.Create}>
            <OverviewCreateComponent createLabel={t('actions.createFirst', { context: 'lifecycle' })} />
          </OverviewCardTemplateLayout>
        )
      } ]
    }

    return items.map((lifecycle: ILifecycle) => {
      const itemAttr = { lifecycle }
      return {
        key: lifecycle?.id,
        itemComponent: <CardItemTemplateComponent {...itemAttr} />
      }
    })
  }

  const onCustomPageChange = (event: any) => {
    setFirstProduct(event.first)
    updateLifecycle({ currentPage: event.page + 1 })
  }

  const onSortOrderClick = () => {
    const sortOrderUpdated = {
      selectedSortOrder: selectedSortOrder === Order.Asc ? Order.Desc : Order.Asc
    }
    updateLifecycle(sortOrderUpdated)
    setOverviewSetting(sortOrderUpdated)
  }

  const onSortByClick = (event: any) => {
    const { item: { value: sortByTo } } = event
    const sortByUpdated = { selectedSortBy: sortByTo }
    updateLifecycle(sortByUpdated)
    setOverviewSetting(sortByUpdated)
  }

  const sortLabels = {
    [LifeCycleSortField.Name]: t('labels.tableHeader', { context: 'lifecycleName' }),
    [LifeCycleSortField.Updated]: t('labels.tableHeader', { context: 'updated' }),
    [LifeCycleSortField.Created]: t('labels.tableHeader', { context: 'created' })
  }
  const sortMenuItems = Object.values(LifeCycleSortField).map((item: LifeCycleSortField) => ({
    label: sortLabels[item],
    value: item,
    command: onSortByClick
  }))

  const sortOrderIcon = selectedSortOrder === Order.Asc
    ? <FontAwesomeIcon className="text-sm" icon={faArrowUp} />
    : <FontAwesomeIcon className="text-sm" icon={faArrowDown} />

  return (
    <>
      { loadingLifeCycleOverview ? (
        <ProgressSpinnerComponent dataTestId="loading-products" size={2} />
      ) : (
        <>
          <div className="flex flex-none w-full px-3 mt-3">
            <div data-testid="card-view-allTitle" className="flex flex-grow-1">{ t('labels.all', { context: 'lifecycle' }) }</div>
            <div data-testid="card-view-sortWrapper" className="flex w-10rem justify-content-end">
              <Menu model={sortMenuItems} popup ref={menu} />
              <Button
                data-testid="card-view-sortByButton"
                label={sortLabels[selectedSortBy]}
                className="p-button-outlined p-button-plain text-sm border-none p-0 mr-1"
                onClick={(event: any) => menu?.current?.toggle(event)}
              />
              <Button
                data-testid="card-view-sortOrderButton"
                onClick={onSortOrderClick}
                icon={sortOrderIcon}
                className="p-button-outlined p-button-plain p-button-sm p-0 border-none "
              />
            </div>
          </div>
          <div data-testid="card-view-allCards" className="flex flex-wrap w-full px-2">
            <OverviewListLayout items={getLifecycleListItem(allLifecycles)} />
          </div>
          { hasMore() && <PagePaginatorComponent first={firstProduct} pageSize={cardPageSize} totalItems={totalItems} onPageChange={onCustomPageChange} />}
        </>
      )}
    </>
  )
}
