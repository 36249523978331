import { gql } from '../../../__generated__'

export const PRODUCTS = gql(`
  query products($spaceID: ID!, $categories: [ProductCategory!], $sortBy: ProductSortBy, $query: String,
    $pageSize: Int, $currentPage: Int, $labels: [ID!], $tags: [ID!],  $folderID: ID)
  {
    products(spaceID: $spaceID, categories: $categories, sortBy: $sortBy, query: $query,
      pageSize: $pageSize, currentPage: $currentPage, labels: $labels, tags: $tags, folderID: $folderID)
    {
      items {
        __typename
        ... on Folder {
          id
          name
          updatedAt
        }
        ... on Product {
          ...overviewProductFragment
        }
      }
      total
      currentFolder {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`)

export const FAVORITE_PRODUCTS = gql(`
  query favoriteProducts($spaceID: ID!, $categories: [ProductCategory!], $sortBy: ProductSortBy, $query: String,
    $labels: [ID!], $tags: [ID!], $folderID: ID)
  {
    favoriteProducts(spaceID: $spaceID, categories: $categories, sortBy: $sortBy, query: $query,
      labels: $labels, tags: $tags, folderID: $folderID)
    {
      items {
        __typename
        ... on Folder {
          id
          name
          updatedAt
        }
        ... on Product {
          ...overviewProductFragment
        }
      }
      total
    }
  }
`)

export const PRODUCT_IMPORT_COLUMNS = gql(`
  query productImportColumns {
    productImportColumns {
      name
      altNames
      description
      required
    }
  }
`)
