export enum ScenarioAction {
  RemoveConversionItem = 'removeConversionItem',
  AddInventoryItem = 'addInventoryItem',
  ChangeInventoryItemAmount = 'changeInventoryItemAmount',
  ChangeProductReference = 'changeProductReference',
  RenameProduct = 'renameProduct',
  ChangeProductUnit = 'changeProductUnit',
  AddProductProperty = 'addProductProperty',
  ChangeProductProperty = 'changeProductProperty',
  RemoveProductReference = 'removeProductReference'
}
