import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useReactiveVar, useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { Toast } from 'primereact/toast'

import { useTranslation } from 'react-i18next'
import {
  currentWorkspaceSlugVar, selectedAccountVar, selectedWorkspaceVar, setSelectedWorkspace, usersSettingVar, userVar
} from '../../../graphql/cache'
import { WorkspaceNavigationComponent } from '../component/workspace-navigation'
import { useWorkspaceQuery } from '../hooks/workspace-query'
import { store } from '../../../configureStore'
import { AddWorkspaceComponent } from '../component/add-workspace'
import { CREATE_SPACE, REMOVE_SPACE } from '../../graphql/mutation'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'
import { RemoveWorkspaceComponent } from '../component/remove-workspace'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { isValid } from '../../shared/util/tools'
import { ISpace, ISpaceAccess } from '../../model'
import { IUserSetting } from '../../shared/interface/user-setting'
import { Navigation } from '../../shared/enum'
import { WorkspaceAccessContainer } from './workspace-access.container'
import { SPACE_PERMISSION } from '../../graphql/query'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { setSelectedDatabasesStorageItem } from '../../shared/util/impact'

export const WorkspaceNavContainer = () => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const navigate = useNavigate()
  const toast = useRef<Toast>(null)

  const {
    skipSpacePermission, refetchUser, updateDashboard, openWorkspaceSetting = () => {}
  } = useContext<IDashboardContext>(DashboardContext)
  const currentWorkspaceSlug = useReactiveVar(currentWorkspaceSlugVar) || ''
  const selectedAccount = useReactiveVar(selectedAccountVar) || {}
  const { account, emptyAccount } = selectedAccount
  const [ showAddWorkspaceDialog, setAddWorkspaceDialog ] = useState<boolean>(false)
  const [ showRemoveWorkspaceDialog, setRemoveWorkspaceDialog ] = useState<boolean>(false)
  const { workspaceSlug: urlWorkspaceSlug = null } = useParams()
  const {
    loading, emptyWorkspace, workspace
  } = useWorkspaceQuery(account, currentWorkspaceSlug)
  const [ trackEventInSegment ] = getSegmentTrack()

  const selectedWorkspace: ISpaceAccess | null = selectedWorkspaceVar() || {}
  const {
    space: {
      id: spaceId = null
    } = {},
  } = selectedWorkspace || {}
  const [
    createSpace,
    {
      error: failedCreating,
      data: createSpaceData,
      loading: spaceCreating
    }
  ] = useMutation(CREATE_SPACE)

  const [
    removeSpace,
    {
      error: failedRemoving,
      data: removeSpaceData,
      loading: spaceRemoving
    }
  ] = useMutation(REMOVE_SPACE)

  const {
    loading: loadingPermission,
    data: workspacePermissionData
  } = useQuery(SPACE_PERMISSION, {
    skip: skipSpacePermission || !currentWorkspaceSlug || !account?.id || emptyAccount,
    variables: {
      slug: currentWorkspaceSlug,
      accountID: account?.id || ''
    },
    fetchPolicy: 'no-cache'
  })

  const spaceCreated = createSpaceData?.createSpace || null
  const spaceRemoved = removeSpaceData?.removeSpace || null
  const workspacePermission = workspacePermissionData?.spaceBySlug || null

  useEffect(() => {
    if (!skipSpacePermission && !loadingPermission && workspacePermission) {
      const space = { ...selectedWorkspace?.space, ...workspacePermission } as ISpace

      updateDashboard({ skipSpacePermission: true, loadingPermission })
      setSelectedWorkspace({ ...selectedWorkspace, space })
    } else if (loadingPermission) {
      updateDashboard({ loadingPermission })
    }
  }, [ loadingPermission, workspacePermission ])

  useEffect(() => {
    if (!loading && workspace) {
      setSelectedWorkspace({ ...selectedWorkspace, space: workspace, loading })
    } else if (emptyWorkspace) {
      setSelectedWorkspace({ loading, emptyWorkspace })
    }
  }, [ loading, workspace, emptyWorkspace ])

  useEffect(() => {
    createWorkspaceCallback()
  }, [ spaceCreating, spaceCreated, failedCreating ])

  useEffect(() => {
    removeWorkspaceCallback()
  }, [ spaceRemoving, spaceRemoved, failedRemoving ])

  useEffect(() => {
    if (account) {
      const spaceFromAccount = getSpaceFromAccount(account.spaces)
      currentWorkspaceSlugVar(spaceFromAccount?.slug)
      if (!isValid(workspace) && spaceFromAccount) {
        setSelectedWorkspace({ loading, space: spaceFromAccount })
      }
    }
  }, [ account ])

  const getSpaceFromAccount = (spaces: ISpace[] = []): ISpace => {
    const user = userVar()
    const usersSetting = usersSettingVar()
    const { selectedWorkspaceId = null } = usersSetting.find((setting: IUserSetting) => setting.userId === user?.id && setting.selectedAccountId === account?.id) || {}
    const prevSelectedSpace = spaces.find(workspace => (workspace.id === selectedWorkspaceId))
    const spaceFromUrlSlug = spaces.find(workspace => (workspace?.slug === urlWorkspaceSlug))
    if (spaceFromUrlSlug) {
      return spaceFromUrlSlug
    } if (prevSelectedSpace) {
      return prevSelectedSpace
    }

    return spaces[0]
  }

  const createWorkspaceCallback = () => {
    if (!spaceCreating && (spaceCreated || failedCreating)) {
      closeAddWorkspaceDialog()
      if (failedCreating) {
        toast?.current?.show({
          severity: 'error',
          summary: t('messages.errorSummary', { ns: 'common' }),
          detail: t('messages.errorDetail', { context: 'createWorkspace' }),
          life: 3000
        })
      } else if (spaceCreated && account) {
        trackEventInSegment(TrackEventType.CREATED_WORKSPACE)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.successDetail', { context: 'createWorkspace' }),
          life: 3000
        })
        refetchUser && refetchUser()
        setSelectedWorkspace({ loading: true })
        currentWorkspaceSlugVar(spaceCreated.slug)
        account?.id && spaceCreated?.slug && navigate(generatePath(Navigation.WorkspaceDetail, {
          accountId: account.id,
          workspaceSlug: spaceCreated.slug
        }))
      }
    }
  }

  const handleSwitchWorkspace = (id: string) => {
    const spaceToSwitch = account?.spaces?.find((space: ISpace) => space.id === id)
    setSelectedWorkspace({ loading: true })
    setSelectedDatabasesStorageItem([])
    if (account && spaceId !== spaceToSwitch?.id) {
      setTimeout(() => {
        setSelectedWorkspace({ loading, space: spaceToSwitch })
        currentWorkspaceSlugVar(spaceToSwitch?.slug)
        trackEventInSegment(TrackEventType.SWITCH_WORKSPACE)
        account?.id && spaceToSwitch?.slug && navigate(generatePath(Navigation.WorkspaceDetail, {
          accountId: account.id,
          workspaceSlug: spaceToSwitch.slug
        }))

        // TODO : Added for compatibility with v0.9
        const state = store.getState()
        const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
        isDetailsPanelOpen && updateDashboard({ skipSpacePermission: false })
      }, 0)
    }
  }

  const removeWorkspaceCallback = () => {
    if (!spaceRemoving && (spaceRemoved || failedRemoving)) {
      closeRemoveWorkspaceDialog()
      if (failedRemoving) {
        toast?.current?.show({
          severity: 'error',
          summary: t('messages.errorSummary', { ns: 'common' }),
          detail: t('messages.errorDetail', { context: 'removeWorkspace' }),
          life: 3000
        })
      } else if (spaceRemoved) {
        trackEventInSegment(TrackEventType.DELETED_WORKSPACE)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.successDetail', { context: 'removeWorkspace' }),
          life: 3000
        })
        refetchUser && refetchUser()
        setSelectedWorkspace({ loading: true })
        currentWorkspaceSlugVar(null)
        account?.id && navigate(generatePath(Navigation.AccountDetail, { accountId: account.id }))
      }
    }
  }

  const handleWorkspaceSetting = () => {
    openWorkspaceSetting(selectedWorkspace?.space)
  }

  const openAddWorkspaceDialog = () => {
    setAddWorkspaceDialog(true)
  }

  const closeAddWorkspaceDialog = () => {
    setAddWorkspaceDialog(false)
  }

  const openRemoveWorkspaceDialog = () => {
    setRemoveWorkspaceDialog(true)
  }

  const closeRemoveWorkspaceDialog = () => {
    setRemoveWorkspaceDialog(false)
  }

  const handleWorkspaceCreation = (newWorkspace: any) => {
    const { name } = newWorkspace
    account?.id && createSpace({ variables: { name, accountID: account.id } })
  }

  const handleWorkspaceRemoval = (confirm: any) => {
    const { id = null } = workspace || {}
    if (confirm.remove === 'REMOVE' && id) {
      setTimeout(() => removeSpace({ variables: { id } }), 0)
    }
  }

  const content = (
    <div data-testid="with-workspace-container">
      <WorkspaceNavigationComponent
        handleSwitchWorkspace={handleSwitchWorkspace}
        openRemoveWorkspaceDialog={openRemoveWorkspaceDialog}
        openAddWorkspaceDialog={openAddWorkspaceDialog}
        handleWorkspaceSetting={handleWorkspaceSetting}
      />

      <AddWorkspaceComponent
        showAddWorkspaceDialog={showAddWorkspaceDialog}
        workspaceCreating={spaceCreating}
        closeAddWorkspaceDialog={closeAddWorkspaceDialog}
        handleWorkspaceCreation={handleWorkspaceCreation}
      />

      <RemoveWorkspaceComponent
        selectedWorkspaceName={workspace?.name || ''}
        showRemoveWorkspaceDialog={showRemoveWorkspaceDialog}
        workspaceRemoving={spaceRemoving}
        closeRemoveWorkspaceDialog={closeRemoveWorkspaceDialog}
        handleWorkspaceRemoval={handleWorkspaceRemoval}
      />
    </div>
  )

  return (
    <>
      <Toast ref={toast} position="top-right" />
      { loading && (
        <div data-testid="workspace-container-loading" className="w-full px-3 py-4">
          <ProgressSpinnerComponent size={2} />
        </div>
      ) }
      { workspace?.id && (
        <WorkspaceAccessContainer resourceID={workspace.id}>
          {' '}
          {content}
          {' '}
        </WorkspaceAccessContainer>
      ) }
      { emptyWorkspace && (
        <>
          {' '}
          {content}
          {' '}
        </>
      ) }
    </>
  )
}
