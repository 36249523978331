import React from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import { returnNested } from '../../../../utils/tools'
import { LifecycleItemLocation, tabFlatView, tabSunburst, tabChartView } from '../../../shared/locations'
import { matchLocation } from '../../../../utils/location'
import ChangeLifecycle from '../../lifecycle/change-lifecycle/changeLifecycle.container'
import CreateLifecycle from '../../lifecycle/create-lifecycle/createLifecycle.container'
import PhasesManagement from '../../lifecycle/phases-management/phasesManagement.container'
import DetailsPanelTitle from '../detailsPanelTitle'

const LifecycleDetails = ({
  lifecycle,
  isCreateLifecycle,
  isDetailsPanelReadOnly
}) => {

  const isShowLifecyclePhases = () => {
    const isLifecycleItemLocation = matchLocation(LifecycleItemLocation, true)
    const listOfTabsToHidePhases = [ tabFlatView, tabSunburst, tabChartView ]
    const isInTabToHide = listOfTabsToHidePhases.some(el => window.location.href.includes(el))

    return isLifecycleItemLocation && !isInTabToHide
  }

  return <>
    {
      isCreateLifecycle
        ? <CreateLifecycle withProductSelection={ true }/>
        : <div className="details-panel-wrapper lifecycle-details" data-cy="lifecycle-details">
          <DetailsPanelTitle title={returnNested(lifecycle, 'name')} className="lifecycle-title" data-cy="selected-lifecycle-name" />
          <div className='details-panel-body'>
            {
              lifecycle && <>
                <ChangeLifecycle
                  key={returnNested(lifecycle, 'id')}
                  lifecycle={lifecycle}
                  isReadOnly={isDetailsPanelReadOnly} />
              </>
            }
            {
              isShowLifecyclePhases() && <PhasesManagement
                key={returnNested(lifecycle, 'id')}
                lifecycle={lifecycle}
                isReadOnly={isDetailsPanelReadOnly} />
            }
          </div>
        </div>
    }
  </>
}

export default compose(
  withRouter,
  withTranslation()
)(LifecycleDetails)
