import React, { useContext, useState } from 'react'
import ProductDetailContext from './context/product-detail.context'
import { TProductDetailProvider } from '../interface/product-detail-context.type'
import { InventoryViewType } from '../enum/inventory-view-type'
import { IInventory, IInventoryItemNode, IPhase } from '../../model'
import { isValid } from '../../shared/util/tools'
import { TInventoryTreeItem } from '../../shared/util/transform'
import { MenuAction } from '../enum/menu-action'
import { DecimalViewType } from '../../shared/enum'
import { ImpactType } from '../../shared/enum/impact'
import { ComparisonEntity } from '../../analysis/enum/entity'
import { AnalysisMode } from '../../analysis/enum/analysis'
import { TLifecycleDetailContext } from '../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../lifecycle/provider/context/lifecycle-detail.context'

type ProductDetailProviderProp = {
  analysisMode?: AnalysisMode
  showInventoryHeader?: boolean
  readonlyInventory?: boolean
  noInventoryImpactBar?: boolean
  children?: React.ReactNode
  productInventory?: IInventory
}
export const ProductDetailProvider = ({
  productInventory,
  analysisMode,
  noInventoryImpactBar = false,
  showInventoryHeader = true,
  readonlyInventory = false,
  children
}: ProductDetailProviderProp) => {
  const defaultProductProviderValue = {
    analysisMode,
    inventoryKey: ComparisonEntity.Primary,
    readonlyInventory,
    noInventoryImpactBar,
    editAmountMode: false,
    expandedKeys: [],
    selectedInventoryViewType: InventoryViewType.Model,
    selectedDecimalViewType: DecimalViewType.NumericValue,
    loadingInventory: true,
    showInventoryHeader,
    productInventory
  }
  const [ product, setProduct ] = useState<TProductDetailProvider>(defaultProductProviderValue)
  const { lifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const updateProductDetail = (newState: TProductDetailProvider = defaultProductProviderValue) => {
    setProduct(prevState => ({ ...prevState, ...newState }))
  }

  const getMaxImpactAmount = () => {
    const {
      totalImpact: { amount: totalImpactAmount = '' } = {},
      lifecyclePhase,
      inventoryItems = []
    } = product

    if (lifecyclePhase && lifecycleDetail) {
      const { phases = [] } = lifecycleDetail
      const maxImpacts = phases.map((phase: IPhase) => {
        const { amount } = phase.inventory?.maximumImpact || {}
        return amount && Math.abs(parseFloat(amount))
      }).filter((elt: any) => elt && typeof elt === 'number') as number[]

      return Math.max(...maxImpacts)
    }

    const fTotalImpactAmount = parseFloat(totalImpactAmount)
    const maxImpactAmount = Math.abs(fTotalImpactAmount)

    if (inventoryItems.length === 0) return maxImpactAmount

    const impacts = inventoryItems.map((elt: IInventoryItemNode) => {
      const { amount } = elt.inventoryItem?.impact || {}
      return amount && Math.abs(parseFloat(amount))
    }).filter((elt: any) => elt && typeof elt === 'number') as number[]
    return Math.max(...impacts, maxImpactAmount)
  }

  const getImpactType = () => {
    const { totalImpact: { amount: totalImpactAmount = '' } = {} } = product
    const fTotalImpactAmount = totalImpactAmount && parseFloat(totalImpactAmount)

    if (!fTotalImpactAmount || fTotalImpactAmount === 0 || Number.isNaN(fTotalImpactAmount)) {
      return ImpactType.Negative
    }

    const maxImpactAmount = getMaxImpactAmount()
    const ratio = fTotalImpactAmount / maxImpactAmount
    const impactType = ratio > 0 ? ImpactType.Positive : ImpactType.Negative
    return impactType
  }

  const isLeafInventoryLoadingEnabled = () => {
    const { leafInventoryItems = null, hasInventoryChanged = false } = product
    return !isValid(leafInventoryItems) || hasInventoryChanged
  }

  const addInventoryItem = (node?: TInventoryTreeItem) => {
    const { productInventory: { product: rootProduct } = {} } = product
    let params: any = {
      selectedKey: null,
      selectedProduct: rootProduct,
      selectedInventoryAmountV09: 1
    }
    if (node) {
      const { key, data: { inventoryItem = null } = {} } = node
      const { product, amount } = inventoryItem || {}
      params = {
        selectedKey: key,
        selectedProduct: product,
        selectedInventoryAmountV09: amount
      }
    }

    updateProductDetail({
      selectedAction: MenuAction.AddTreeItem,
      ...params
    })
  }

  return (
    <ProductDetailContext.Provider value={{
      ...product,
      addInventoryItem,
      updateProductDetail,
      getMaxImpactAmount,
      getImpactType,
      isLeafInventoryLoadingEnabled
    }}
    >
      { children }
    </ProductDetailContext.Provider>
  )
}
