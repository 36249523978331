import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react'
import styled from 'styled-components'
import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import { useTranslation, Trans } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faChartSimple } from '@fortawesome/pro-solid-svg-icons'

import ProductDetailContext from '../../provider/context/product-detail.context'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import { FlatViewFilterComponent } from './flat-view-filter.component'
import { ITransformedLeafItem } from '../../interface/transformed-leaf-item'
import { castNumberToDisplayValue } from '../../../shared/util/decimal'
import { EComparison } from '../../enum/comparison'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { InventoryViewType } from '../../enum/inventory-view-type'
import { Navigation } from '../../../shared/enum'

import { PngHeaderTemplateComponent } from './png-header-template.component'
import { PngFooterTemplateComponent } from './png-footer-template.component'
import { MenuCompareComponent } from '../menu/menu-compare.component'
import { BarViewDownloadComponent } from './bar-view-download.component'
import { EmptyStateScreenLayout } from '../../../shared/layout/empty-state-screen.layout'
import { isValid } from '../../../shared/util/tools'

const BarViewWrapper = styled.div`
  min-height: 20rem;

  &.bar-view-shadow {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }

  .p-chart {
    width: 100%;
    position: relative;
  }
`

export const InventoryBarViewComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    productInventory: { product = null } = {},
    totalImpact: { unit: totalImpactUnit = null } = {},
    transformedLeafItems = []
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const navigate = useNavigate()
  const { productId } = useParams()
  const [ enableFilter, setEnableFilter ] = useState<boolean>(false)
  const [ inputFilterAmount, setInputFilterAmount ] = useState<number>(1)
  const [ aspectRatio, setAspectRatio ] = useState<number>(0)
  const [ filterComparison, setFilterComparison ] = useState<EComparison>(EComparison.GreaterThan)

  const measureRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const barChartItems: ITransformedLeafItem[] = useMemo(() => {
    const sortedLeafItems = transformedLeafItems.sort((a: ITransformedLeafItem, b: ITransformedLeafItem) => {
      const { impactAmount: aImpactAmount } = a || {}
      const { impactAmount: bImpactAmount } = b || {}

      if (aImpactAmount && bImpactAmount) {
        if (parseFloat(aImpactAmount) < parseFloat(bImpactAmount)) {
          return -1
        } if (parseFloat(aImpactAmount) > parseFloat(bImpactAmount)) {
          return 1
        }
      }

      return 0
    }).reverse()

    if (enableFilter) {
      return sortedLeafItems.filter((item: ITransformedLeafItem) => {
        const { impactRatioRelativeToTotal: impactRatio } = item || {}
        if (filterComparison === EComparison.LessThan) {
          return impactRatio && Math.abs(impactRatio) * 100 < inputFilterAmount
        }
        return impactRatio && Math.abs(impactRatio) * 100 > inputFilterAmount
      })
    }
    return sortedLeafItems
  }, [ filterComparison, enableFilter, inputFilterAmount, transformedLeafItems ])

  const barChartData = useMemo(() => {
    if (barChartItems.length) {
      const labels = barChartItems.map((value: any) => value.id)
      const datasetBackground = barChartItems.map((value: any) => (value.impactAmount < 0 ? '#b6e89c' : '#97c7eb'))
      const datasetHoverBackground = barChartItems.map((value: any) => (value.impactAmount < 0 ? '#96de70' : '#68aee2'))
      const datasetData = barChartItems.map((value: any) => parseFloat(value.impactAmount))
      const datasets = [ {
        borderRadius: 4,
        maxBarThickness: 30,
        minBarLength: 1,
        backgroundColor: [ ...datasetBackground ],
        hoverBackgroundColor: [ ...datasetHoverBackground ],
        data: [ ...datasetData ]
      } ]
      return { labels, datasets }
    }
    return { labels: [], datasets: [] }
  }, [ barChartItems.length ])

  useEffect(() => {
    if (containerRef?.current && barChartItems.length) {
      const container = document.querySelector('.bar-view-wrapper')
      const containerMinHeight = container && parseInt(getComputedStyle(container).minHeight)
      const heightRatio = containerMinHeight && containerMinHeight / (barChartItems.length * 40)
      setAspectRatio(heightRatio && heightRatio < 1.5 ? heightRatio : 1.5)
    }
  }, [ containerRef?.current, barChartItems.length ])

  const getLeafItem = (id: string) => barChartItems.find((item: any) => item.id === id)
  const getMaxImpactAmount = () => Math.max(...barChartItems.map((item: any) => item?.impactAmount || 0), 0)

  const getNumberDisplay = (value: any) => {
    const [ coefficient, exponential ] = castNumberToDisplayValue({ value: value.toString() })
    const maxTwoDecimalPlaces = Math.round((Number.parseFloat(coefficient) + Number.EPSILON) * 100) / 100
    if (exponential) {
      return `${maxTwoDecimalPlaces}e${exponential}`
    }
    return maxTwoDecimalPlaces
  }

  const horizontalOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio,
    animation: false,
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: measureRef.current?.clientWidth ? measureRef.current.clientWidth + 20 : undefined
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltipItem: any) => {
            const { amount, name, unit } = getLeafItem(tooltipItem?.label) || {}
            const unitToShow = unit || 'unit'
            const value = t('labels.unitOfEntityWithAmount', {
              amount, unit: unitToShow, name, ns: 'common'
            })
            const productNameMultiline: string[] = []
            let i = 0
            while (i < value.length) {
              productNameMultiline.push(value.substring(i, i + 40))
              i += 40
            }

            return productNameMultiline
          },
          footer: (tooltipItem: any) => {
            const value = getLeafItem(tooltipItem[0]?.label)
            const impactAmountText = value?.impactAmount && getNumberDisplay(value?.impactAmount)
            return impactAmountText || impactAmountText === 0 ? `Impact : ${impactAmountText} ${totalImpactUnit}` : ''
          }
        }
      }
    },
    scales: {
      x: {
        max: getMaxImpactAmount(),
        ticks: {
          color: '#657c8e',
          beginAtZero: true,
          callback(value: any) {
            return getNumberDisplay(value)
          }
        },
        grid: { color: '#ebedef' }
      },
      y: {
        ticks: { display: false },
        grid: { color: '#ebedef' }
      }
    }
  }

  const beforeDraw = (chart: any) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  }

  const afterDraw = (chart: any) => {
    if (measureRef.current) {
      const { ctx, scales: { y } } = chart

      ctx.save()
      const { clientWidth } = measureRef.current
      y.ticks.forEach((tick: any) => {
        ctx.font = '500 14px sans-serif'
        ctx.fillStyle = '#657c8e'
        const label = getLeafItem(tick.label)?.name
        let i = label?.length || 0
        let displayText = label
        let postfix = ''
        let measuredTextWidth = ctx.measureText(displayText).width
        while (measuredTextWidth > clientWidth - 10 && i > 0) {
          if (i === label?.length) {
            postfix = '...'
          }
          displayText = label?.substr(0, i - 1)
          measuredTextWidth = ctx.measureText(displayText).width
          i--
        }
        ctx.fillText(`${displayText}${postfix}`, clientWidth - measuredTextWidth + 10, y.getPixelForValue(tick.value) + 5)
      })
      ctx.restore()
    }
  }

  const productHasCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !isValid(product?.referenceProduct)
  const productHasNoItems = !product?.hasInventory
  const thereIsItemWithImpact = transformedLeafItems.some(item => item.impactAmount && Math.abs(Number(item.impactAmount)) > 0)

  if (productHasNoImpact && !thereIsItemWithImpact) {
    const goToModel = () => {
      addToBackToList(t('labels.tab', { context: 'barChart', ns: 'common' }))
      account?.id && space?.slug && productId && navigate(generatePath(Navigation.ProductObjectInventoryTabs, {
        accountId: account.id,
        workspaceSlug: space.slug,
        productId,
        activeTab: InventoryViewType.Model
      }))
    }

    return (
      <EmptyStateScreenLayout
        wrapperClassName="h-30rem"
        title={t('labels.emptyState', { context: productHasNoItems ? 'addItemsToShowBarChart' : 'addImpactToShowBarChart', ns: 'common' })}
        icon={<FontAwesomeIcon icon={faChartSimple} className="text-primary-100" fontSize={106} />}
        footer={<Button onClick={goToModel}>{t('actions.go_toModel', { ns: 'common' })}</Button>}
      />
    )
  }

  return (
    <BarViewWrapper ref={containerRef} className="flex flex-column border-round-lg bg-white w-full h-full p-6 bar-view-shadow bar-view-wrapper">
      <PngHeaderTemplateComponent />
      <div data-html2canvas-ignore className="flex h-2rem text-2xl align-items-center mb-2">
        <div className="flex-grow-1 flex align-items-center h-full">
          { t('labels.header', { context: 'chart', unit: totalImpactUnit, ns: 'common' }) }
        </div>
        <div className="flex-none flex align-items-center gap-3">
          <FlatViewFilterComponent {...{
            enableFilter, inputFilterAmount, setEnableFilter, setInputFilterAmount, filterComparison, setFilterComparison
          }}
          />

          <BarViewDownloadComponent containerRef={containerRef} />

          <MenuCompareComponent />
        </div>
      </div>
      <div ref={measureRef} className="md:col-3 lg:col-3 xl:col-2 h-1rem white-space-nowrap overflow-hidden text-overflow-clip measure-text"></div>
      { barChartItems.length > 0 ? (
        <div className="flex w-full">
          <Chart
            id="product-bar-chart"
            data-testid="product-bar-chart"
            type="bar"
            data={barChartData}
            options={horizontalOptions}
            plugins={[ { afterDraw, beforeDraw } ]}
          />
        </div>
      ) : (
        <EmptyStateScreenLayout
          wrapperClassName="h-28rem"
          title={t('labels.emptyState_noResultsFound', { ns: 'common' })}
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary-100" fontSize={106} />}
          description={<Trans t={t} ns="common" i18nKey="labels.emptyState_tryDifferentFilter" />}
        />
      )}
      <PngFooterTemplateComponent />
    </BarViewWrapper>
  )
}
