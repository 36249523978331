import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { CreateLifeCycleComponent } from '../component/lifecycle/create-lifecycle.component'
import { selectedWorkspaceVar } from '../../../graphql/cache'

import { IProduct } from '../../model'
import { CREATE_LIFECYCLE } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../util/error'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { useEntitiesPaginatedQuery } from '../hook/query/use-entities-paginated.query'
import { transformEntitiesToOptions } from '../util/transform'
import { PAGINATION } from '../constants'
import { TrackEventType } from '../enum/track-events'
import { EntityType } from '../enum/entity-type'

type TCreateLifeCycleContainerProps = {
  afterCreateLifeCycle: (param: string, param1: TrackEventType) => void
}

export const CreateLifeCycleContainer = ({
  afterCreateLifeCycle
}: TCreateLifeCycleContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const toast = useRef<Toast>(null)
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    createLifeCycleFromProduct = false, selectedEntity, showCreateLifeCycleDialog, updateDashboard
  } = useContext(DashboardContext)
  const [ entities, setEntities ] = useState([])
  const [
    createLifeCycle,
    {
      error: failedCreatingLifecycle,
      data: createLifeCycleData = {},
      loading: lifeCycleCreating
    }
  ] = useMutation(CREATE_LIFECYCLE)
  const lifeCycleCreated = createLifeCycleData?.createLifecycle

  const { loading: loadingEntities, entitiesData, refetchEntities } = useEntitiesPaginatedQuery({
    spaceID,
    disabled: !showCreateLifeCycleDialog,
    pageSize: PAGINATION.PAGE_SIZE,
    skipLifeCycles: true
  })

  useEffect(() => {
    if (!loadingEntities) {
      const groupedEntitiesList:any = entitiesData ? [
        { label: 'Products', items: transformEntitiesToOptions(entitiesData.products.items.filter((item: IProduct) => (item.__typename !== EntityType.Folder))) },
        { label: 'Objects', items: transformEntitiesToOptions(entitiesData.objects.items.filter((item: IProduct) => (item.__typename !== EntityType.Folder))) }
      ] : []

      setEntities(groupedEntitiesList)
    }
  }, [ loadingEntities, entitiesData ])

  useEffect(() => {
    if (!lifeCycleCreating && (lifeCycleCreated || failedCreatingLifecycle)) {
      createProductCallback()
    }
  }, [ lifeCycleCreating, lifeCycleCreated, failedCreatingLifecycle ])

  const createProductCallback = () => {
    try {
      if (failedCreatingLifecycle) {
        throw failedCreatingLifecycle
      } else if (lifeCycleCreated) {
        afterCreateLifeCycle(lifeCycleCreated?.id, TrackEventType.CREATED_LIFECYCLE)
        closeCreateLifeCycleDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: 'Successfully created a life cycle',
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary'), error?.graphQLErrors)
    }
  }

  const closeCreateLifeCycleDialog = () => {
    updateDashboard({ createLifeCycleFromProduct: false, selectedEntity: null, showCreateLifeCycleDialog: false })
  }

  const handleCreateLifeCycle = (values: any) => {
    let variables = {
      ...values, workspaceID: spaceID, amount: '', cb: () => {}
    }
    if (createLifeCycleFromProduct) {
      variables = { ...variables, productID: selectedEntity?.id }
    }
    createLifeCycle({ variables })
  }

  const handleFilterProducts = (query: string = '') => {
    refetchEntities && refetchEntities({
      query
    })
  }

  return (
    <>
      <CreateLifeCycleComponent
        entities={entities}
        handleFilterProducts={handleFilterProducts}
        handleCreateLifeCycle={handleCreateLifeCycle}
        createLifeCycleFromProduct={createLifeCycleFromProduct}
        closeCreateLifeCycleDialog={closeCreateLifeCycleDialog}
        showCreateLifeCycleDialog={showCreateLifeCycleDialog}
      />

      <Toast data-testid="create-lifeCycle-status" ref={toast} position="top-right" />
    </>
  )
}
