import React, { useContext } from 'react'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { faTag } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { IImpact } from '../../../../model'
import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component'
import { ImpactType } from '../../../../shared/enum/impact'
import { ImpactBarComponent } from '../../../../product/component/impact-bar.component'
import { EComparisonTableColumn } from '../../../enum/table-column'

type TTableFooterComponentProps ={
  column?: EComparisonTableColumn
}
export const TableFooterComponent = ({
  column = EComparisonTableColumn.Id
}: TTableFooterComponentProps) => {
  const { t } = useTranslation([ 'common' ])

  const {
    primaryEntityId,
    secondaryEntityId,
    primaryTotalImpact,
    secondaryTotalImpact
  } = useContext<TAnalysisContext>(AnalysisContext)
  const { amount: pTotalImpactAmount } = primaryTotalImpact || {}
  const { amount: sTotalImpactAmount } = secondaryTotalImpact || {}

  const getTotalImpactChange = () => {
    if (!pTotalImpactAmount || !sTotalImpactAmount || pTotalImpactAmount === sTotalImpactAmount) return

    return parseFloat(pTotalImpactAmount) < parseFloat(sTotalImpactAmount) ? ImpactType.Positive : ImpactType.Negative
  }

  const impactChange = getTotalImpactChange()
  const impactIsPositive = impactChange === ImpactType.Positive

  const isColumn = (checkFor: EComparisonTableColumn) => column === checkFor

  const getImpactBar = (id?: string, totalImpact?: IImpact) => {
    const { amount: totalImpactAmount = '' } = totalImpact || {}
    const impactEffect = parseFloat(totalImpactAmount) > 0 ? ImpactType.Positive : ImpactType.Negative

    return (
      <>
        { id && totalImpact && (
          <div className="flex w-full justify-content-end">
            <ImpactBarComponent nodeKey={id} impactPercent="100%" impactType={`${impactEffect}BarDeep`} />
          </div>
        )}
      </>
    )
  }

  const getImpactAmount = (dataCy: string, totalImpact?: IImpact) => {
    const { amount: totalImpactAmount = '', unit: totalImpactUnit = null } = totalImpact || {}
    return (
      <>
        { totalImpact && (
          <div data-cy={dataCy} data-testid="table-footer-impactAmount" className="flex w-full justify-content-end pr-2 text-sm">
            { totalImpactAmount && (
              <>
                <DecimalPointComponent value={totalImpactAmount} enableTooltip /> { totalImpactUnit }
              </>
            ) }
          </div>
        ) }
      </>
    )
  }

  return (
    <div className="flex w-full">
      { isColumn(EComparisonTableColumn.Id) && (
        <>
          <div className="flex-none flex align-items-center justify-content-center text-primary-500 bg-primary-50 border-primary-100 border-1 border-round tag-icon">
            <FontAwesomeIcon data-testid="table-footer-component-icon" icon={faTag} className="text-base footer-product-icon" />
          </div>
          <div data-testid="table-footer-total" className="flex-grow-1 align-items-center text-sm font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer product-name">
            { t('labels.total', { context: 'impact' }) }
          </div>
        </>
      ) }

      { isColumn(EComparisonTableColumn.PrimaryImpactBar) && (
        <>
          { getImpactBar(primaryEntityId, primaryTotalImpact) }
        </>
      )}

      { isColumn(EComparisonTableColumn.SecondaryImpactBar) && (
        <>
          { getImpactBar(secondaryEntityId, secondaryTotalImpact) }
        </>
      ) }

      { isColumn(EComparisonTableColumn.PrimaryImpact) && (
        <>
          { getImpactAmount('primary-total-impact', primaryTotalImpact) }
        </>
      )}

      { isColumn(EComparisonTableColumn.SecondaryImpact) && (
        <>
          { getImpactAmount('secondary-total-impact', secondaryTotalImpact) }
        </>
      )}

      { isColumn(EComparisonTableColumn.ImpactChangeEffect) && (
        <div className="flex w-full justify-content-end">
          { impactChange && (
            <FontAwesomeIcon
              data-testid="table-footer-impactIcon"
              data-cy="table-footer-impactIcon"
              icon={impactIsPositive ? faCircleArrowUp : faCircleArrowDown}
              className={classNames('flex-none text-base', {
                'text-red-500': impactIsPositive,
                'text-green-500': !impactIsPositive
              })}
            />
          )}
        </div>
      ) }

    </div>
  )
}
