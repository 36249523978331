import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Intercom from '@intercom/messenger-js-sdk'

import DashboardContext from '../context/dashboard.context'
import { TrialExtendTimeContainer } from '../container/trial-extend-time.container'
import { IAuth0User } from '../../model'
import { userAuth0Var, userVar } from '../../../graphql/cache'
import { ExternalSources } from '../../shared/enum'

interface NavigationProps {
  readonly collapsedNavigation?: boolean
}

const Content = styled.div<NavigationProps>`
  width: ${(props: NavigationProps) => (props?.collapsedNavigation ? 'calc(100% - 4.5rem)' : 'calc(100% - 15rem)')};
`

const OutletWrapper = styled.div`
  height: calc(100% - 100px);
`

const GlobalStyle = createGlobalStyle`
  .png-export-wrapper {
    font-variant-numeric: normal !important;
  }
`

export const ContentLayout = () => {
  const { collapsedNavigation, } = useContext(DashboardContext)
  const userAuth0: IAuth0User | null = userAuth0Var()
  const { intercomUserHash } = userVar() || {}

  const {
    userId, email, createdDate = '', metadata
  } = userAuth0 || {}
  const { firstName, lastName } = metadata || {}
  const date = new Date(createdDate)
  const timestamp = date.getTime()

  useEffect(() => {
    intercomUserHash && Intercom({
      api_base: ExternalSources.IntercomApiBaseUrl,
      app_id: window.env.INTERCOM_APP_ID,
      user_id: userId,
      name: firstName || lastName ? `${firstName} ${lastName}`.trim() : undefined,
      email,
      created_at: timestamp,
      user_hash: intercomUserHash

    })
  }, [ intercomUserHash ])

  return (
    <>
      <GlobalStyle />
      <Content collapsedNavigation={collapsedNavigation} data-testid="content-layout" className="col h-full p-0">
        <TrialExtendTimeContainer />
        <OutletWrapper className="w-full p-0">
          <Outlet />
        </OutletWrapper>
      </Content>
    </>
  )
}
