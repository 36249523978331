import { connect } from 'react-redux'
import  FlagsSelector from '../../../../redux/selectors/flags.selector'
import  AccountSelector from '../../../../redux/selectors/account.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import  { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import { inventoryRootItemIdSelector } from '../../../../redux/selectors/inventoryTree.selector'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'
import { returnNested } from '../../../../utils/tools'
import LifecycleDetails from './lifecycleDetails'

const getIsDetailsPanelReadOnly = state => {
  const isSelectedLifecycle = LifecycleSelector.lifecycleId(state) === LifecycleSelector.detailsPanelLifecycleId(state)
  const isShowLifecycleScenarioInventory = FlagsSelector.isShowLifecycleScenarioInventory(state)
  const isInventoryCompare = ComparisonSelector.isInventoryCompare(state)
  const isScenarioReadOnly = isShowLifecycleScenarioInventory && isSelectedLifecycle
  const isComparisonReadOnly = isInventoryCompare && !isSelectedLifecycle
  return isScenarioReadOnly || isComparisonReadOnly
}

const mapStateToProps = (state, ownProps) => ({
  accountId: AccountSelector.selectedAccountId(state),
  selectedProductId: SelectedProductSelector.productId(state),
  isCreateLifecycle: FlagsSelector.isCreateLifecycle(state),
  inventoryRootItemId: inventoryRootItemIdSelector(state),
  lifecycle: returnNested(ownProps, 'lifecycle'),
  isDetailsPanelReadOnly: getIsDetailsPanelReadOnly(state)
})

export default connect(
  mapStateToProps,
  null
)(LifecycleDetails)
