import { CheckCircleFilled } from '@ant-design/icons'
import { /* Button, */ Card, Modal, Tag, Row, Col } from 'antd'
import React from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { SUBSCRIPTION_STATUS, TRACK_EVENT_TYPE } from '../../../../utils/const'
import { isEmpty, returnNested, safeArray } from '../../../../utils/tools'
import SubscriptionChoice from '../subscriptionChoice.container'
import Moment from 'react-moment'
import { extractStripePriceKey } from '../../../../utils/tools'

class SubscriptionProductDetails extends React.Component {
  state = {
    showChangeSubscriptionProduct: false
  }

  mapSubscriptionProductIdToTranslation = () => {
    const { subscriptionProduct, t } = this.props
    const { lookupKey } = subscriptionProduct
    const priceKey = extractStripePriceKey(lookupKey)
    return {
      id: priceKey,
      title: t('billing.subscription.planTitle', { context: priceKey }),
      description: t('billing.subscription.planDescription', { context: priceKey }),
      features: t('billing.subscription.planFeatures', { context: priceKey, returnObjects: true })
    }
  }

  changeSubscriptionProductCallback = () => {
    const { trackEventSaga, isUpdate } = this.props
    this.setState({ showChangeSubscriptionProduct: false })
    trackEventSaga(isUpdate ? TRACK_EVENT_TYPE.SUBSCRIPTION_CHANGED : TRACK_EVENT_TYPE.SUBSCRIPTION_PURCHASED)
  }

  renderSubscriptionStatus = () => {
    const { subscription, t } = this.props
    const status = returnNested(subscription, 'status')
    const currentPeriodEnd = returnNested(subscription, 'currentPeriodEnd')
    const statusText = t(`billing.subscription.status.${ status }`)
    let tagText = statusText
    let tagColor

    switch (status) {
    case SUBSCRIPTION_STATUS.INCOMPLETE:
      tagColor = 'orange' //'warning'
      break
    case SUBSCRIPTION_STATUS.CANCELED:
      tagColor = 'red' //'error'
      tagText = <span><b>{statusText}</b>: {t('billing.active_until')} <Moment date={ currentPeriodEnd } format='DD/MM/YYYY'/></span>
      break
    case SUBSCRIPTION_STATUS.UNPAID:
    case SUBSCRIPTION_STATUS.PAST_DUE:
    case SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED:
      tagColor = 'red' //'error'
      break
    case SUBSCRIPTION_STATUS.TRIALING:
    case SUBSCRIPTION_STATUS.ACTIVE:
      tagColor = 'green'//'success'
      break
    default:
      tagColor = 'magenta' // 'default'
      break
    }
    return <Tag color={ tagColor }>{ tagText }</Tag>
  }

  render() {
    const { /* subscription, */ subscriptionProduct, t } = this.props
    const { showChangeSubscriptionProduct } = this.state

    if (isEmpty(subscriptionProduct)) return null
    const mappedSubscriptionProduct = this.mapSubscriptionProductIdToTranslation()
    return (
      <div className="plan-details">
        <Card
          type="inner"
          className="card-item card-title-big"
          title={ t('billing.current_plan') }
          // Temporary disabled until change plan bussiness process will be defined
          // extra={ returnNested(subscription, 'status') !== SUBSCRIPTION_STATUS.CANCELED &&
          // <Button type="default" className="button" onClick={ () => this.setState({ showChangeSubscriptionProduct: true }) }>{ t('billing.change_plan') }</Button>
          //}
        >
          <Modal
            title={ t('billing.change_plan') }
            visible={ showChangeSubscriptionProduct }
            onCancel={ () => this.setState({ showChangeSubscriptionProduct: false }) }
            footer={ null }
            centered={true}
            width="80%"
          >
            <SubscriptionChoice
              isUpdate={ true }
              changeSubscriptionProductCallback={ this.changeSubscriptionProductCallback }
            />
          </Modal>
          <Row>
            <Col span={12}>
              <Card
                type="inner"
                className={ `plan-item plan-${ mappedSubscriptionProduct.id }` }
                bordered="false"
              >
                <Row className="plan-title">
                  <Col flex="auto" className="name">{ mappedSubscriptionProduct.title }</Col>
                  <Col flex="30px" className="status text-right">{ this.renderSubscriptionStatus() }</Col>
                </Row>
                <div className="description">{ mappedSubscriptionProduct.description }</div>
                <div className="features">
                  <span className="title">{ t('billing.includes')}</span>
                  { safeArray(mappedSubscriptionProduct.features)
                    .map(feature => <Row key={feature} className="features-item flex-nowrap">
                      <Col flex="24px">
                        <CheckCircleFilled />
                      </Col>
                      <Col flex="auto">{ feature }</Col>
                    </Row>)
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

export default compose(
  withTranslation()
)(SubscriptionProductDetails)
