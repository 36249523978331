import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { faChartSimple, faTable } from '@fortawesome/pro-regular-svg-icons'
import { selectedWorkspaceVar } from '../../../graphql/cache'

import { HideImpactBarComponent } from '../component/product/hide-impact-bar.component'
import { TProductDetailContext } from '../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../product/provider/context/product-detail.context'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { AnalysisMode } from '../enum/analysis'
import { isValid } from '../../shared/util/tools'
import { AnalysisEmptyStateComponent } from '../component/analysis-empty-state.component'
import { Navigation } from '../../shared/enum'

type TInsightWrapperLayoutProps = {
  children?: React.ReactNode
}
export const InsightWrapperLayout = ({
  children
}: TInsightWrapperLayoutProps) => {
  const { selectedInventoryViewType } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    primaryCustomImpacts,
    primaryHasInventory,
    primaryReferenceProduct,
    primaryLeafItems = [],
    secondaryLeafItems = [],
    secondaryReferenceProduct,
    secondaryCustomImpacts,
    secondaryHasInventory,
    analysisMode
  } = useContext<TAnalysisContext>(AnalysisContext)

  const { t } = useTranslation([ 'analysis', 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}

  const { name: impactCategoryName = '' } = space?.impactCategory || {}
  const isFlatViewType = () => selectedInventoryViewType === InventoryViewType.FlatView
  const analysisInScenario = analysisMode === AnalysisMode.Scenario

  const thereIsPrimaryItemWithImpact = primaryLeafItems.some(item => (item.impactAmount && Math.abs(Number(item.impactAmount)) > 0))
  const thereIsSecondaryItemWithImpact = secondaryLeafItems.some(item => (item.impactAmount && Math.abs(Number(item.impactAmount)) > 0))
  const thereIsItemWithImpact = thereIsPrimaryItemWithImpact || thereIsSecondaryItemWithImpact
  const hasNoInventory = !primaryHasInventory && !secondaryHasInventory
  const productHasCustomImpact = primaryCustomImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
    && secondaryCustomImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !isValid(primaryReferenceProduct) && !isValid(secondaryReferenceProduct)

  const backButtonLabel = isFlatViewType()
    ? t('labels.tab', { context: 'table', ns: 'common' })
    : t('labels.tab', { context: 'stackedChart', ns: 'common' })
  const title = isFlatViewType()
    ? t('labels.emptyState', { context: hasNoInventory ? 'addItemsToShowTable' : 'addImpactToShowTable', ns: 'common' })
    : t('labels.emptyState', { context: hasNoInventory ? 'addItemsToShowStackedChart' : 'addImpactToShowStackedChart', ns: 'common' })
  const path = AnalysisMode.Comparison ? Navigation.ProductComparisonTabs : Navigation.ProductScenarioTabs
  const icon = isFlatViewType() ? faTable : faChartSimple

  if (productHasNoImpact && !thereIsItemWithImpact) {
    return <AnalysisEmptyStateComponent icon={icon} backButtonLabel={backButtonLabel} path={path} title={title} />
  }

  return (
    <div className="flex flex-column w-full border-round-lg bg-white">
      <div className="flex-none flex p-5 w-full">
        <div data-testid="table-wrapper-title" className="flex flex-grow-1 text-gray-700 text-2xl">
          {
            t('labels.panelHeader', {
              context: analysisInScenario ? 'productScenario' : 'productComparison',
              impactCategory: impactCategoryName
            })
          }
        </div>
        <div data-testid="table-wrapper-hideImpactBar" className="flex-none flex">
          {isFlatViewType() && <HideImpactBarComponent />}
        </div>
      </div>
      <div data-testid="table-wrapper-body" className="flex flex-grow-1 justify-content-center w-full p-5 pt-0 model-layout-body">
        {children}
      </div>
    </div>
  )
}
