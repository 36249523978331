import { LifeCycleSortField, Order, ProductSortField } from '../../../__generated__/graphql'
import { ViewType } from '../enum'
import { LocalStorage } from '../enum/storage'

export type TOverviewSetting = {
  selectedViewType?: ViewType,
  selectedSortBy?: ProductSortField | LifeCycleSortField,
  selectedSortOrder?: Order
}

export const defaultSetting: TOverviewSetting = {
  selectedViewType: ViewType.Grid,
  selectedSortBy: ProductSortField.Name,
  selectedSortOrder: Order.Asc
}

export const getOverviewSetting = (): TOverviewSetting => {
  const overviewSetting = localStorage.getItem(LocalStorage.OverviewSetting)
  return overviewSetting ? JSON.parse(overviewSetting) : defaultSetting
}

export const setOverviewSetting = (setting: TOverviewSetting = defaultSetting) => {
  const overviewSetting = getOverviewSetting()

  localStorage.setItem(LocalStorage.OverviewSetting, JSON.stringify({ ...overviewSetting, ...setting }))
}
