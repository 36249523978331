import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  query products($spaceID: ID!, $categories: [ProductCategory!], $sortBy: ProductSortBy, $query: String,
    $pageSize: Int, $currentPage: Int, $labels: [ID!], $tags: [ID!],  $folderID: ID)
  {
    products(spaceID: $spaceID, categories: $categories, sortBy: $sortBy, query: $query,
      pageSize: $pageSize, currentPage: $currentPage, labels: $labels, tags: $tags, folderID: $folderID)
    {
      items {
        __typename
        ... on Folder {
          id
          name
          updatedAt
        }
        ... on Product {
          ...extendedProductFragment
        }
      }
      total
      currentFolder {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
  ${extendedProduct}
`
