import React, {
  useContext, useEffect, useRef
} from 'react'

import styled, { createGlobalStyle } from 'styled-components'
import { useQuery } from '@apollo/client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { classNames } from 'primereact/utils'

import { useTranslation } from 'react-i18next'
import { CATEGORIES } from '../../graphql/query'
import { CategoryType } from '../enum/category'
import { CategoryFilterComponent } from '../component/filter/category-filter.component'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { Category } from '../../../__generated__/graphql'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { CategoryFilterItem } from '../interface/category-filter-item'

const GlobalStyle = createGlobalStyle`
  .elementary-flow-menu {
    .p-menuitem:not(:last-child) > div {
      padding-bottom: 1rem !important;
      margin-bottom: 0.5rem;
    }
  }
`

const CategoryFilterWrapper = styled.div`
  height: calc(100% - 2rem);
  overflow: overlay;

  .p-button-label {
    font-size: .875rem;
  }
`

type TCategoryContainerProps = {
  type: CategoryType
}
export const CategoryFilterContainer = ({
  type
}: TCategoryContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const menuRef = useRef<Menu>(null)

  const {
    selectedDatasetViewType,
    categoryFilterItems = [],
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const getSelectedCategoryId = () => {
    if (categoryFilterItems.length > 0) {
      return categoryFilterItems[categoryFilterItems.length - 1].parentCategory?.id || ''
    }
    return ''
  }
  const lastSelectedId = getSelectedCategoryId()

  const isTheSameCategoriesList = (categories: Category[]) => {
    if (categoryFilterItems?.length == 0) return false
    const lastList = categoryFilterItems[categoryFilterItems.length - 1]
    return lastList?.categories?.[0]?.id === categories?.[0]?.id
  }

  const {
    loading: loadingCategories,
    data: categoriesData,
  } = useQuery(CATEGORIES, {
    variables: {
      parentId: lastSelectedId,
      types: [ type ]
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    const { categories = [] } = categoriesData || {}
    if (!loadingCategories && categories.length > 0 && !isTheSameCategoriesList(categories)) {
      const defaultCategoryFilterItems: CategoryFilterItem[] = [ {} ]
      const updatedFilterItems = categoryFilterItems.length > 0 ? categoryFilterItems : defaultCategoryFilterItems
      updatedFilterItems[updatedFilterItems.length - 1].categories = categories

      // This fix is for preselecting first category option for elementary flow
      if (type === CategoryType.Flow && lastSelectedId === '') {
        const elementaryFlowItemToPreselect = categories.find(item => item.name === 'Elementary flows')
        updatedFilterItems[0].selectedCategory = elementaryFlowItemToPreselect
        updatedFilterItems.push({ parentCategory: elementaryFlowItemToPreselect, categories: [] })
      }
      updateFilterValue({
        categoryFilterItems: updatedFilterItems
      })
    }
  }, [ loadingCategories, categoriesData ])

  const handleCategorySelection = (selectedCategory: Category, parentCategoryId?: string) => {
    const selectedCategoryIndex = categoryFilterItems.findIndex(categoryFilterItem => categoryFilterItem.parentCategory?.id === parentCategoryId)
    const updatedFilterItems = categoryFilterItems
      .filter((value, index) => value && index <= selectedCategoryIndex)
      .map(categoryFilterItem => {
        if (categoryFilterItem.parentCategory?.id === parentCategoryId) {
          return { ...categoryFilterItem, selectedCategory }
        }
        return categoryFilterItem
      })

    if (!selectedCategory.isLeaf) {
      updatedFilterItems.push({ parentCategory: selectedCategory, categories: [] })
    }
    updateFilterValue({ categoryFilterItems: updatedFilterItems })
  }

  const isSelectedViewType = (datasetViewType: ImpactDatasetViewType) => datasetViewType === selectedDatasetViewType

  const elementaryFlowOptionTemplate = (item: any, options:any) => {
    const { label } = item
    const isRecommended = label === 'Elementary flows'

    return (
      <div className={classNames('flex flex-column w-18rem pb-2', { 'border-bottom-1 border-gray-100': isRecommended })}>
        <div className="block py-2 font-medium text-sm">
          { isRecommended ? 'Recommended' : 'When using EF2.0 method:' }
        </div>
        <div onClick={options.onClick} className={classNames('block p-2 border-round-md cursor-pointer text-sm', { 'bg-gray-50': isRecommended })}>
          { label }
        </div>
      </div>
    )
  }

  const elementaryFlowOptions = (categoryFilterItems.length > 0)
    ? categoryFilterItems[0].categories?.map(category => ({
      value: category,
      label: category.name,
      command: (event: any) => {
        const { value: selectedCategory } = event.item || {}
        handleCategorySelection(selectedCategory)
      },
      template: elementaryFlowOptionTemplate
    }))
    : []

  const showCategoryFilter = (index: number) => (isSelectedViewType(ImpactDatasetViewType.ElementaryFlow) && index > 0) || isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)

  return (
    <>
      <div className="flex w-full h-2rem px-4">
        <div className="flex w-1rem h-full align-items-center text-gray-500">
          <FontAwesomeIcon data-testid="sidebar-filter-icon" icon={faFilter} className="text-base" />
        </div>
        <div className="flex flex-grow-1 align-items-center h-full pl-2 text-gray-700 font-medium text-base">
          { t('labels.filters') }
        </div>
      </div>

      <CategoryFilterWrapper className="flex flex-column w-full px-4 pb-0">
        <GlobalStyle />
        <Menu model={elementaryFlowOptions} popup ref={menuRef} id="popup_menu" className="elementary-flow-menu mt-1 w-auto elementary-flow-menu" />

        { isSelectedViewType(ImpactDatasetViewType.ElementaryFlow) && (
          <div className="flex w-full pt-3 mb-3">
            <Button
              className="p-button p-component p-button-outlined p-button-plain text-base w-full text-left text-gray-100 h-3rem px-2"
              label={categoryFilterItems[0]?.selectedCategory
                ? categoryFilterItems[0]?.selectedCategory.name
                : 'Select elementary flow'}
              onClick={(event: any) => menuRef?.current?.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
              data-testid="first-level-elementary-flow"
            >
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            </Button>
          </div>
        )}

        { categoryFilterItems?.map((categoryFilterItem, index) => (
          <div className="block w-full" key={`category-wrapper-${index}`}>
            {
              showCategoryFilter(index) && (
                <CategoryFilterComponent
                  categoryFilterItem={categoryFilterItem}
                  handleCategorySelection={handleCategorySelection}
                />
              )
            }
          </div>
        ))}
      </CategoryFilterWrapper>
    </>
  )
}
