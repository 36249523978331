import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faGrid2 } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { store } from '../../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { Status } from '../../../shared/enum/status'
import { DecimalViewType, ProductLabel } from '../../../shared/enum'
import { ImpactBarComponent } from '../impact-bar.component'

const RootProductNameWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: var(--primary-500) !important;
  }
`

type TTreeFooterComponent = {
  classNamePrefix?: string
}

export const TreeFooterComponent = ({
  classNamePrefix = 'tree-footer-component'
}: TTreeFooterComponent) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    productInventory: {
      product: {
        id = null, unit = null, name = null, labels = []
      } = {}
    } = {},
    totalImpact = null,
    getImpactType = () => {},
    getMaxImpactAmount = () => {},
    selectedDecimalViewType = DecimalViewType.NumericValue
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ productRef, hasProductOverflow, productDom ] = useRefOverflow()
  const isImpactPending = () => totalImpact?.status === Status.Pending
  const [ productLabel ] = labels || []
  const isObject = productLabel?.id === ProductLabel.Object

  const maxImpactAmount = getMaxImpactAmount()
  const totaloImpactRatio = totalImpact?.amount ? parseFloat(totalImpact?.amount) / maxImpactAmount : 0
  const impactPercent = `${(Math.abs(totaloImpactRatio) * 100).toFixed(2)}%`
  const impactType = `${String(getImpactType())}Bar`

  const openDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedProductId = SelectedProductSelector.productId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (id === selectedProductId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    store.dispatch(showProductInDetailsPanelSaga(id))
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { name }
      {' '}
    </TooltipOverflowContent>
  )

  const showImpactBar = totalImpact?.amount && totalImpact?.amount !== '0'
  const isPercentage = selectedDecimalViewType === DecimalViewType.PercentageValue

  return (
    <>
      <Tooltip target={productDom} onBeforeShow={() => hasProductOverflow} position="bottom">{ getTooltipContent() }</Tooltip>
      <div className="flex-none flex align-items-center justify-content-center text-primary-500 bg-primary-50 border-primary-100 border-1 border-round h-2rem w-2rem">
        <FontAwesomeIcon data-testid="tree-footer-icon" icon={isObject ? faGrid2 : faTag} size="lg" />
      </div>
      <RootProductNameWrapper
        ref={productRef}
        onClick={openDetailPanel}
        data-testid="tree-footer-name"
        data-cy="inventory-footer-name"
        className={`flex-grow-1 align-items-center px-3 pt-1 text-base font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis ${classNamePrefix}-name`}
      >
        { t('labels.unitOfEntityName', { ns: 'common', unit, name }) }
      </RootProductNameWrapper>
      <div className="flex-none flex align-items-center text-base">
        { t('labels.total', { ns: 'common' }) }
        {' '}
      </div>
      <div data-cy="inventory-footer-impact" className={`flex-none flex align-items-center font-medium pl-2 pr-3 text-base ${classNamePrefix}-impact`}>
        { isImpactPending() && (
          <div className="flex justify-content-center align-items-center pr-2">
            <i className="pi pi-spin pi-spinner text-lg text-primary-500" />
          </div>
        ) }
        { totalImpact?.amount && <DecimalPointComponent value={isPercentage ? '1' : totalImpact?.amount} enableTooltip decimalViewType={selectedDecimalViewType} /> }
        { totalImpact?.amount && !isPercentage && <>{ totalImpact?.unit }</> }
      </div>
      <div className="flex-none flex align-items-center justify-content-start text-gray-700 text-base w-11rem h-full pl-2">
        { showImpactBar && (
          <div data-cy="item-impact-bar" data-testid="item-impact-bar" className="flex-none flex align-items-center justify-content-start w-10rem pr-2 line-height">
            <ImpactBarComponent nodeKey="total" impactPercent={impactPercent} impactType={impactType} inventoryKey="total" disabledTooltip />
          </div>
        ) }
      </div>
    </>
  )
}
