import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'

export type CopyLifecycleComponentProps = {
  showCopyLifecycleDialog: boolean,
  closeCopyLifecycleDialog: () => void,
  handleCopyLifecycle: () => void
}
export const CopyLifecycleComponent = ({
  showCopyLifecycleDialog,
  closeCopyLifecycleDialog,
  handleCopyLifecycle,
}: CopyLifecycleComponentProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const { selectedLifecycle } = useContext<TLifecycleContext>(LifecycleContext)

  const onHide = () => {
    closeCopyLifecycleDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="create-folder-dialog"
      header="Copy Lifecycle"
      visible={showCopyLifecycleDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div className="col-12 p-0">
          { t('messages.removedLifecycle', { ns: 'lifecycle', context: 'warning', name: selectedLifecycle?.name }) }
        </div>
        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product"
            label={t('actions.cancel')}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleCopyLifecycle}
            data-testid="button-copy-lifecycle"
            data-cy="button-copy-lifecycle"
            label={t('actions.copy')}
            iconPos="left"
            loading={false}
            className="p-button-primary justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
