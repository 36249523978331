import React, { useContext } from 'react'

import { faCircleInfo, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, useTranslation } from 'react-i18next'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'

export const SearchFieldHelpComponent = () => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { updateImpactDataset } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  return (
    <div className="flex flex-column w-full h-full">
      <div className="flex px-4 py-0 w-full">
        <div className="flex w-1rem align-items-center text-primary-500">
          <FontAwesomeIcon icon={faCircleInfo} className="text-basis" />
        </div>
        <div className="flex flex-grow-1 align-items-center justify-content-start text-basis text-gray-700 font-medium px-3">
          { t('labels.searchTips', { context: 'title' }) }
        </div>
        <div data-testid="close-search-tips" className="flex w-1rem align-items-center text-gray-500 cursor-pointer" onClick={() => updateImpactDataset({ showSearchTips: false })}>
          <FontAwesomeIcon icon={faXmark} className="text-lg" />
        </div>
      </div>

      <div className="flex p-4 pb-0 w-full text-sm text-gray-500">
        { t('labels.searchTips', { context: 'subtitle' }) }
      </div>

      <div className="flex flex-column p-4 pb-1 w-full">
        <div className="flex p-0 w-full text-basis text-gray-500 font-medium border-bottom-1 border-gray-100">
          { t('labels.default', { ns: 'common' }) }
        </div>
        <div className="flex p-0 w-full text-sm text-gray-500 pt-2">
          { t('labels.searchTips', { context: 'default' }) }
        </div>
      </div>

      <div className="flex flex-column p-4 pb-1 w-full">
        <div className="flex p-0 py-1 w-full border-bottom-1 border-gray-100 gap-2">
          <div className="flex align-items-center justify-content-center w-2rem h-2rem bg-gray-100 text-gray-500">'</div>
          <div className="flex flex-grow-1 align-items-center text-basis text-gray-500 font-medium">
            { t('labels.searchTips', { context: 'quotationTitle' }) }
          </div>
        </div>
        <div className="block p-0 w-full text-sm text-gray-500 pt-2">
          <Trans t={t} ns="impact-dataset" i18nKey="labels.searchTips_quotationBody" />
        </div>
      </div>

      <div className="flex flex-column p-4 pb-1 w-full">
        <div className="flex p-0 py-1 w-full border-bottom-1 border-gray-100 gap-2">
          <div className="flex align-items-center justify-content-center w-2rem h-2rem bg-gray-100 text-gray-500">-</div>
          <div className="flex flex-grow-1 align-items-center text-basis text-gray-500 font-medium">
            { t('labels.searchTips', { context: 'minusTitle' }) }
          </div>
        </div>
        <div className="block p-0 w-full text-sm text-gray-500 pt-2">
          <Trans t={t} ns="impact-dataset" i18nKey="labels.searchTips_minusBody" />
        </div>
      </div>

    </div>
  )
}
