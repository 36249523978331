import React, { useContext, useState } from 'react'
import { classNames } from 'primereact/utils'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPage, faFolder } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { TreeItemAmountContainer } from '../../container/model/tree-item-amount.container'
import { MenuTreeItemContainer } from '../../container/menu/menu-tree-item.container'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { isValid } from '../../../shared/util/tools'
import { store } from '../../../../configureStore'
import { setSelectedProductAction } from '../../../../redux/actions/global.actions'
import { setIsForcingWorkspacePanelAction, setIsImpactSelectorDialogShowedAction } from '../../../../redux/actions/flags.actions'
import { ImpactBarComponent } from '../impact-bar.component'
import { normalize } from '../../../../utils/tools'
import { Status } from '../../../shared/enum/status'
import { DecimalViewType } from '../../../shared/enum'
import { TLifecycleDetailContext } from '../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../lifecycle/provider/context/lifecycle-detail.context'
import { getImpactBarAttr } from '../../../shared/util/impact'
import { useDatasetNavigate } from '../../../impact-dataset/hook/use-dataset-navigate'
import { Feature } from '../../../shared/enum/feature'
import FeatureContext from '../../../dashboard/context/feature.context'

type TNodeProps = {
  crop?: boolean
  type?: boolean
  width?: any
  mouseOverNode?: boolean
}

const Node = styled.div<TNodeProps>`
  width: ${(props: TNodeProps) => (props.crop ? 'calc(100% - 2.25rem)' : '100%')};

  .line-height {
    height: 1.563rem;
  }

  .btn-add-action:hover {
    color: var(--gray-500) !important;
  }
`

const NodeAction = styled.div<TNodeProps>`
  width: 4rem;
  visibility: ${(props: TNodeProps) => (props.mouseOverNode ? 'visible' : 'hidden')};

  .node-action-more {
    border: none !important;
  }
`

export type TInventorTreeItemProp = {
  node?: any
  selectedKey?: any
  onNodeClick?: Function
}
export const TreeItemComponent = ({ node, selectedKey, onNodeClick }: TInventorTreeItemProp) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ mouseOverNode, setMouseOverNode ] = useState(false)
  const {
    analysisMode,
    totalImpact: { amount: totalImpactAmount = '' } = {},
    getMaxImpactAmount,
    addInventoryItem,
    selectedDecimalViewType,
    readonlyInventory = false,
    noInventoryImpactBar = false,
    inventoryKey,
    lifecyclePhase
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    getMaxImpactAmount: getMaxLifecycleImpactAmount,
    lifecycleTotalImpact,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ labelRef, hasLabelOverflow, labelDom ] = useRefOverflow()

  const {
    expanded, key, label, data = {}, children
  } = node || {}
  const {
    type, inventoryItem, parentItem, ancestors = []
  } = data || {}
  const { impact: { amount: impactAmount = '', status: impactStatus = null } = {}, product = {} } = inventoryItem || {}
  const {
    unit = null, customImpacts = [], referenceProduct = null, hasInventory = false
  } = product

  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableNewImpactDataset = isFeatureEnabled(Feature.NewImpactDataset) || false
  const { datasetNavigate } = useDatasetNavigate(product!)

  const isRoot = () => key === 'node:root'
  const isSelected = () => key === selectedKey
  const isOneLevelDeep = () => ancestors.length >= 2
  const hasChild = () => children?.length > 0
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { label }
      {' '}
    </TooltipOverflowContent>
  )
  const isImpactPending = () => impactStatus === Status.Pending

  const { impactType, impactPercent, impactRatioRelativeToTotal } = getImpactBarAttr({
    impactAmount,
    totalImpactAmount: lifecycleTotalImpact ? lifecycleTotalImpact.amount || '0' : totalImpactAmount,
    maxImpactAmount: getMaxLifecycleImpactAmount ? getMaxLifecycleImpactAmount() : getMaxImpactAmount(),
  })

  const impactTypeToShow = isOneLevelDeep() ? `${impactType}Deep` : impactType

  const getImpactBarTooltipContent = () => {
    const impactPercentage = impactRatioRelativeToTotal ? (Math.abs(impactRatioRelativeToTotal) * 100).toFixed(2) : 0
    return t('labels.totalImpactPercentage', { value: impactPercentage, ns: 'common' })
  }

  const hasImpactSource = () => isValid(referenceProduct) || customImpacts.length > 0

  const addImpact = () => {
    store.dispatch(setSelectedProductAction(product))

    if (enableNewImpactDataset && product?.id) {
      datasetNavigate()
    } else {
      store.dispatch(setIsImpactSelectorDialogShowedAction(true))
    }
  }

  const setSelectedLifecyclePhase = () => {
    if (lifecyclePhase && updateLifecycleDetail) {
      updateLifecycleDetail({ selectedPhase: lifecyclePhase })
    }
  }

  const handleAddSubItem = (event: any) => {
    event.stopPropagation()
    setSelectedLifecyclePhase()
    store.dispatch(setIsForcingWorkspacePanelAction())
    addInventoryItem(node)
  }

  const hideImpactAmount = () => expanded && isOneLevelDeep() && hasChild()
  const hideImpactBar = () => hideImpactAmount() || impactAmount === '0'
  const hasImpactOrInventory = (isRoot() || hasImpactSource() || hasInventory)
  const impactBarKey = `${key}:${lifecyclePhase?.id}`

  return (
    <Node
      className="flex gap-2 justify-content-end overflow-hidden"
      onMouseOver={() => setMouseOverNode(true)}
      onMouseOut={() => setMouseOverNode(false)}
      crop={hasChild()}
      data-testid="tree-item-node"
      data-cy={`item-named-${normalize(label)}`}
    >
      { !hasChild() && (
        <div className="flex-none flex align-items-center justify-content-center pl-2 line-height">
          { (type === 'File') && <div className="ml-2 tree-item-file-icon"><FontAwesomeIcon data-testid="tree-item-file-icon" icon={faPage} className="text-base" /></div> }
          { (type === 'Folder') && <FontAwesomeIcon data-testid="tree-item-folder-icon" icon={faFolder} className="text-base" /> }
        </div>
      ) }
      <div
        data-testid="tree-item-text-wrap"
        className={classNames('flex-grow-1 flex align-items-center justify-content-start overflow-hidden gap-1', {
          'flex-wrap': isValid(analysisMode),
          'line-height': !isValid(analysisMode)
        })}
      >
        <Tooltip target={labelDom} onBeforeShow={() => hasLabelOverflow} position="bottom">{ getTooltipContent() }</Tooltip>
        <div className="flex-none flex align-items-center justify-content-start line-height">
          <TreeItemAmountContainer mouseOverNode={mouseOverNode} nodeKey={key} inventoryItem={inventoryItem} parentItem={parentItem} />
        </div>
        <div className="flex-none flex align-items-center justify-content-start line-height">
          { unit ? t('labels.treeItemUnit', { context: 'value', unit }) : t('labels.treeItemUnit', { context: 'default' })}
        </div>
        <div ref={labelRef} data-cy="item-name" className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis line-height">{ label }</div>
      </div>

      { !readonlyInventory && (
        <NodeAction
          data-testid="tree-item-node-action"
          className="flex-none flex align-items-center justify-content-end w-4rem line-height"
          mouseOverNode={mouseOverNode || isSelected()}
        >
          { !isRoot() && <MenuTreeItemContainer node={node} onNodeClick={onNodeClick} onAddSubitem={handleAddSubItem} /> }
        </NodeAction>
      ) }

      <div className={classNames('flex-none flex h-full gap-4 line-height', {
        'w-19rem': !noInventoryImpactBar && !readonlyInventory,
        'w-11rem': noInventoryImpactBar && !readonlyInventory && !hasImpactOrInventory,
        'w-7rem': noInventoryImpactBar && (readonlyInventory || (!readonlyInventory && hasImpactOrInventory))
      })}
      >

        { hasImpactOrInventory ? (
          <>
            <div
              data-cy="item-impact"
              className={classNames('flex flex-grow-1 justify-content-end gap-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-right line-height', {
                'pr-2': noInventoryImpactBar
              })}
            >
              { isImpactPending() && (
                <div className="flex justify-content-center align-items-center">
                  <i className="pi pi-spin pi-spinner text-lg text-primary-500" />
                </div>
              ) }
              { hideImpactAmount() ? <></> : (
                <DecimalPointComponent
                  value={selectedDecimalViewType === DecimalViewType.PercentageValue ? impactRatioRelativeToTotal : impactAmount}
                  enableTooltip
                  decimalViewType={selectedDecimalViewType}
                />
              ) }
            </div>
            { !noInventoryImpactBar && (
              <div data-cy="item-impact-bar" data-testid="item-impact-bar" className="flex-none flex align-items-center justify-content-start w-10rem pr-2 line-height">
                { hideImpactBar() ? <></> : <ImpactBarComponent nodeKey={impactBarKey} impactPercent={impactPercent} impactType={impactTypeToShow} inventoryKey={inventoryKey} getTooltipContent={getImpactBarTooltipContent} /> }
              </div>
            )}
          </>
        ) : (
          <>
            { !readonlyInventory && (
              <div className="flex w-full h-full gap-1 align-items-center justify-content-end">
                <Button data-testid="add-impact-button" onClick={addImpact} label="Add impact" data-cy="add-reference-button" className="p-button-text text-sm p-0 h-1rem btn-add-action" />
                <span className="text-sm">or</span>
                <Button data-testid="add-sub-impact-button" onClick={handleAddSubItem} label="add item" className="p-button-text text-sm p-0 h-1rem mr-2 btn-add-action" />
              </div>
            ) }
          </>
        ) }
      </div>
    </Node>
  )
}
