import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  query processBySlug($slug: String!, $spaceID: String!){
    processBySlug(slug: $slug, spaceID: $spaceID) {
      parents(limit: 10) {
        id
        name
        slug
      }
      ...processFragment
    }
  }
  ${process}
`
