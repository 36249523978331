import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  query process($id: ID!){
    process(id: $id) {
      parents(limit: 10) {
        id
        name
      }
      ...processFragment
    }
  }
  ${process}
`
