import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  query processes($spaceID: ID!, $query: String, $pageSize: Int, $currentPage: Int) {
    processes(spaceID: $spaceID, query: $query, pageSize: $pageSize, currentPage: $currentPage) {
        items {
          parents(limit: 10) {
          id
          name
        }
        ...processFragment
        }
        total       
      }
  }
  ${process}
`