import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  mutation createProcessInput($processID: ID!, $name: String!) {
    createProcessInput(processID: $processID, name: $name) {
      ...processFragment
    }
  }
  ${process}
`
