import React, { useContext } from 'react'
import styled from 'styled-components'

import { HeaderComponent } from '../component/header/header.component'
import { TaskbarComponent } from '../component/taskbar/taskbar.component'
import { CardViewLayout } from './card-view.layout'
import ProductContext from '../provider/context/product.context'

import { TProductContext } from '../interface/product-context.type'
import { ViewType } from '../../shared/enum'
import { TableViewLayout } from './table-view.layout'

const ProductWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`

export const ProductOverviewLayout = () => {
  const { selectedViewType } = useContext<TProductContext>(ProductContext)

  return (
    <ProductWrapper
      data-testid="product-overview-wrapper"
      className="flex w-full h-screen flex-column product-overview-wrapper"
    >
      <div data-testid="product-overview-header" className="flex flex-none w-full max-h-9rem px-6 py-5">
        <HeaderComponent />
      </div>
      <div data-testid="product-overview-taskbar" className="flex flex-none w-full h-3rem px-6 py-2">
        <TaskbarComponent />
      </div>
      <div data-testid="product-overview-body" className="flex flex-none w-full px-5 py-3 flex-column">
        { selectedViewType === ViewType.Grid ? <CardViewLayout /> : <TableViewLayout /> }
      </div>
    </ProductWrapper>
  )
}
