import React, { useContext } from 'react'
import styled from 'styled-components'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { ComparisonEntity } from '../enum/entity'
import { TreeFooterComponent } from './product/model/tree-footer.component'
import { TreeHeaderComponent } from './product/model/tree-header.component'
import { useFixedSection } from '../hook/use-fixed-section'

type TreeFixedComponentProps = {
  collapsedNavigation: boolean
}

const TreeFixedComponent = styled.div<TreeFixedComponentProps>`
  width: ${(props: TreeFixedComponentProps) => (props.collapsedNavigation ? 'calc(100% - 5rem)' : 'calc(100% - 15.5rem)')};
  right: 0.5rem;
`

export const FixedComponent = () => {
  const { collapsedNavigation = false } = useContext<IDashboardContext>(DashboardContext)
  const [ showFixedHeader, showFixedFooter ] = useFixedSection()

  return (
    <>
      { showFixedHeader && (
        <TreeFixedComponent
          data-testid="header-fixed-section"
          className="hidden lg:block lg:fixed top-0 px-6"
          collapsedNavigation={collapsedNavigation}
          data-html2canvas-ignore
        >
          <div className="flex w-full px-5 gap-5">
            <div data-testid="header-fixed-original" className="flex w-6 border-round-top-lg border-bottom-1 border-gray-100 shadow-1">
              <TreeHeaderComponent entity={ComparisonEntity.Primary} />
            </div>
            <div data-testid="header-fixed-secondary" className="flex w-6 border-round-top-lg border-bottom-1 border-gray-100 shadow-1">
              <TreeHeaderComponent entity={ComparisonEntity.Secondary} />
            </div>
          </div>
        </TreeFixedComponent>
      ) }
      { showFixedFooter && (
        <TreeFixedComponent
          data-testid="footer-fixed-section"
          className="hidden lg:block lg:fixed bottom-0 h-3rem px-6"
          collapsedNavigation={collapsedNavigation}
          data-html2canvas-ignore
        >
          <div className="flex w-full h-full px-5 gap-5">
            <div data-testid="footer-fixed-original" className="flex w-6 h-full py-3 px-5 bg-white border-round-bottom-lg border-top-1 border-gray-100 shadow-1">
              <TreeFooterComponent entity={ComparisonEntity.Primary} />
            </div>
            <div data-testid="footer-fixed-secondary" className="flex w-6 h-full py-3 px-5 bg-white border-round-bottom-lg border-top-1 border-gray-100 shadow-1">
              <TreeFooterComponent entity={ComparisonEntity.Secondary} />
            </div>
          </div>
        </TreeFixedComponent>
      ) }
    </>
  )
}
