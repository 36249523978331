import * as d3 from 'd3'
import React, { useContext } from 'react'
import { faChartBar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { TDonutPhase } from '../../interface/donut-chart.type'

import { ImpactType } from '../../../shared/enum/impact'
import { DonutLegendPhaseComponent } from './donut-legend-phase.component'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Navigation } from '../../../shared/enum'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'

type TDonutLegendComponentProps = {
  phasesDonutData?: TDonutPhase[]
}

export const DonutLegendComponent = ({ phasesDonutData = [] } : TDonutLegendComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const { lifecycleId } = useParams()
  const navigate = useNavigate()

  const colorInterpolator = d3.interpolateHsl('#072b49', 'white')
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)

  const navigateToTable = () => {
    addToBackToList(t('labels.tab', { context: 'table' }))
    account?.id && space?.slug && lifecycleId && navigate(
      generatePath(Navigation.LifeCycleDetailTabs, {
        accountId: account.id,
        workspaceSlug: space.slug,
        lifecycleId,
        activeTab: InventoryViewType.FlatView
      })
    )
  }

  return (
    <div className="flex flex-column w-full">
      {
        phasesDonutData.map((donutPhase: TDonutPhase, index: number) => {
          const colorIndex = index / phasesDonutData.length
          const background = donutPhase.totalImpactType == ImpactType.Negative ? 'var(--green-200)' : d3.rgb(colorInterpolator(colorIndex)).formatRgb()

          return (
            <div key={index} className="flex w-full">
              <DonutLegendPhaseComponent donutPhase={donutPhase} background={background} index={index} />
            </div>
          )
        })
      }
      { phasesDonutData.length > 0
      && (
        <div
          data-html2canvas-ignore
          data-testid="doughnut-legend-viewAll"
          onClick={navigateToTable}
          className="flex align-items-center justify-content-end w-full px-1 text-primary-500 cursor-pointer m-2"
        >
          <div className="flex-none mr-2"><FontAwesomeIcon data-testid="doughnut-legend-viewAll-icon" icon={faChartBar} /></div>
          <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
            { t('labels.view', { context: 'allItems' }) }
          </div>
        </div>
      )}
    </div>
  )
}
