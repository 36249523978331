import React, {
  useContext, useEffect, useRef, useState, useCallback
} from 'react'
import styled from 'styled-components'

import { transformToDonutChartData } from '../../../shared/util/transform-lifecycle'
import { TDonutPhase } from '../../interface/donut-chart.type'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { DonutChartComponent } from './donut-chart.component'
import { DonutLegendComponent } from './donut-legend.component'

const DoughnutViewWrapper = styled.div`
  min-height: 25rem;
`

export const DonutComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { lifecycleLeafItems } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ phasesDonutData, setPhasesDonutData ] = useState<TDonutPhase[]>([])
  const [ donutWidthAndHeight, setDonutWidthAndHeight ] = useState<number>(500)

  const chartRef = useCallback(node => {
    if (node !== null) {
      setDonutWidthAndHeight(node.getBoundingClientRect().width)
    }
  }, [])

  useEffect(
    () => {
      const donutChartData = transformToDonutChartData(lifecycleLeafItems?.phases)
      setPhasesDonutData(donutChartData)
    },
    [ lifecycleLeafItems ]
  )

  return (
    <DoughnutViewWrapper
      data-testid="doughnut-view-wrapper"
      ref={containerRef}
      className="flex flex-column w-full h-full bar-view-wrapper"
    >
      { phasesDonutData.length > 0
      && (
        <div className="flex w-full">
          <div ref={chartRef} className="md:w-9 lg:w-8 xl:w-6">
            <DonutChartComponent phasesDonutData={phasesDonutData} donutWidthAndHeight={donutWidthAndHeight} />
          </div>
          <div className="md:w-3 lg:w-4 xl:w-6 flex align-items-center justify-content-center flex flex-column">
            <DonutLegendComponent phasesDonutData={phasesDonutData} />
          </div>
        </div>
      )}
    </DoughnutViewWrapper>
  )
}
