import { gql } from '../../../__generated__'

export const LIFE_CYCLE_PAGINATED = gql(`
  query lifecyclesPaginated($query: String, $spaceID: ID!, $currentPage: Int, $pageSize: Int, $sortBy: LifeCycleSortBy) {
    lifecyclesPaginated(query: $query, spaceID: $spaceID, currentPage: $currentPage, pageSize: $pageSize, sortBy: $sortBy) {
      items {
        id
        name
        description
        unit
        amount
        createdAt
        updatedAt
        product {
          id
          name
        }
        scenarios {
          id
          name
        }
      }
      total
    }
  }
`)

export const LIFECYCLE_DETAIL = gql(`
  query lifecycle($id: ID!){
    lifecycle(id: $id) {
      id
      name
      amount
      unit
      description
      slug
      product{
        ...minimalProductFragment
      }
      scenarios{
        id
        name
        slug
      }
      phases {
        id
        name
        type
        inventory{
          product {
            ...minimalProductFragment
          }
          maximumImpact {
            amount
          }
          totalImpact {
            amount
            unit
            status
          }
        }
        activities {
          id
          spaceID
          phaseID
          template {
            id
            name
            description
          }
          products {
            inventoryID
            productID
          }
        }
      }
    }
  }
`)

export const LIFECYCLE_LEAF_ITEMS = gql(`
  query lifecycleLeafItems($id: ID!){
    lifecycle(id: $id) {
      id
      name
      amount
      unit
      description
      slug
      product{
        ...minimalProductFragment
      }
      scenarios{
        id
        name
        slug
      }
      phases {
        id
        name
        type
        inventory{
          product {
            ...minimalProductFragment
          }
          leafInventoryItems {
            amount
            impact {
              amount
              unit
              status
            }
            product {
              id
              name
              unit
            }
            phase{
              id
              name
              type
            }
          }
          totalImpact {
            amount
            unit
            status
          }
        }
      }
    }
  }
`)
