import { isValid } from './tools'

type TErrorMessage = {
  extensions?: any
  message?: string
  path?: any
}

export const displayGraphqlErrors = (toastRef: any, summary: string, graphQlErrors: Readonly<TErrorMessage[]> = [], t?: Function) => {
  if (toastRef?.current) {
    graphQlErrors.forEach(({ message, extensions }) => {
      const { code = null, args = {} } = extensions || {}
      let detail = ''
      if (isValid(code)) {
        detail = t && t('messages.error', { context: code, ...args })
      } else if (typeof message === 'string') {
        detail = message
      }
      toastRef?.current?.show({
        severity: 'error', summary, detail, life: 3000
      })
    })
  }
}
