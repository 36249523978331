import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'antd'
import { withTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'

import { SearchListResults } from './search-list/searchListResults'
import SearchFilter from './search-filter/searchFilter'
import SearchByQuery from './searchByQuery'

import compose from '../../utils/compose'
import { PAGINATION, PRODUCT_TYPE, TRACK_EVENT_TYPE } from '../../utils/const'
import { getSelectedDatabasesStorageItem, setSelectedDatabasesStorageItem } from '../../v1/shared/util/impact'
import { selectedWorkspaceVar } from '../../graphql/cache'

const SearchReferenceProduct = props => {
  const {
    t,
    query,
    setQuery,
    fuzzy,
    setFuzzy,
    productType,
    productMutator,
    trackEventSaga,
    updatePageItems,
    selectedProduct,
    referenceDatabases,
    referencePageSize,
    referenceProductsResults,
    getReferenceDatabasesSaga,
    storeSearchTermSaga,
    changeProductReferenceSaga,
    isLoadingReferenceProducts,
    searchReferenceProductsSaga,
    setReferencePageSizeAction,
    setIsLoadingReferenceProductsAction,
    setIsImpactSelectorDialogShowedAction
  } = props

  const { space: selectedWorkspace = null } = useReactiveVar(selectedWorkspaceVar)
  const isElementaryFlow = productType === PRODUCT_TYPE.ELEMENTARY_FLOW

  const [ currentPage, setCurrentPage ] = useState(1)
  const [ filterCount, setFilterCount ] = useState(0)
  const [ locationQuery, setLocationQuery ] = useState('')
  const [ categoryId, setCategoryId ] = useState('')
  const [ selectedDatabases, setSelectedDatabases ] = useState([])
  const [ initialLoadProducts, setInitialLoadProducts ] = useState(true)
  const intervalRef = useRef(null)
  const { space: selectedSpace } = useReactiveVar(selectedWorkspaceVar) || {}
  const { impactMethod } = selectedSpace || {}
  const { id: impactMethodID } = impactMethod || {}

  useEffect(() => {
    if (impactMethodID) {
      initialLoad()
      setDefaultFilterValues()
      if (!isElementaryFlow) getReferenceDatabasesSaga()
      if (!referencePageSize) setReferencePageSizeAction(PAGINATION.PAGE_SIZE)
    }
  }, [ impactMethodID ])

  useEffect(() => {
    initialLoad()
  })
  useEffect(() => {
    const clearSearchTermInterval = () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }

    const setSearchTermInterval = () => {
      intervalRef.current = setInterval(() => {
        storeSearchTermSaga(query)
        clearSearchTermInterval()
      }, 5000)
    }
    clearSearchTermInterval()
    if (query && query !== '') {
      setSearchTermInterval()
    }
    return () => clearSearchTermInterval()
  }, [ query ])

  useEffect(() => {
    setCurrentPage(1)
    refetchReferenceProducts()
    setFilterCount(getFilterCount())
  }, [ query, fuzzy, locationQuery, categoryId, selectedDatabases ])

  useEffect(() => {
    refetchReferenceProducts()
  }, [ currentPage, referencePageSize ])

  useEffect(() => {
    const modalBodyDOM = document.querySelector('.impactSelectorDialog .ant-modal-body')
    if (!isLoadingReferenceProducts && modalBodyDOM) {
      modalBodyDOM.scrollTop = 0
    }
  }, [ isLoadingReferenceProducts ])

  useEffect(() => {
    setDefaultFilterValues()
  }, [ productType ])

  const { total, items } = referenceProductsResults || {}

  const setDefaultFilterValues = () => {
    const storedDatabaseItems = getSelectedDatabasesStorageItem()
    const { databases = [] } = selectedSpace || {}
    const defaultDatabases = storedDatabaseItems?.length > 0 ? storedDatabaseItems : databases

    setSelectedDatabases(defaultDatabases)
    setCategoryId('')
    setLocationQuery('')
  }

  const handleShowSizeChange = (current, size) => {
    setCurrentPage(current)
    setReferencePageSizeAction(size)
    setSelectedDatabases([])
  }

  const paginationOption = {
    total,
    showSizeChanger: true,
    current: currentPage,
    onChange: setCurrentPage,
    pageSize: referencePageSize,
    onShowSizeChange: handleShowSizeChange
  }

  const initialLoad = () => {
    if (initialLoadProducts) {
      setInitialLoadProducts(false)
      refetchReferenceProducts()
    }
  }

  const getFilterCount = () => {
    let count = 0

    if ( categoryId !== '' ) {
      count++
    }

    if ( locationQuery !== '' ) {
      count++
    }

    count += !isElementaryFlow ? selectedDatabases?.length : 0

    return count
  }

  const clearFilter = () => {
    setCategoryId('')
    setLocationQuery('')
    handleDatabaseChanges([])
  }

  const handleDatabaseChanges = (databases) => {
    const selectedDatabases = databases.map(database =>
      referenceDatabases.find(referenceDatabase => referenceDatabase.id === database)
    )
    setSelectedDatabasesStorageItem(selectedDatabases)
    setSelectedDatabases(selectedDatabases)
  }

  const refetchReferenceProducts = () => {
    const databases = !isElementaryFlow ? selectedDatabases.map(selectedDatabase => selectedDatabase.id) : []
    impactMethod?.id &&
      searchReferenceProductsSaga(
        {
          query,
          fuzzy,
          locationQuery,
          categoryId,
          types: productType,
          currentPage,
          databases,
          impactMethodId: impactMethod?.id
        }
      )
  }

  /**
   * @param {{id: string,
   *         referenceProperty: {id: String, name:String},
   *         referenceUnit: {id: String, name: String}
   *  }} referenceProduct
   */
  const referProductsToEachOther = referenceProduct => {
    const hasRefProperty = selectedProduct.productProperties
      .some(({ referenceProperty }) => referenceProperty?.id === referenceProduct?.referenceProperty?.id)
    const callback = (success = true) =>  {
      setIsLoadingReferenceProductsAction(false)
      if (success) {
        setIsImpactSelectorDialogShowedAction(false)
      }
      updatePageItems(!hasRefProperty)
    }

    setIsLoadingReferenceProductsAction(true)
    changeProductReferenceSaga(productMutator, selectedProduct.id, referenceProduct, callback)
    trackEventSaga(TRACK_EVENT_TYPE.ADDED_DATASET)
  }

  const isFiltered = () => {
    return query !== '' || selectedDatabases.length !== 0 || categoryId !== ''
  }
  const totalResultsText = () => {
    if (isElementaryFlow) {
      return isFiltered() ? t('global.numOfElementaryFlows', { total }) : t('global.allElementaryFlows')
    }
    return isFiltered() ? t('global.numOfDatasets', { total }) : t('global.allDatasets')
  }
  return (
    <Row type='flex' gutter={ 48 } className="search-reference">
      <Col span={ 24 } className='search-reference__query'>
        <SearchByQuery
          query={query}
          setQuery={setQuery}
          fuzzy={fuzzy}
          setFuzzy={setFuzzy}
          selectedProductName={selectedProduct?.name} />
      </Col>
      <Col span={ 16 } className='search-reference__result'>
        <SearchListResults
          items={items}
          selectedProduct={selectedProduct}
          paginationOption={paginationOption}
          isElementaryFlow={isElementaryFlow}
          referProductsToEachOther={referProductsToEachOther}
          totalResults={totalResultsText()}
          isLoadingReferenceProducts={isLoadingReferenceProducts} />
      </Col>
      <Col span={ 8 } className='search-reference__sidebar'>
        <SearchFilter
          categoryId={categoryId}
          databases={selectedDatabases}
          filterCount={filterCount}
          clearFilter={clearFilter}
          setDatabases={handleDatabaseChanges}
          setCategoryId={setCategoryId}
          locationQuery={locationQuery}
          setLocationQuery={setLocationQuery}
          isElementaryFlow={isElementaryFlow}
          referenceDatabases={referenceDatabases} />
      </Col>
    </Row>
  )
}

export { SearchReferenceProduct }
export default compose(
  withTranslation()
)(SearchReferenceProduct)
