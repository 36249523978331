import React, { useContext, useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Frame } from '../../component/layout'
import { WorkspaceHomeLayout } from './layout/workspace-home.layout'
import { selectedAccountVar, selectedWorkspaceVar } from '../../graphql/cache'
import { WorkspaceDefault } from './component/workspace.default.component'
import { ProgressSpinnerComponent } from '../shared/component/general/progress-spinner.component'
import DetailsPanel from '../../component/model/details-panel/detailsPanel.container'
import WorkspaceDetails from '../../component/model/details-panel/workspace-details/workspaceDetails.container'
import { IDashboardContext } from '../shared/interface/workspace-context-type'
import DashboardContext from '../dashboard/context/dashboard.context'
import { useIsMounted } from '../shared/hook/use-is-mounted'
import { WorkspaceProvider } from './provider/workspace.provider'

export const WorkspaceEntry = () => {
  const isMounted = useIsMounted()
  const { loading: loadingWorkspace = true, emptyWorkspace = false } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    account: { isTrialing = false, hasValidSubscription = false } = {},
    loading: loadingAccount = true,
    emptyAccount = false
  } = useReactiveVar(selectedAccountVar) || {}
  const { clearBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    isMounted() && clearBackToList()
  }, [ isMounted ])

  const hasValidSubscriptionOrInTrial = () => (isTrialing || hasValidSubscription)

  if (loadingWorkspace || loadingAccount) {
    return <ProgressSpinnerComponent dataTestId="loading-workspace-entry" size={2} />
  } if (emptyWorkspace && !emptyAccount) {
    return <WorkspaceDefault />
  }

  return (
    <WorkspaceProvider>
      <Frame>
        {() => (
          <>
            { (hasValidSubscriptionOrInTrial() || emptyAccount) && <WorkspaceHomeLayout />}
            <DetailsPanel>
              <WorkspaceDetails />
            </DetailsPanel>
          </>
        )}
      </Frame>
    </WorkspaceProvider>
  )
}
