import { useAuth0 } from '@auth0/auth0-react'
import { userAuth0Var } from '../../../../graphql/cache'

type TAuth0UserMetadata = {
  firstName?: string
  lastName?: string
  companyType?: string
  jobFunction?: string
  country?: string
  phone?: string
  use_mfa?: string
}

export const useAuth0Api = () => {
  const {
    user, getIdTokenClaims, getAccessTokenSilently, logout
  } = useAuth0()
  const {
    AUTH_API_MANAGEMENT = 'no-env',
    AUTH_RETURNTOURI = 'no-env'
  } = window.env || {}

  const refetchAuth0User = async () => {
    try {
      const userDetailsByIdUrl = `${AUTH_API_MANAGEMENT}users/${user?.sub}`

      const accessToken = await getAccessTokenSilently()

      if (!accessToken) {
        throw new Error('undefined access token')
      }
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      const userInformation = await metadataResponse.json()

      const {
        email,
        last_login: lastLogin,
        picture,
        created_at: createdDate,
        user_metadata: metadata,
        app_metadata: appMetadata,
        user_id: userId
      } = userInformation || {}

      userAuth0Var({
        email,
        userId,
        lastLogin,
        picture,
        createdDate,
        metadata,
        appMetadata,
        getIdTokenClaims,
        logout
      })

      return true
    } catch (e:any) {
      return false
    }
  }
  const updateAuth0UserMetadata = async (metadata: TAuth0UserMetadata) => {
    try {
      const userDetailsByIdUrl = `${AUTH_API_MANAGEMENT}users/${user?.sub}`

      const accessToken = await getAccessTokenSilently()

      if (!accessToken) {
        throw new Error('undefined access token')
      }
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_metadata: metadata })
      })

      return metadataResponse.json()
    } catch (e:any) {
      return undefined
    }
  }
  const passwordChange = async (email: string) => {
    try {
      const changePasswordUrl = `${AUTH_RETURNTOURI}/dbconnections/change_password`

      const accessToken = await getAccessTokenSilently()

      if (!accessToken) {
        throw new Error('undefined access token')
      }
      await fetch(changePasswordUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          email,
          connection: 'Username-Password-Authentication'
        })
      })
    } catch (e:any) {
      return undefined
    }
  }

  return {
    refetchAuth0User,
    updateAuth0UserMetadata,
    passwordChange
  }
}
