import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { TreeWrapperLayout } from '../../layout/tree-wrapper.layout'
import { ProductDetailContainer } from '../../../product/container/product-detail.container'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'
import { ComparisonEntity } from '../../enum/entity'
import { ProductDetailProvider } from '../../../product/provider/product-detail.provider'
import { ProductModelContainer } from '../../../product/container/product-model.container'
import { AnalysisMode } from '../../enum/analysis'
import { PERFORM_SCENARIO_ACTION } from '../../../graphql/mutation'
import { ScenarioAction } from '../../enum/scenario-action'

import { DeleteTreeItemContainer } from '../../../product/container/dialog/delete-tree-item.container'

import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { store } from '../../../../configureStore'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import ImpactSelectorDialog from '../../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'

import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'

type TModelPanelComponentProps = {
  entity?: ComparisonEntity
}
export const ModelPanelComponent = ({
  entity = ComparisonEntity.Primary
}: TModelPanelComponentProps) => {
  const {
    secondaryEntityId = '',
    analysisMode,
    primaryEntityId = '',
    secondaryInventoryItemsRefetch,
    secondaryUpdateDetail,
    primaryUpdateDetail,
    getProductScenarioMutatorV1 = () => {}
  } = useContext<TAnalysisContext>(AnalysisContext)
  const [ afterScenario, setAfterScenario ] = useState<{ callback?: Function }>({})

  const [
    performScenarioAction,
    {
      error: failedPerformingScenarioAction,
      data: performScenarioActionData,
      loading: performingScenarioAction
    }
  ] = useMutation(PERFORM_SCENARIO_ACTION)

  useEffect(() => {
    if (!performingScenarioAction && afterScenario?.callback
      && (failedPerformingScenarioAction || performScenarioActionData)) {
      afterScenario.callback(failedPerformingScenarioAction, performScenarioActionData)
    }
  }, [ failedPerformingScenarioAction, performScenarioActionData, performingScenarioAction ])

  const isPrimary = entity === ComparisonEntity.Primary
  const isComparison = analysisMode === AnalysisMode.Comparison

  const handleScenarioAction = (
    action: ScenarioAction,
    argument: any,
    inventoryNodeID: string,
    callback: Function
  ) => {
    if (primaryEntityId && secondaryEntityId) {
      setAfterScenario({ callback })
      performScenarioAction({
        variables: {
          action,
          args: JSON.stringify(argument),
          inventoryNodeID,
          productID: argument.productID,
          scenarioProductID: secondaryEntityId
        }
      })
    }
  }

  const getModel = () => (
    <ProductModelContainer>
      <TreeWrapperLayout entity={entity} handleScenarioAction={handleScenarioAction} />
    </ProductModelContainer>
  )

  const [ trackEventInSegment ] = getSegmentTrack()
  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const selectedProductId = SelectedProductSelector.productId(state)
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)

  const afterDeleteTreeItem = () => {
    secondaryInventoryItemsRefetch && secondaryInventoryItemsRefetch()
    primaryUpdateDetail && primaryUpdateDetail({ hasInventoryChanged: true })
    secondaryUpdateDetail && secondaryUpdateDetail({ hasInventoryChanged: true })
    trackEventInSegment(TrackEventType.DELETED_SUBITEM)
  }

  const afterImpactChange = () => {
    secondaryInventoryItemsRefetch && secondaryInventoryItemsRefetch()
    selectedProductId && isDetailsPanelOpen
      && store.dispatch(showProductInDetailsPanelSaga(selectedProductId))
  }

  return (
    <>
      { !isPrimary && secondaryEntityId
        ? (
          <ProductDetailProvider
            readonlyInventory={isComparison}
            showInventoryHeader={false}
            noInventoryImpactBar
            analysisMode={analysisMode}
          >
            <ProductDetailContainer productId={secondaryEntityId}>
              { getModel() }
            </ProductDetailContainer>

            <DeleteTreeItemContainer afterDeleteTreeItem={afterDeleteTreeItem} />

            {/* // TODO : From v0.9 : To be refactored */}
            { selectedProductId
          && (
            <ImpactSelectorDialog
              productMutator={getProductScenarioMutatorV1(selectedProductId)}
              updatePageItems={afterImpactChange}
              analysisMode={analysisMode}
            />
          )}
          </ProductDetailProvider>
        )
        : <>{ getModel() }</>}
    </>
  )
}
