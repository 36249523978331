import React from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  useNavigate, matchPath, useLocation, generatePath
} from 'react-router-dom'
import { TieredMenu } from 'primereact/tieredmenu'
import styled from 'styled-components'

import { FiGrid } from 'react-icons/fi'
import { AiOutlineHome, AiOutlineTag } from 'react-icons/ai'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { NavigationItemAttr, NavigationItemProps } from '../interface/navigation-items-props'
import { NavigationItemLayout } from '../layout/navigation-item.layout'
import { WorkspaceMenuItemComponent } from './workspace-menu-item'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { Navigation } from '../../shared/enum/navigation'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'

const PageNavigation = styled.div`
  .main-menu-item-wrapper + .p-submenu-list {
    top: 4rem !important;
    left: 0.6rem;
  }

  li.p-menuitem-active:first-child {
    .main-menu-item {
      background: var(--surface-100)
    }
  }

  .p-submenu-list {
    border-radius: 0.25rem;
    box-shadow: 0px 0.3rem 0.6rem rgba(0, 0, 0, 0.15);

    .p-menuitem {
      padding: 0 0.5rem;
    }
  }

  .p-menu-separator {
    border-top: 1px solid var(--gray-300);
    background: var(--gray-300);
    height: 1px;
  }
`

export const PageNavigationComponent = () => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [ trackEventInSegment ] = getSegmentTrack()

  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    id: accountId = null,
    isTrialing = false,
    hasValidSubscription = false
  } = account || {}
  const { slug = null } = space || {}

  const isSelectedPath = (path: Navigation) => {
    const param = matchPath(path, pathname)
    return param !== null && param !== undefined
  }

  const getWorkspacePath = (
    pathname: Navigation.ProductOverview |
    Navigation.LifeCycleOverview |
    Navigation.ObjectOverview
  ) => (accountId && slug ? generatePath(pathname, { accountId, workspaceSlug: slug }) : '')

  const hasAccountActiveSubscription = () => (isTrialing || hasValidSubscription)

  const navigateTo = (pathname: string, event: TrackEventType | null) => {
    event && trackEventInSegment(event)
    navigate(pathname)
  }

  const mainItemTemplate = (item: any, options: any) => {
    const {
      label, icon, selected, disabled, items = [], tag, dataCy, iconClassName
    } = item || {}
    const navItemProps: NavigationItemProps = {
      itemAttr: {
        label, icon, options, selected, disabled, hasItems: (items.length > 0), tag, dataCy, iconClassName
      },
      itemComponent: WorkspaceMenuItemComponent
    }
    return <NavigationItemLayout {...navItemProps} />
  }

  const isHomeSelected = () => isSelectedPath(Navigation.Root)
      || isSelectedPath(Navigation.Account)
      || isSelectedPath(Navigation.AccountDetail)
      || isSelectedPath(Navigation.WorkspaceDetail)

  const menuitems: NavigationItemAttr[] = [
    {
      label: t('labels.home', { ns: 'common' }),
      icon: <AiOutlineHome size={22} />,
      disabled: !hasAccountActiveSubscription(),
      dataCy: 'show-home-page',
      iconClassName: 'nav-icon-home',
      selected: isHomeSelected(),
      command: () => navigateTo(Navigation.Root, TrackEventType.OPENED_WORKSPACE_HOME),
      template: mainItemTemplate
    },
    {
      label: t('labels.product', { count: 2, ns: 'common' }),
      icon: <AiOutlineTag size={22} />,
      disabled: !hasAccountActiveSubscription(),
      dataCy: 'show-products-page',
      iconClassName: 'nav-icon-products',
      selected: isSelectedPath(Navigation.ProductOverview),
      command: () => navigateTo(getWorkspacePath(Navigation.ProductOverview), TrackEventType.VIEWED_PRODUCTS_OVERVIEW),
      template: mainItemTemplate
    },
    {
      label: t('labels.lifecycle', { count: 2, ns: 'common' }),
      icon: <FontAwesomeIcon data-testid="header-detail-back-icon" icon={faRecycle} className="text-2xl" />,
      disabled: !hasAccountActiveSubscription(),
      dataCy: 'show-lifecycle-page',
      iconClassName: 'nav-icon-lifecycles',
      selected: isSelectedPath(Navigation.LifeCycleOverview),
      command: () => navigateTo(getWorkspacePath(Navigation.LifeCycleOverview), TrackEventType.VIEWED_LIFECYCLE_OVERVIEW),
      template: mainItemTemplate
    },
    {
      label: t('labels.object', { ns: 'common' }),
      icon: <FiGrid size={22} />,
      disabled: !hasAccountActiveSubscription(),
      dataCy: 'show-objects-page',
      iconClassName: 'nav-icon-objects',
      selected: isSelectedPath(Navigation.ObjectOverview),
      command: () => navigateTo(getWorkspacePath(Navigation.ObjectOverview), TrackEventType.VIEWED_OBJECTS_OVERVIEW),
      template: mainItemTemplate
    }
  ]

  return (
    <PageNavigation className="card">
      <TieredMenu model={menuitems} className="surface-0 border-none w-full" />
    </PageNavigation>
  )
}
