import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import ProductDetailContext from '../provider/context/product-detail.context'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import DetailsPanel from '../../../component/model/details-panel/detailsPanel.container'
import ProductDetails from '../../../component/model/details-panel/product-details/productDetails.container'
import { store } from '../../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../../redux/actions/clear.actions'
import { MenuAction } from '../enum/menu-action'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { ILifecycle, IProduct } from '../../model'
import { TrackEventType } from '../../shared/enum/track-events'
import { EntityType } from '../../shared/enum/entity-type'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { CreateLifeCycleContainer } from '../../shared/container/create-lifecycle.container'
import ProductMutationSelector from '../../../redux/selectors/productMutation.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import { updateInventoryItemsSaga } from '../../../redux/sagas/inventoryTree.saga'
import ImpactSelectorDialog from '../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'
import { getSegmentTrack } from '../../shared/util/segment'
import { CreateScenarioContainer } from '../container/dialog/create-scenario.container'
import { CreateProductLifeCycleContainer } from '../../shared/container/create-product-lifecycle.container'
import { ExportProductCsvContainer } from '../container/dialog/export-product-csv.container'
import { ProductDetailLayout } from '../layout/product-detail.layout'
import { Feature } from '../../shared/enum/feature'
import FeatureContext from '../../dashboard/context/feature.context'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import { InventoryViewType } from '../enum/inventory-view-type'
import { UpdateProductPropertyContainer } from '../../shared/container/update-product-property.container'

export const ProductDetailComponent = () => {
  const navigate = useNavigate()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { productId = '' } = useParams()
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyComparison = isFeatureEnabled(Feature.LegacyComparisonScenario)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false

  const {
    productInventory,
    showExportProductCsv = false,
    selectedAction,
    updateProductDetail,
    refetchInventory = () => {},
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    showCreateComparisonDialog = false,
    showCreateLifeCycleDialog = false,
    showCreateLifeCycleFromProduct = false,
    showUpdatePropertyDialog = false,
    addToBackToList = () => {},
    updateDashboard = () => {}
  } = useContext<IDashboardContext>(DashboardContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const productMutator = ProductMutationSelector.productMutator(state)
  const selectedProductId = SelectedProductSelector.productId(state)

  const isAddedTreeItemAction = () => selectedAction === MenuAction.AddTreeItem

  const afterUpdateProductDetail = ({ isClosedAfterAddItem = true } = {}) => {
    if (isAddedTreeItemAction() && isClosedAfterAddItem) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      updateProductDetail({ selectedAction: null, selectedProduct: null, selectedInventoryAmountV09: null })
    }

    reloadInventoryItems()
    isAddedTreeItemAction() && trackEventInSegment(TrackEventType.CREATED_SUBITEM)
  }

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    const activeTabKey = comparisonEntity.__typename === EntityType.LifeCycle ? 'tab-flat-view' : 'tab-tree-view'
    productInventory?.product?.name && addToBackToList(productInventory?.product?.name)
    if (!enableLegacyComparison && comparisonEntity.__typename !== EntityType.LifeCycle) {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/product/${productId}/compare/?`
        + `activeTabKey=${InventoryViewType.Model}&type=${comparisonEntity.__typename}&id=${comparisonEntity.id}`)
    } else {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/product/${productId}/inventory/?`
        + `activeTabKey=${activeTabKey}&compareEntityType=${comparisonEntity.__typename}&compareEntityId=${comparisonEntity.id}`)
    }
  }

  const openDetailAfterCreate = (id: string, trackEventType?: TrackEventType) => {
    trackEventType && trackEventInSegment(trackEventType)
    updateDashboard({ showCreateLifeCycleFromProduct: false })

    productInventory?.product?.name && addToBackToList(productInventory?.product?.name)
    account?.id && space?.slug && id
      && navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/${!enableLegacyLifecycleInventory ? 'detail' : 'inventory'}/`)
  }

  const afterImpactChange = () => {
    reloadInventoryItems()
    store.dispatch(updateInventoryItemsSaga())
  }

  const reloadInventoryItems = () => {
    refetchInventory()
    refetchInventoryItems()
    updateProductDetail({ hasInventoryChanged: true })
  }

  return (
    <>
      <ProductDetailLayout />

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} /> }

      <CreateScenarioContainer />

      { showCreateLifeCycleDialog && <CreateLifeCycleContainer afterCreateLifeCycle={openDetailAfterCreate} /> }

      { showCreateLifeCycleFromProduct && <CreateProductLifeCycleContainer product={productInventory?.product} /> }

      { showExportProductCsv && <ExportProductCsvContainer />}

      {showUpdatePropertyDialog && <UpdateProductPropertyContainer onUpdateCallback={reloadInventoryItems} />}

      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <ProductDetails isEnabledForV1 updatePageItems={afterUpdateProductDetail} />
      </DetailsPanel>

      {/* // TODO : From v0.9 : To be refactored */}
      { selectedProductId && <ImpactSelectorDialog productMutator={productMutator} updatePageItems={afterImpactChange} /> }
    </>
  )
}
