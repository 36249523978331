import * as React from 'react'
import * as d3 from 'd3'
import styled from 'styled-components'

import { TDonutPhase } from '../../interface/donut-chart.type'
import { DonutPhaseComponent } from './donut-phase.component'
import { ImpactType } from '../../../shared/enum/impact'

const DonutChartWrapper = styled.div`
  .donut-chart-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    max-width: 20rem;
  }

  .donut-chart-phase {
    cursor: pointer;
  }
`

type TDonutChartComponentProps = {
  donutWidthAndHeight: number
  phasesDonutData?: TDonutPhase[]
}
export const DonutChartComponent = ({
  donutWidthAndHeight,
  phasesDonutData = []
}: TDonutChartComponentProps) => {
  const [ chartData, setChartData ] = React.useState<TDonutPhase[]>([])
  const [ showSinglePhase, setShowSinglePhase ] = React.useState<boolean>(false)
  const [ selectedSliceColor, setSelectedSliceColor ] = React.useState<string | null>(null)
  React.useEffect(() => {
    setChartData(phasesDonutData)
  }, [ phasesDonutData ])

  const height = donutWidthAndHeight
  const width = donutWidthAndHeight
  const radius = (donutWidthAndHeight - 50) / 3.5

  const pie = d3
    .pie<TDonutPhase>()
    .sort((a: any, b: any) => d3.ascending(a.order, b.order))
    .value((d: any) => d.totalImpactAmount)(chartData)
  const colorInterpolator = d3.interpolateHsl('#072b49', 'white')

  const handleOnClick = (donutPhase: TDonutPhase, sliceColor: string) => {
    if (!showSinglePhase) {
      setSelectedSliceColor(sliceColor)
      setChartData([ donutPhase ])
    } else {
      setChartData(phasesDonutData)
      setSelectedSliceColor(null)
    }
    setShowSinglePhase(!showSinglePhase)
  }

  return (
    <DonutChartWrapper>
      <div className="donut-chart-tooltip"></div>
      <svg className="donut-chart-svg" height={height} width={width}>
        <g transform={`translate(${width / 2},${height / 2})`}>
          {
            pie.map((slice: any, index: number) => {
              const donutPhase = chartData[index]
              const colorIndex = index / pie.length
              const sliceColor = showSinglePhase && selectedSliceColor ? selectedSliceColor : d3.rgb(colorInterpolator(colorIndex)).formatRgb()
              const backgroundColor = donutPhase.totalImpactType == ImpactType.Negative ? 'var(--green-200)' : sliceColor
              return (
                <g key={index}>
                  <DonutPhaseComponent
                    index={index}
                    radius={radius}
                    slice={slice}
                    showSinglePhase={showSinglePhase}
                    donutPhase={donutPhase}
                    sliceColor={backgroundColor}
                    handleOnClick={handleOnClick}
                  />
                </g>
              )
            })
          }
        </g>
      </svg>
    </DonutChartWrapper>
  )
}
