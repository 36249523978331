import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'

import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { CreateComparisonComponent } from '../component/general/create-comparison.component'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IDashboardContext } from '../interface/workspace-context-type'
import { useEntitiesPaginatedQuery } from '../hook/query/use-entities-paginated.query'
import { transformEntitiesToOptions } from '../util/transform'
import { PAGINATION } from '../constants'
import { ILifecycle, IProduct } from '../../model'
import { EntityType } from '../enum/entity-type'

type TCreateComparisonContainerProps = {
  afterCreateComparison: (comparisonEntity: IProduct | ILifecycle) => void
}

export const CreateComparisonContainer = ({
  afterCreateComparison
}: TCreateComparisonContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const toast = useRef<Toast>(null)
  const [ entities, setEntities ] = useState([])

  const {
    showCreateComparisonDialog = false,
    updateDashboard,
  } = useContext<IDashboardContext>(DashboardContext)

  const { loading: loadingEntities, entitiesData, refetchEntities } = useEntitiesPaginatedQuery({
    spaceID: space?.id,
    pageSize: PAGINATION.PAGE_SIZE
  })

  useEffect(() => {
    if (!loadingEntities) {
      const groupedEntitiesList:any = entitiesData ? [
        { label: t('labels.product', { count: 2 }), items: transformEntitiesToOptions(entitiesData.products.items.filter((product: IProduct) => (product.__typename !== EntityType.Folder))) },
        { label: t('labels.object', { count: 2 }), items: transformEntitiesToOptions(entitiesData.objects.items.filter((object: IProduct) => (object.__typename !== EntityType.Folder))) },
        { label: t('labels.lifecycle', { count: 2 }), items: transformEntitiesToOptions(entitiesData.lifecycles?.items) }
      ] : []

      setEntities(groupedEntitiesList)
    }
  }, [ loadingEntities, entitiesData ])

  const closeCreateComparisonDialog = () => {
    updateDashboard({ showCreateComparisonDialog: false })
  }

  const handleFilterEntities = (query: string = '') => {
    refetchEntities && refetchEntities({ query })
  }

  const handleCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    closeCreateComparisonDialog()
    afterCreateComparison(comparisonEntity)
  }

  return (
    <>
      { !loadingEntities && entities && (
        <CreateComparisonComponent
          entitiesList={entities}
          handleFilterEntities={handleFilterEntities}
          handleCreateComparison={handleCreateComparison}
          closeCreateComparisonDialog={closeCreateComparisonDialog}
          showCreateComparisonDialog={showCreateComparisonDialog}
        />
      )}

      <Toast data-testid="create-comparison-status" ref={toast} position="top-right" />
    </>
  )
}
