import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'

import { useTranslation } from 'react-i18next'
import { IControllerRender } from '../../interface/react-form-hook'
import { ILifecycle, IProduct } from '../../../model'

const GlobalStyle = createGlobalStyle`
  .w-32rem {
    width: 31rem;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-overflow: ellipsis;
  }
`

type CreateComparisonComponentProps = {
  entitiesList: any,
  showCreateComparisonDialog: boolean,
  handleFilterEntities: Function,
  closeCreateComparisonDialog: () => void,
  handleCreateComparison: (comparisonEntity: IProduct | ILifecycle) => void
}
export const CreateComparisonComponent = ({
  entitiesList: entitiesOptionGroup = [],
  showCreateComparisonDialog,
  closeCreateComparisonDialog,
  handleCreateComparison,
  handleFilterEntities
}: CreateComparisonComponentProps) => {
  const { t } = useTranslation([ 'common' ])

  const [ queryString, setQueryString ] = useState('')
  const defaultValues = { entity: null }
  const { control, formState: { errors }, reset } = useForm({ defaultValues })

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      handleFilterEntities(queryString)
    }, 250)
    return () => clearTimeout(delayInputTimeoutId)
  }, [ queryString, 250 ])

  useEffect(() => {
    reset()
  }, [ showCreateComparisonDialog ])

  const onHide = () => {
    reset()
    closeCreateComparisonDialog()
  }

  const handleFilter = (event: any) => {
    setQueryString(event.filter)
  }

  const onChangeProduct = (event: any) => {
    const entity = event.value
    handleCreateComparison(entity)
  }

  const getErrorMessage = (context: string): string => t('form.errorMessage', { context })

  return (
    <>
      <GlobalStyle />
      <Dialog
        className="w-32rem"
        data-testid="create-comparison-dialog"
        header="Comparison"
        visible={showCreateComparisonDialog}
        draggable={false}
        dismissableMask
        onHide={onHide}
      >
        <div className="w-full pb-6">
          <div className="col-12 px-0">
            <label htmlFor="entity" className={classNames('text-sm', { 'p-error': errors.entity })}>
              {t('labels.comparison', { context: 'selector' })}
            </label>
            <Controller
              name="entity"
              control={control}
              rules={{ required: getErrorMessage('productName') }}
              render={({ field, fieldState }: IControllerRender) => (
                <Dropdown
                  filter
                  showClear
                  id={field.entity}
                  value={field.value}
                  data-testid="create-comparison-products"
                  data-cy="entity-selector"
                  placeholder={t('labels.product', { count: 1 })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                  panelClassName="w-20rem cy-entity-list"
                  onChange={onChangeProduct}
                  onFilter={handleFilter}
                  options={entitiesOptionGroup}
                  optionLabel="label"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                />
              )}
            />
            {
              errors?.entity
            && <small className="p-error">{ errors?.entity.message }</small>
            }
          </div>

          <div className="col-12 pt-6 px-0 flex justify-content-end">
            <div className="flex-none">
              <Button
                data-testid="submit-new-comparison"
                onClick={onHide}
                label={t('actions.cancel')}
                iconPos="left"
                loading={false}
                className="p-button-outlined p-button-plain justify-content-end"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
