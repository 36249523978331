import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'

import { AddAccountComponentProps } from '../interface/account-navigation'

import { IControllerRender } from '../../shared/interface/react-form-hook'
import { JobFunction } from '../../shared/enum/job-function'

export const AddAccountComponent = ({
  handleAccountCreation,
  showAddAccountDialog,
  closeAddAccountDialog,
  emptyAccount,
  accountCreating
}: AddAccountComponentProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])

  const defaultValues = {
    firstName: '',
    lastName: '',
    name: '',
    jobFunction: '',

    switchAccount: true
  }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  useEffect(() => {
    if (!accountCreating) {
      reset()
    }
  }, [ accountCreating ])

  const jobRolesValues = Object.values(JobFunction)
  const jobRoles = jobRolesValues.map(name => ({
    label: t(`userProfile.jobFunctionList.${name}`),
    value: name,
  }))

  const onHide = () => {
    reset()
    closeAddAccountDialog()
  }

  const onSubmit = (data: any) => handleAccountCreation(data)

  const getErrorMessage = (context: string): string => t('form.errorMessage', { context })

  const dialogTitle = t('actions.header', { context: emptyAccount ? 'createFirstAccount' : 'createAccount' })

  return (
    <Dialog
      className="w-30rem"
      header={dialogTitle}
      visible={showAddAccountDialog}
      draggable={false}
      closable={!emptyAccount}
      dismissableMask={!emptyAccount}
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 px-0">
            <label htmlFor="name" className={classNames('text-sm', { 'p-error': errors.name })}>
              {t('form.placeholder', { context: 'companyName' })}
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: getErrorMessage('companyName') }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-cy="company-name-input"
                  data-testid="input-company-name"
                  placeholder={t('form.placeholder', { context: 'companyName' })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            {
              errors?.name && <small className="p-error">{ errors?.name.message }</small>
            }
          </div>
          {emptyAccount ? (
            <>
              <div className="col-12 px-0">
                <label htmlFor="firstName" className={classNames('text-sm', { 'p-error': errors.firstName })}>
                  {t('form.placeholder', { context: 'firstName' })}
                </label>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: getErrorMessage('firstName') }}
                  render={({ field, fieldState }: IControllerRender) => (
                    <InputText
                      id={field.name}
                      {...field}
                      data-cy="first-name_input"
                      data-testid="input-first-name"
                      placeholder={t('form.placeholder', { context: 'firstName' })}
                      className={classNames('w-full', { 'p-invalid': fieldState.error })}
                    />
                  )}
                />
                {
                  errors?.firstName && <small className="p-error">{ errors?.firstName.message }</small>
                }
              </div>
              <div className="col-12 px-0">
                <label htmlFor="lastName" className={classNames('text-sm', { 'p-error': errors.lastName })}>
                  {t('form.placeholder', { context: 'lastName' })}
                </label>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: getErrorMessage('lastName') }}
                  render={({ field, fieldState }: IControllerRender) => (
                    <InputText
                      id={field.name}
                      {...field}
                      data-cy="last-name_input"
                      data-testid="input-last-name"
                      placeholder={t('form.placeholder', { context: 'lastName' })}
                      className={classNames('w-full', { 'p-invalid': fieldState.error })}
                    />
                  )}
                />
                {
                  errors?.lastName && <small className="p-error">{ errors?.lastName.message }</small>
                }
              </div>
              <div className="col-12 px-0">
                <label htmlFor="jobFunction" className={classNames('text-sm', { 'p-error': errors.jobFunction })}>
                  {t('form.placeholder', { context: 'selectJobRole' })}
                </label>
                <Controller
                  name="jobFunction"
                  control={control}
                  rules={{ required: getErrorMessage('jobRole') }}
                  render={({ field, fieldState }: IControllerRender) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      data-cy="new-account-job-role"
                      data-testid="dropdown-job-role"
                      placeholder={t('form.placeholder', { context: 'jobRole' })}
                      className={classNames('w-full', { 'p-invalid': fieldState.error })}
                      onChange={(event: any) => field.onChange(event.value)}
                      options={jobRoles}
                      optionLabel="label"
                    />
                  )}
                />
                {
                  errors?.jobFunction && <small className="p-error">{ errors?.jobFunction.message }</small>
                }
              </div>
            </>
          ) : (
            <>
              <div className="col-12 px-3 pt-3">
                <div className="field-checkbox">
                  <Controller
                    name="switchAccount"
                    control={control}
                    render={({ field }: IControllerRender) => (
                      <Checkbox
                        inputId={field.name}
                        name="option"
                        data-testid="checkbox-switch-account"
                        onChange={(event: any) => field.onChange(event.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                  <label htmlFor="switchAccount" className="text-sm">
                    { t('form.label', { context: 'switchToNewAccount' }) }
                  </label>
                </div>
              </div>
            </>
          )}

          <div className="flex col-12 pt-6 justify-content-end">
            <Button
              type="submit"
              data-cy="first_account_submit"
              data-testid="create-account-submit"
              label={t('form.label', { ns: 'common', context: 'create' })}
              iconPos="left"
              loading={accountCreating}
              className="p-button-primary"
            />
          </div>
        </form>
      </div>
    </Dialog>
  )
}
