import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { useTranslation } from 'react-i18next'
import { LciDatabase } from '../../../../__generated__/graphql'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../../shared/util/impact'

export const DatabaseFilterListComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])
  const {
    databaseFilterItems = [],
    referenceDatabases,
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const updateSelectedDatabases = (databaseFilterItems: LciDatabase[] = []) => {
    setSelectedDatabasesStorageItem(databaseFilterItems)
    updateFilterValue({ databaseFilterItems, hasActiveDatabaseFilter: true })
  }

  const onDatabaseChange = (event: any) => {
    const selectedDatabases = [ ...databaseFilterItems ]
    const selectedDatabaseIndex = selectedDatabases.findIndex(elt => elt.id === event.value.id)

    if (event.checked) selectedDatabases.push(event.value)
    else selectedDatabases.splice(selectedDatabaseIndex, 1)

    updateSelectedDatabases(selectedDatabases)
  }

  const isChecked = (databaseId: string) => databaseFilterItems.map(elt => elt.id).includes(databaseId)

  return (
    <div data-testid="database-filter-wrapper" className="flex flex-column w-full">

      <ul className="w-full">
        { referenceDatabases?.map((database, index) => (
          <li
            data-testid={`database-item-${index}`}
            key={`database-item-${index}`}
            className="flex align-items-center gap-2 w-full py-1"
          >
            <Checkbox
              inputId={database.id}
              name="database"
              value={database}
              onChange={onDatabaseChange}
              data-testid={`database-checkbox-${index}`}
              checked={isChecked(database.id)}
            />
            <label
              htmlFor={database.id}
              className="line-height-2 text-sm text-gray-800"
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
              }}
            >
              { database.name }
            </label>
          </li>
        )) }
      </ul>

      <div className="flex justify-content-between w-full border-top-1 border-gray-100 pt-3 mt-3">
        <Button
          label={t('labels.clearFilters')}
          onClick={() => updateSelectedDatabases([])}
          disabled={databaseFilterItems.length === 0}
          className="p-button-outlined p-button-plain h-2rem"
        />
      </div>
    </div>
  )
}
