import { gql } from '@apollo/client'

export default gql`
  mutation renameProcess($processID: ID!, $name: String!) {
    renameProcess(processID: $processID, name: $name) {
      id
      name
      slug
      parents(limit: 10) {
        id
        name
        slug
      }
    }
  }
`
