import { PRODUCT_LABEL } from '../utils/const'
import { store } from '../configureStore'
import SpaceSelector from '../redux/selectors/space.selector'
import ProcessSelector from '../redux/selectors/process.selector'
import AccountSelector from '../redux/selectors/account.selector'
import { LifecycleSelector } from '../redux/selectors/lifecycle.selector'
import { InventoryTreeSelector } from '../redux/selectors/inventoryTree.selector'
import {
  InventoryPageLocation,
  LifecycleItemLocation,
  ProductListPageLocation,
  LifecycleListPageLocation,
  ObjectListPageLocation
} from '../component/shared/locations'
import GlobalSelector from '../redux/selectors/global.selector'

const getPreviousProcess = () => {
  const parentProcesses = ProcessSelector.selectedProcessParentProcesses(store.getState())

  if (parentProcesses && parentProcesses.length) {
    return parentProcesses[0]
  }
}

export const getPreviousProcessSlug = () =>  {
  const previousProcess = getPreviousProcess()
  return previousProcess ? previousProcess.slug : null
}

export const getPreviousProcessName = () =>  {
  const previousProcess = getPreviousProcess()
  return previousProcess ? previousProcess.name : null
}

export const goToProductList = () => {
  const state = store.getState()
  const workspaceSlug = SpaceSelector.spaceSlug(state)
  const accountId = AccountSelector.selectedAccountId(state)
  const productLabel = GlobalSelector.productLabel(state)

  const location = productLabel === PRODUCT_LABEL.OBJECT ? ObjectListPageLocation : ProductListPageLocation

  return location.toUrl({ accountId, workspaceSlug })
}

export const goToInventoryRootProduct = () => {
  const state = store.getState()
  const lifecycleProductId = LifecycleSelector.productId(state)
  const inventoryProductId = InventoryTreeSelector.productId(state)
  const accountId = AccountSelector.selectedAccountId(state)

  const productId = lifecycleProductId || inventoryProductId
  if (!productId) return goToProductList()
  return InventoryPageLocation.toUrl({
    accountId,
    workspaceSlug: SpaceSelector.spaceSlug(state),
    productId: productId,
  })
}

export const goToLifecycleRoot = () => {
  const state = store.getState()
  const lifecycleProductId = LifecycleSelector.productId(state)
  const lifecycleId = LifecycleSelector.lifecycleId(state)
  const accountId = AccountSelector.selectedAccountId(state)

  if (!lifecycleProductId) return goToLifecycleList()
  if (!lifecycleId) return goToProductList()
  return LifecycleItemLocation.toUrl({
    accountId,
    workspaceSlug: SpaceSelector.spaceSlug(state),
    lifecycleId,
    productId: lifecycleProductId
  })
}

export const goToLifecycleList = () => {
  const state = store.getState()
  return LifecycleListPageLocation.toUrl({
    accountId: AccountSelector.selectedAccountId(state),
    workspaceSlug: SpaceSelector.spaceSlug(state)
  })
}
