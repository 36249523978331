import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBringForward } from '@fortawesome/pro-regular-svg-icons'

// import { ProductLabel } from '../../../shared/enum'
import { useTranslation } from 'react-i18next'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { BackLinkComponent } from '../../shared/component/general/back-link.component'
import { useIsMounted } from '../../shared/hook/use-is-mounted'
import { TooltipOverflowContent } from '../../shared/hook/use-ref-overflow'
import { MenuToolsContainer } from '../../product/container/menu/menu-tools.container'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { AnalysisMode } from '../enum/analysis'

const HeaderWrapper = styled.div`

  .root-object-icon {
    color: var(--primarydark-400);
    background: var(--primarydark-50);
    border: 1px solid var(--primarydark-100);
  }
`

export const HeaderComponent = () => {
  const { t } = useTranslation([ 'common', 'product' ])
  const navigate = useNavigate()
  const location = useLocation()
  const isMounted = useIsMounted()
  const [ backTo, setBackTo ] = useState<string | undefined | null>(null)
  const { analysisMode } = useContext<TAnalysisContext>(AnalysisContext)
  const { backToList = [], removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { state = null } = location || {}
  const { from = null } = state || {}
  const analysisInComparisonMode = analysisMode === AnalysisMode.Comparison

  useEffect(() => {
    const backTo = backToList.length > 0 ? backToList[backToList.length - 1] : from
    isMounted() && setBackTo(backTo)
  }, [ backToList, from ])

  const handleBack = () => {
    navigate(-1)
    removeFromBackToList()
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      { t('labels.backTo', { backTo }) }
    </TooltipOverflowContent>
  )
  return (
    <HeaderWrapper>
      <div data-cy="go-back-button" className="flex align-items-center justify-content-start h-4rem">
        <BackLinkComponent handleBack={handleBack} tooltipContent={getTooltipContent()} backButtonLabel={t('labels.backTo', { backTo })} />
      </div>
      <div className="flex align-items-center justify-content-start h-3rem">
        <div className="flex align-items-center justify-content-center border-1 border-round h-3rem w-3rem px-3 text-primary-500 bg-primary-50 border-primary-100">
          <FontAwesomeIcon data-testid="header-detail-back-icon" icon={faBringForward} rotation={90} className="text-3xl" />
        </div>
        <div
          data-cy="analysis-header-title"
          data-testid="header-detail-name"
          className="flex-grow-1 text-gray-800 text-3xl font-medium px-3 white-space-nowrap overflow-hidden text-overflow-ellipsis py-1 page-header-title"
        >
          { analysisInComparisonMode ? t('labels.comparison') : t('labels.scenario') }
        </div>
        <div data-testid="header-detail-menu-tools" className="flex-none flex h-full w-6rem">
          <MenuToolsContainer fromComparison />
        </div>
      </div>
    </HeaderWrapper>
  )
}
