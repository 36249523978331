import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  mutation createProcessOutput($processID: ID!, $name: String!) {
    createProcessOutput(processID: $processID, name: $name) {
      ...processFragment
    }
  }
  ${process}
`
