import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { HeaderDetailComponent } from '../component/header/header-detail.component'
import { TaskbarDetailComponent } from '../component/taskbar/taskbar-detail.component'
import { ProductModelContainer } from '../container/product-model.container'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { InventoryViewType } from '../enum/inventory-view-type'
import { ProductInsightContainer } from '../container/product-insight.container'
import { TreeFixedSectionComponent } from '../component/tree/tree-fixed-section.component'
import { ProductModelComponent } from '../component/product-model.component'
import { ProductInsightComponent } from '../component/product-insight.component'

const ProductWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`
export const ProductDetailLayout = () => {
  const {
    showPdfInsightsResult,
    selectedInventoryViewType = InventoryViewType.Model,
    productInventory: { product = null } = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { productId = '' } = useParams()

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model
  const isInsightRendered = () => !isModelViewType() || showPdfInsightsResult

  return (
    <ProductWrapper
      data-testid="product-detail-wrapper"
      className="relative w-full h-screen flex-column product-detail-wrapper"
    >
      <div className="absolute top-0 left-0 flex w-full h-full flex-column">
        <div data-testid="product-detail-header" className="flex flex-column w-full h-8rem mb-3 px-6 py-2">
          {product && <HeaderDetailComponent />}
        </div>
        <div data-testid="product-detail-taskbar" className="flex w-full h-3rem px-6 py-2">
          <TaskbarDetailComponent />
        </div>
        <div data-testid="product-detail-body" className="flex w-full px-6 pt-3 flex-column">
          {product && isInsightRendered() && (
            <ProductInsightContainer productID={productId}>
              {' '}
              <ProductInsightComponent />
              {' '}
            </ProductInsightContainer>
          )}
          {product && isModelViewType() && (
            <ProductModelContainer>
              {' '}
              <ProductModelComponent />
              {' '}
            </ProductModelContainer>
          ) }
        </div>
      </div>
      {isModelViewType() && <TreeFixedSectionComponent />}
    </ProductWrapper>
  )
}
