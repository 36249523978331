import { useQuery } from '@apollo/client'
import { FAVORITE_PRODUCTS, PRODUCTS } from '../../../graphql/query'
import { PAGINATION } from '../../constants'
import { ProductCategory, ProductLabel } from '../../enum'
import { Order, ProductSortBy, ProductSortField } from '../../../../__generated__/graphql'

export type TProductOverviewQueryProps = {
  spaceID?: any
  query?: string
  currentPage?: number
  pageSize?: number
  sortBy?: ProductSortBy
  labels?: ProductLabel[]
  tags?: string[]
  categories?: ProductCategory[]
  folderID?: string | null
  loadFavoriteOnly?: boolean
  loadProductOnly?: boolean
  disabled?: boolean
}

export const useProductOverviewQuery = ({
  spaceID,
  query = '',
  currentPage = PAGINATION.CURRENT_PAGE,
  pageSize = PAGINATION.PAGE_SIZE,
  sortBy = { field: ProductSortField.Updated, order: Order.Desc },
  labels = [ ProductLabel.Product ],
  tags = [],
  categories = [],
  folderID = '',
  disabled = false,
  loadFavoriteOnly = false,
  loadProductOnly = false
}: TProductOverviewQueryProps) => {
  const commonArgs = {
    spaceID, query, tags, categories, labels, folderID
  }

  const {
    loading: loadingProducts,
    data: productsData,
    refetch: refetchProducts
  } = useQuery(PRODUCTS, {
    skip: loadFavoriteOnly || !spaceID || disabled,
    variables: {
      ...commonArgs, currentPage, pageSize, sortBy
    },
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingFavProducts,
    data: favoriteProductsData,
    refetch: refetchFavProducts
  } = useQuery(FAVORITE_PRODUCTS, {
    skip: loadProductOnly || !spaceID || disabled,
    variables: { ...commonArgs, sortBy: { field: ProductSortField.Updated, order: Order.Desc } },
    fetchPolicy: 'no-cache'
  })

  if (loadFavoriteOnly) {
    return { loading: loadingFavProducts, favoriteProductsData, refetchFavProducts }
  }

  if (loadProductOnly) {
    return { loading: loadingProducts, productsData, refetchProducts }
  }

  return {
    loading: loadingFavProducts || loadingProducts,
    favoriteProductsData,
    productsData,
    refetchProducts,
    refetchFavProducts
  }
}
