import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { CreateProductComponent } from '../component/product/create-product.component'

import { displayGraphqlErrors } from '../util/error'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { Navigation, ProductLabel } from '../enum'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { CREATE_PRODUCT } from '../../graphql/mutation'

type TCreateProductContainerProps = {
  afterCreateProduct: () => void,
  folderID?: string,
  label?: ProductLabel
}

export const CreateProductContainer = ({
  afterCreateProduct,
  folderID = '',
  label = ProductLabel.Product
}: TCreateProductContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const toast = useRef<Toast>(null)
  const navigate = useNavigate()
  const { space: { id: spaceID = null, slug: workspaceSlug = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account: { id: accountID = null } = {} } = useReactiveVar(selectedAccountVar) || {}
  const { showCreateProductDialog = false, updateDashboard } = useContext(DashboardContext)
  const [
    createProduct,
    {
      error: failedCreatingProduct,
      data: createProductData = {},
      loading: productCreating
    }
  ] = useMutation(CREATE_PRODUCT)
  const productCreated = createProductData?.createProduct
  useEffect(() => {
    if (!productCreating && (productCreated || failedCreatingProduct)) {
      createProductCallback()
    }
  }, [ productCreating, productCreated, failedCreatingProduct ])

  const closeCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: false })
  }

  const handleCreateProduct = (name: string, unit: string) => {
    spaceID && createProduct({
      variables: {
        name, spaceID, folderID, labels: [ label ], unit
      }
    })
  }

  const navigateToProductDetail = () => {
    const productId = productCreated?.id
    if (accountID && workspaceSlug && productId) {
      navigate(generatePath(Navigation.ProductObjectInventory, {
        accountId: accountID, workspaceSlug, productId
      }))
    }
  }

  const createProductCallback = () => {
    try {
      if (failedCreatingProduct) {
        throw failedCreatingProduct
      } else if (productCreated) {
        afterCreateProduct()
        closeCreateProductDialog()
        navigateToProductDetail()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'createProduct' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'createProduct' }), error?.graphQLErrors, t)
    }
  }

  return (
    <>
      <CreateProductComponent
        label={label}
        handleCreateProduct={handleCreateProduct}
        closeCreateProductDialog={closeCreateProductDialog}
        showCreateProductDialog={showCreateProductDialog}
      />

      <Toast data-testid="create-product-status" ref={toast} position="top-right" />
    </>
  )
}
