import { gql } from '@apollo/client'

export default gql`
  mutation createConversion($processID: ID!) {
    createConversion(processID: $processID) {
      id
      inputs {
        product {
          id
          name
          type
          unit
        }
        value
      }
      outputs {
        product {
          id
          name
          type
          unit
        }
        value
      }
      process {
        id
        name
        slug
      }
    }
  }
`
