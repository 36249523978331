import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import DashboardContext from '../context/dashboard.context'
import { ExternalSources } from '../../shared/enum'
import { selectedAccountVar } from '../../../graphql/cache'

export const TalkExpertComponent = () => {
  const { showTalkToExpert, updateDashboard } = useContext(DashboardContext)
  const { t } = useTranslation([ 'dashboard' ])
  const {
    account
  } = useReactiveVar(selectedAccountVar) || {}
  const {
    hasValidSubscription = false
  } = account || {}

  const hubspotCalendarUrl = hasValidSubscription ? ExternalSources.HubSpotCalendarForPaidUrl : ExternalSources.HubSpotCalendarForTrialUrl

  const onHide = () => {
    updateDashboard({ showTalkToExpert: false })
  }

  return (
    <Dialog data-testid="meetings-iframe-dialog" header={t('actions.chatToOurTeam')} modal visible={showTalkToExpert} style={{ width: '50vw' }} onHide={onHide}>
      <div data-testid="meetings-iframe-container" className="meetings-iframe-container" data-src={hubspotCalendarUrl}></div>
    </Dialog>
  )
}
