import { call, put, takeLatest, select } from 'redux-saga/effects'
import resource from '../../utils/resource/resource'
import { PAGINATION } from '../../utils/const'

import {
  setSelectedProductAction,
  setSelectedProcessAction,
  setSelectedSpaceProcessesAction,
  changeProcessDescriptionAction,
  setSelectedProcessNameAction,
  setProcessNameInSpaceProcessListAction,
  setSelectedSubProcessNameAction,
  setSelectedSubProcessNameInSelectedProcessAction
} from '../actions/global.actions'
import {
  setIsDetailsPanelOpenAction,
  setIsForcingWorkspacePanelAction
} from '../actions/flags.actions'
import SpaceSelector from '../selectors/space.selector'
import { dispatchErrors } from './helpers'
import { addSuccessNotificationAction } from '../actions/notification.actions'

export const SET_SELECTED_PROCESS_BY_ID = 'SET_SELECTED_PROCESS_BY_ID_SAGA'
export const REMOVE_PROCESS = 'REMOVE_PROCESS_SAGA'
export const CREATE_PROCESS = 'CREATE_PROCESS_SAGA'
export const SEARCH_PROCESS = 'SEARCH_PROCESS_SAGA'
export const CHANGE_PROCESS_DESCRIPTION = 'CHANGE_PROCESS_DESCRIPTION_SAGA'
export const RENAME_PROCESS = 'RENAME_PROCESS_SAGA'
export const RENAME_SUB_PROCESS = 'RENAME_SUB_PROCESS_SAGA'
export const SET_SELECTED_PROCESS_BY_SLUG = 'SET_SELECTED_PROCESS_BY_SLUG_SAGA'

export const setSelectedProcessByIdSaga = (processId, cb) => ({
  type: SET_SELECTED_PROCESS_BY_ID,
  processId, cb
})

export const removeProcessSaga = (processId, cb) => ({
  type: REMOVE_PROCESS,
  processId, cb
})

export const createProcessSaga = (name, spaceID, parentID, cb) => ({
  type: CREATE_PROCESS,
  name, spaceID, parentID, cb
})

export const searchProcessSaga = ({ spaceID, query, currentPage }) => ({
  type: SEARCH_PROCESS,
  spaceID, query, currentPage
})

export const changeProcessDescriptionSaga = ( processId, processDescription ) => ({
  type: CHANGE_PROCESS_DESCRIPTION,
  processId, processDescription
})

export const renameProcessSaga = ( processId, processName, cb ) => ({
  type: RENAME_PROCESS,
  processId, processName, cb
})

export const renameSubProcessSaga = ( processId, processName, cb ) => ({
  type: RENAME_SUB_PROCESS,
  processId, processName, cb
})

export const setSelectedProcessBySlugSaga = ( processSlug, selectedSpaceId, cb ) => ({
  type: SET_SELECTED_PROCESS_BY_SLUG,
  processSlug, selectedSpaceId, cb
})

function* setSelectedProcessByIdHandler(action) {
  try {
    const process = yield call(resource.queryByParams, 'process', { id: action.processId })
    yield put(setSelectedProductAction(null))
    yield put(setSelectedProcessAction(process))
    yield put(setIsDetailsPanelOpenAction(true))
    yield put(setIsForcingWorkspacePanelAction(false))
    action.cb && action.cb(process.slug)
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeProcessHandler(action) {
  try {
    const spaceID = yield select(SpaceSelector.spaceId)
    yield call(resource.mutateByParams, 'removeProcess', { processID: action.processId })
    yield call(searchProcessHandler, searchProcessSaga({ spaceID, currentPage: 1 }))
    yield put(addSuccessNotificationAction('model.process_successfully_removed'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* createProcessHandler(action) {
  try {
    const createProcessArgs = action.parentID ?
      { name: action.name, spaceID: action.spaceID, parentID: action.parentID } :
      { name: action.name, spaceID: action.spaceID }
    const process = yield call(resource.mutateByParams, 'createProcess', createProcessArgs)
    yield put(addSuccessNotificationAction('model.process_successfully_created'))
    yield put(setSelectedProcessAction(process))
    yield put(setIsDetailsPanelOpenAction(true))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* searchProcessHandler(action) {
  try {
    const processesListArgs = {
      spaceID: action.spaceID,
      query: action.query,
      currentPage: action.currentPage,
      pageSize: PAGINATION.PAGE_SIZE
    }
    if (!action.spaceID) return
    const processesList = yield call(resource.queryByParams, 'processes', processesListArgs)
    yield put(setSelectedSpaceProcessesAction(processesList))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* changeProcessDescriptionHandler(action) {
  try {
    const changeProcessDescriptionArgs = { processID: action.processId, description: action.processDescription }
    yield call(resource.mutateByParams, 'changeProcessDescription', changeProcessDescriptionArgs)
    yield put(changeProcessDescriptionAction(action.processId, action.processDescription))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* renameProcessHandler(action) {
  try {
    const renameProcessArgs = { processID: action.processId, name: action.processName }
    const process = yield call(resource.mutateByParams, 'renameProcess', renameProcessArgs)
    yield put(setSelectedProcessNameAction(process.id, process.name, process.slug))
    yield put(setProcessNameInSpaceProcessListAction(process.id, process.name, process.slug))
    yield put(addSuccessNotificationAction('model.process_renamed_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* renameSubProcessHandler(action) {
  try {
    const renameProcessArgs = { processID: action.processId, name: action.processName }
    const process = yield call(resource.mutateByParams, 'renameProcess', renameProcessArgs)
    yield put(setSelectedSubProcessNameAction(process.id, process.name, process.slug))
    yield put(setSelectedSubProcessNameInSelectedProcessAction(process.id, process.name, process.slug))
    yield put(addSuccessNotificationAction('model.process_renamed_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* setSelectedProcessBySlugHandler(action) {
  try {
    const process = yield call(resource.queryByParams, 'processBySlug', { slug: action.processSlug, spaceID: action.selectedSpaceId })
    yield put(setSelectedProductAction(null))
    yield put(setSelectedProcessAction(process))
    yield put(setIsDetailsPanelOpenAction(true))
    yield put(setIsForcingWorkspacePanelAction(false))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export default function* processSaga() {
  yield takeLatest(SET_SELECTED_PROCESS_BY_ID, setSelectedProcessByIdHandler)
  yield takeLatest(REMOVE_PROCESS, removeProcessHandler)
  yield takeLatest(CREATE_PROCESS, createProcessHandler)
  yield takeLatest(SEARCH_PROCESS, searchProcessHandler)
  yield takeLatest(CHANGE_PROCESS_DESCRIPTION, changeProcessDescriptionHandler)
  yield takeLatest(RENAME_PROCESS, renameProcessHandler)
  yield takeLatest(RENAME_SUB_PROCESS, renameSubProcessHandler)
  yield takeLatest(SET_SELECTED_PROCESS_BY_SLUG, setSelectedProcessBySlugHandler)
}
