export enum EDatabaseSearchColumn {
  DatasetName = 'datasetName',
  Geography = 'geography',
  Unit = 'unit',
  Database = 'database',
  Action = 'action'
}

export enum EElementaryFlowColumn {
  DatasetName = 'datasetName',
  Compartment = 'compartment',
  SubCompartment = 'subCompartment',
  Unit = 'unit',
  Action = 'action'
}
