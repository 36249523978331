import React, { useContext } from 'react'
import classnames from 'classnames'
import {
  AiOutlineCloseCircle,
  AiOutlineProfile,
  AiOutlineEdit,
} from 'react-icons/ai'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCircleNodes } from '@fortawesome/pro-regular-svg-icons'

import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Row, Divider } from 'antd'
import { withTranslation } from 'react-i18next'
import {
  handleModal,
  returnNested,
  capitalizeFirstLetter,
  floatToString
} from '../../../../../utils/tools'
import compose from '../../../../../utils/compose'
import CropLongText from '../../../../helpers/cropLongText.container'
import { PRODUCT_TYPE } from '../../../../../utils/const'
import FeatureContext from '../../../../../v1/dashboard/context/feature.context'
import { Feature } from '../../../../../v1/shared/enum/feature'
import { useDatasetNavigate } from '../../../../../v1/impact-dataset/hook/use-dataset-navigate'

const ReferenceProduct = props => {
  const {
    selectedProduct,
    isDetailsPanelReadOnly,
    productMutator,
    updatePageItems,
    t,
    removeProductReferenceSaga,
    setIsImpactSelectorDialogShowedAction
  } = props
  const { isFeatureEnabled } = useContext(FeatureContext)
  const { datasetNavigate } = useDatasetNavigate(selectedProduct)

  const productReference = returnNested(selectedProduct, 'referenceProduct')
  const hasInventory = returnNested(selectedProduct, 'hasInventory')

  const enableNewImpactDataset =  isFeatureEnabled(Feature.NewImpactDataset) || false

  const showImpactSelectorDialog = () => {
    if ( enableNewImpactDataset && selectedProduct?.id) {
      datasetNavigate()
    } else {
      setIsImpactSelectorDialogShowedAction(true)
    }
  }

  const removeProductReference = () => {
    if (!selectedProduct) {
      return
    }
    handleModal({
      title: t('model.remove_impact'),
      content: t('model.remove_impact_text'),
      onOk: () => {
        removeProductReferenceSaga(
          productMutator,
          selectedProduct.id,
          updatePageItems
        )
      }
    })
  }

  const handleClickContent = () => {
    const referenceProduct = returnNested(selectedProduct, 'referenceProduct')
    handleModal({
      title: t('model.dataset_details'),
      modalType: 'info',
      content: getReferenceProductContent(referenceProduct, false),
      okText: t('global.ok'),
      width: 800,
      onCancel: () => {},
      className: 'reference-modal-details'
    })
  }

  const getIsElementaryFlow = p => returnNested(p, 'type') === PRODUCT_TYPE.ELEMENTARY_FLOW

  const getReferenceProductContent = (productReference, isInTooltip) => {
    const isElementaryFlow = getIsElementaryFlow(productReference)
    return <React.Fragment>
      {
        productReference && <ul className='ref-list'>
          <li className='product-reference-name'>{returnNested(productReference, 'name')}</li>
          {isElementaryFlow && <li>{capitalizeFirstLetter(productReference.type).replace('_', ' ')}</li>}
          {<li>{returnNested(productReference, 'location', 'name')}</li>}

          {
            !isElementaryFlow ?
              <li>{returnNested(productReference, 'databaseName')}</li> : null
          }

          <li>{returnNested(productReference, 'parentCategory', 'name')} / {returnNested(productReference, 'category', 'name')}</li>
          <li>{`${floatToString(returnNested(productReference, 'amount'))} ${returnNested(productReference, 'referenceUnit', 'name')}  ${returnNested(productReference, 'referenceProperty', 'name')}`}</li>

        </ul>
      }

      {!isInTooltip && productReference.description && <div className='ref-description'>
        <Divider style={{ margin: '8px 0' }}/>
        { returnNested(productReference, 'description') }
      </div>
      }
    </React.Fragment>
  }

  return <div className="product-reference">
    { hasInventory ?
      <Row data-cy="linked-reference-product" className="product-reference__linked" align="middle">
        <Col className="product-reference__icon">
          {<AiOutlineProfile size={14} />}
        </Col>
        <Col className="product-reference__name">
          { t('model.from_inventory') }
        </Col>
      </Row>
      :
      <React.Fragment>
        { productReference
          ? <Row data-cy="linked-reference-product" className="product-reference__linked" align="middle">
            <Col className="product-reference__icon">
              {getIsElementaryFlow(productReference)
                ? <FontAwesomeIcon icon={faCircleNodes} className="mr-1" />
                : <FontAwesomeIcon icon={faDatabase} className="mr-1 product-reference__icon--link" />
              }
            </Col>
            <Col className="product-reference__name">
              <CropLongText
                isHtmlContentType={true}
                tooltipText={getReferenceProductContent(productReference, true)}
                className="name"
                dataCy="product-reference-name"
                onClick={handleClickContent}>
                { productReference.name }
              </CropLongText>
            </Col>
            <Col className="product-reference__actions">
              <Button
                data-cy="edit-reference"
                hidden={ isDetailsPanelReadOnly }
                onClick={ showImpactSelectorDialog }
                className={classnames('button transparent product-reference__buttons', { disabled: isDetailsPanelReadOnly })}
                data-type="Input"
                type="button"
                icon={<AiOutlineEdit size={16} />}
              />
              <Button
                data-cy="remove-reference"
                hidden={ isDetailsPanelReadOnly }
                onClick={ removeProductReference }
                className={classnames('button transparent product-reference__buttons', { disabled: isDetailsPanelReadOnly })}
                data-type="Input"
                type="button"
                icon={<AiOutlineCloseCircle size={16} />}
              />
            </Col>
          </Row>
          :
          <div className="toolbar">
            <Button
              data-cy="find-reference-product"
              hidden={ isDetailsPanelReadOnly }
              onClick={ showImpactSelectorDialog }
              className="button find-reference-product_button"
              data-type="Input"
              type="button"
              icon={<SearchOutlined />}
            >
              { t('model.addImpact') }
            </Button>
          </div>
        }
      </React.Fragment>
    }
  </div>
}

export { ReferenceProduct }
export default compose(
  withTranslation(),
)(ReferenceProduct)
