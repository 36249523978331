import { returnNested, safeArray } from './tools'

export const removeProductFromProcessRecursively = (process, productId) => {
  // Only makes sense to remove products from valid processes
  if (!returnNested(process, 'inputs') || !returnNested(process, 'outputs')) return process

  const returnValue = {
    ...process,
    inputs: process.inputs.filter(p => p.id !== productId),
    outputs: process.outputs.filter(p => p.id !== productId),
    conversions: process.conversions.map(conv => {
      return {
        ...conv,
        inputs: conv.inputs.filter(p => p.id !== productId),
        outputs: conv.outputs.filter(p => p.id !== productId)
      }
    }),
  }
  if (Array.isArray(process.processes)) {
    returnValue.processes = process.processes.map(childProcess => removeProductFromProcessRecursively(childProcess, productId))
  }
  return returnValue
}

export const renameProductsInProcessRecursively = (process, productId, newName) => {
  // Only makes sense to remove products from valid processes
  if (!returnNested(process, 'inputs') || !returnNested(process, 'outputs')) return process

  const renameProduct = p => {
    return p.id === productId ? { ...p, name: newName } : p
  }
  const returnValue = {
    ...process,
    inputs: process.inputs.map(renameProduct),
    outputs: process.outputs.map(renameProduct),
    conversions: process.conversions.map(conv => {
      return {
        ...conv,
        inputs: conv.inputs.map(renameProduct),
        outputs: conv.outputs.map(renameProduct)
      }
    }),
  }
  if (Array.isArray(process.processes)) {
    returnValue.processes = process.processes.map(childProcess => renameProductsInProcessRecursively(childProcess, productId, newName))
  }
  return returnValue
}

/**
 * @param collection array
 * @param action array
 * @returns {Object}
 */
export const getUpdatedListByActionParams = (collection, action) => {
  const updatedCollection = collection && safeArray(collection).map(el => {
    if (el.id === action.id) {
      el.name = action.name
      el.slug = action.slug
    }
    return el
  })
  return updatedCollection
}

export const getProcessWithRemovedProductsFromConversions = (process, productId) => {
  if (process.conversions && process.conversions.length) {
    process.conversions = process.conversions.map(conversion => {
      conversion.inputs = conversion.inputs.filter(el => el.product.id !== productId)
      conversion.outputs = conversion.outputs.filter(el => el.product.id !== productId)
      return conversion
    })
  }

  return process
}

/**
 * @param accountPermission object
 * @returns object
 */
export const mapAccountPermission = accountPermission => {
  const mappedAccountPermission = {
    id: accountPermission.id,
    key: accountPermission.id,
    ownerId: accountPermission.who && accountPermission.who.id,
    name: accountPermission.who && accountPermission.who.name,
    policies: safeArray(accountPermission.how).map(how => how.id),
  }

  return mappedAccountPermission
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action={}) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
