import { useQuery } from '@apollo/client'
import { LIFE_CYCLE_PAGINATED } from '../../../graphql/query'
import { PAGINATION } from '../../constants'
import { isValid } from '../../util/tools'
import { LifeCycleSortBy, LifeCycleSortField, Order } from '../../../../__generated__/graphql'

export type TLifeCycleOverviewProps = {
  spaceID?: any
  currentPage?: number
  pageSize?: number
  sortBy?: LifeCycleSortBy
}

export const useLifeCycleOverview = ({
  spaceID,
  currentPage = PAGINATION.CURRENT_PAGE,
  pageSize = PAGINATION.PAGE_SIZE,
  sortBy = { field: LifeCycleSortField.Updated, order: Order.Desc },
}: TLifeCycleOverviewProps) => {
  const {
    loading,
    data: lifeCycleData,
    refetch: refetchLifeCycle
  } = useQuery(LIFE_CYCLE_PAGINATED, {
    skip: !isValid(spaceID),
    variables: {
      spaceID, currentPage, pageSize, sortBy
    },
    fetchPolicy: 'no-cache'
  })

  return {
    loading,
    lifeCycleData,
    refetchLifeCycle
  }
}
