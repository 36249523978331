import React, { useState } from 'react'
import { IDashboardProviderValue } from '../../shared/interface/workspace-context-type'
import { AppProps } from '../../shared/interface/app-props'
import DashboardContext from '../context/dashboard.context'
import ScriptLoader from '../../../utils/ScriptLoader'
import { ExternalSources } from '../../shared/enum'
import { LocalStorage } from '../../shared/enum/storage'
import { ISpace } from '../../model'
import { store } from '../../../configureStore'
import { forceWorkspacePanelSaga } from '../../../redux/sagas/space.saga'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const getDefaultBackToList = (): string[] => {
  const localSetting = localStorage.getItem(LocalStorage.BackToList)
  return localSetting ? JSON.parse(localSetting) : []
}

export const DashboardProvider = ({ children }: AppProps) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const [ dashboard, setDashboard ] = useState<IDashboardProviderValue>({
    skipSpacePermission: true,
    backToList: getDefaultBackToList()
  })

  const updateDashboard = (newState: IDashboardProviderValue) => {
    setDashboard(prevState => ({ ...prevState, ...newState }))
  }

  const openSidebar = (sidebarComponent: React.ReactNode | null = null) => {
    updateDashboard({ openedSidebar: true, sidebarComponent })
  }

  const closeSidebar = () => {
    updateDashboard({ openedSidebar: false, sidebarComponent: null })
  }

  const openTalkToExpert = async () => {
    updateDashboard({ showTalkToExpert: true })
    const loader = new ScriptLoader({
      protocol: 'https:',
      src: ExternalSources.HubSpotScriptUrl
    })
    await loader.load()
  }

  const updateBackToList = (updatedList: string[] = []) => {
    localStorage.setItem(LocalStorage.BackToList, JSON.stringify(updatedList))

    updateDashboard({ backToList: updatedList })
  }

  const removeFromBackToList = () => {
    const { backToList = [] } = dashboard
    const backToListClone = [ ...backToList ]
    const lastBackToItem = backToListClone.pop()

    lastBackToItem && updateBackToList(backToListClone)
  }

  const addToBackToList = (backToValue: string) => {
    const { backToList = [] } = dashboard
    const updatedList = [ ...backToList, backToValue ]
    updateBackToList(updatedList)
  }

  const clearBackToList = () => {
    updateBackToList()
  }

  const openWorkspaceSetting = (space?: ISpace) => {
    if (space) {
      trackEventInSegment(TrackEventType.OPENED_SETTINGS)
      !space?.permissions && updateDashboard({ skipSpacePermission: false })
      store.dispatch(forceWorkspacePanelSaga(true))
    }
  }

  return (
    <DashboardContext.Provider value={{
      ...dashboard,
      clearBackToList,
      updateDashboard,
      openSidebar,
      closeSidebar,
      openTalkToExpert,
      removeFromBackToList,
      addToBackToList,
      openWorkspaceSetting
    }}
    >
      { children }
    </DashboardContext.Provider>
  )
}

