import React, { useContext } from 'react'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/pro-solid-svg-icons'

import { useTranslation } from 'react-i18next'
import { TProductDetailContext } from '../../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../../product/provider/context/product-detail.context'
import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component'
import { DecimalViewType } from '../../../../shared/enum'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { ComparisonEntity } from '../../../enum/entity'
import { ImpactType } from '../../../../shared/enum/impact'

export type TFooterComponentProps = {
  entity?: ComparisonEntity
}
export const TreeFooterComponent = ({
  entity = ComparisonEntity.Primary
}: TFooterComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    inventoryItems = [],
    selectedDecimalViewType,
    getImpactType = () => {},
    noInventoryImpactBar
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const {
    primaryTotalImpact,
    secondaryTotalImpact
  } = useContext<TAnalysisContext>(AnalysisContext)
  const {
    getImpactEffect = () => {},
    getImpactChangeRatio = () => {}
  } = useContext<TAnalysisContext>(AnalysisContext)
  const isPrimary = entity === ComparisonEntity.Primary
  const isPercentage = selectedDecimalViewType === DecimalViewType.PercentageValue

  const { amount: totalImpactAmount = '', unit: totalImpactUnit = '' } = (isPrimary ? primaryTotalImpact : secondaryTotalImpact) || {}
  const impactEffect = getImpactEffect(isPrimary)
  const impactPercentageChange = getImpactChangeRatio(isPrimary)
  const isImpactPositive = impactEffect === ImpactType.Positive
  const showImpactBar = inventoryItems.length > 0 && totalImpactAmount !== '0'

  return (
    <>
      <div data-cy={`tree-footer-${entity}-impact`} data-testid="tree-footer-wrapper" className="flex flex-grow-1 align-items-center justify-content-end font-medium px-2 text-base">
        <div className="flex-none flex align-items-center font-normal pr-2">{ t('labels.total') } </div>
        { totalImpactAmount && <DecimalPointComponent value={isPercentage ? '1' : totalImpactAmount} enableTooltip decimalViewType={selectedDecimalViewType} /> }
        { totalImpactUnit && <>{ totalImpactUnit }</>}
      </div>
      { impactEffect && impactPercentageChange && !isPrimary && (
        <div className="flex-none flex align-items-center justify-content-end text-gray-700 text-base h-full gap-1">
          <div
            data-testid="tree-footer-impactText"
            className={classNames('flex-none flex align-items-center white-space-nowrap', {
              'text-red-500': isImpactPositive,
              'text-green-500': !isImpactPositive
            })}
          >
            { impactPercentageChange > 0 && <>+</>}
            <DecimalPointComponent value={impactPercentageChange} enableTooltip decimalViewType={DecimalViewType.PercentageValue} />
          </div>
          <FontAwesomeIcon
            data-testid="tree-footer-impactIcon"
            icon={isImpactPositive ? faCircleArrowUp : faCircleArrowDown}
            className={classNames('flex-none align-items-center pr-2', {
              'text-red-500': isImpactPositive,
              'text-green-500': !isImpactPositive
            })}
          />
        </div>
      )}

      { !noInventoryImpactBar && (
        <div className="flex-none flex align-items-center justify-content-end text-gray-700 text-base w-11rem h-full pl-2 pr-3">
          { showImpactBar && (
            <div
              data-testid="tree-footer-bar"
              className={classNames('flex-none align-items-center h-1rem w-full border-round-sm', {
                'bg-primary-500': getImpactType() === 'positive',
                'bg-green-400': getImpactType() === 'negative'
              })}
            >
            </div>
          ) }
        </div>
      )}
    </>
  )
}
