import React, { useContext, useState } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDatabase, faCircleNodes, faPenField, faTrash, faPen
} from '@fortawesome/pro-regular-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { ImpactBarComponent } from '../impact-bar.component'
import { Status } from '../../../shared/enum/status'
import { setIsImpactSelectorDialogShowedAction } from '../../../../redux/actions/flags.actions'
import { setSelectedProductAction } from '../../../../redux/actions/global.actions'
import { store } from '../../../../configureStore'
import { IProduct } from '../../../model'
import { handleModal } from '../../../../utils/tools'
import { ReferenceProductInfoComponent } from '../reference-product-info.component'
import { CustomImpactInfoComponent } from '../custom-impact-info.component'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { DecimalViewType, ProductType } from '../../../shared/enum'
import { getImpactBarAttr } from '../../../shared/util/impact'
import { useDatasetNavigate } from '../../../impact-dataset/hook/use-dataset-navigate'
import { Feature } from '../../../shared/enum/feature'
import FeatureContext from '../../../dashboard/context/feature.context'

type TNodeProps = {
  mouseOverNode?: boolean
  clickable?: boolean
}

const Node = styled.div<TNodeProps>`
  width: '100%';
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  .line-height {
    height: 1.563rem;
  }
`

const NodeAction = styled.div<TNodeProps>`
  width: 4rem;
  visibility: ${(props: TNodeProps) => (props.mouseOverNode ? 'visible' : 'hidden')};

  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
    padding: 0;

    &.edit-button {
      color: var(--gray-500);
      border-color: var(--gray-100);
    }

    &:hover {
      color: var(--primary-500) !important;
      border-color: var(--primary-500) !important;
    }
  }
`

export type TreeImpactComponentProps = {
  node?: any
}
export const TreeImpactComponent = ({ node }: TreeImpactComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ mouseOverNode, setMouseOverNode ] = useState(false)
  const {
    totalImpact: { amount: totalImpactAmount = '' } = {},
    getMaxImpactAmount,
    updateProductDetail,
    selectedDecimalViewType,
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ labelRef, , labelDom ] = useRefOverflow()
  const { space = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableNewImpactDataset = isFeatureEnabled(Feature.NewImpactDataset) || false

  const { key, data = {} } = node || {}
  const { inventoryItem } = data || {}
  const impact = inventoryItem?.impact || {}
  const { amount: impactAmount = '', status: impactStatus = null } = impact

  const { datasetNavigate } = useDatasetNavigate(inventoryItem?.product)

  const getImpactLabelInfo = (product: IProduct) => {
    if (product?.referenceProduct) {
      const isElementaryFlow = product.referenceProduct?.type === ProductType.ElementaryFow
      const referencePropertyId = product.referenceProduct?.referenceProperty?.id
      const propertyToShow = product.productProperties?.find(
        property => property?.referenceProperty?.id === referencePropertyId
      )
      const impactPropAmount = propertyToShow?.conversionFactor || ''
      const impactPropUnit = propertyToShow?.referenceUnit?.name || ''

      return {
        isCustomImpact: false,
        impactLabel: `${impactPropAmount} ${impactPropUnit} ${product.referenceProduct.name}`,
        impactIcon: isElementaryFlow ? faCircleNodes : faDatabase,
      }
    }
    return {
      impactLabel: t('labels.customImpact', { ns: 'common' }),
      impactIcon: faPenField,
      isCustomImpact: true,
    }
  }
  const { impactLabel, impactIcon, isCustomImpact } = getImpactLabelInfo(inventoryItem?.product)

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {isCustomImpact ? (
        <CustomImpactInfoComponent space={space} />
      ) : (
        <ReferenceProductInfoComponent
          referenceProduct={inventoryItem.product.referenceProduct}
          isInTooltip
        />
      )}
    </TooltipOverflowContent>
  )
  const isImpactPending = () => impactStatus === Status.Pending

  const { impactType, impactPercent } = getImpactBarAttr({
    impactAmount,
    totalImpactAmount,
    maxImpactAmount: getMaxImpactAmount(),
  })

  const openEditImpact = (e: any) => {
    e.stopPropagation()
    // TODO : From v0.9 : To be refactored
    store.dispatch(setSelectedProductAction(inventoryItem.product))

    if (enableNewImpactDataset && inventoryItem?.product?.id) {
      datasetNavigate()
    } else {
      store.dispatch(setIsImpactSelectorDialogShowedAction(true))
    }
  }

  const removeProductReference = (e: any) => {
    e.stopPropagation()
    const updateDetails = isCustomImpact ? { showDeleteCustomImpact: true } : { showDeleteReferenceProduct: true }
    updateProductDetail(updateDetails)
  }

  const onNodeClick = () => {
    if (!isCustomImpact) {
      // TODO : From v0.9 : To be refactored
      handleModal({
        title: t('labels.datasetDetails', { ns: 'common' }),
        modalType: 'info',
        content: (
          <ReferenceProductInfoComponent referenceProduct={inventoryItem.product.referenceProduct} />
        ),
        okText: t('form.label', { ns: 'common', context: 'ok' }),
        width: 800,
        onCancel: () => {},
        className: '',
        cancelText: null,
        onOk: null,
      })
    }
  }

  return (
    <Node
      className="flex gap-2 justify-content-end w-full"
      onMouseOver={() => setMouseOverNode(true)}
      onMouseOut={() => setMouseOverNode(false)}
      data-testid="tree-impact-node"
      onClick={onNodeClick}
      clickable={!isCustomImpact}
    >
      <div className="flex-none flex align-items-center justify-content-center pl-2 line-height">
        <FontAwesomeIcon data-testid="tree-impact-node-icon" icon={impactIcon} className="ml-2 text-base text-primary-500" />
      </div>
      <div className="flex-grow-1 flex align-items-center justify-content-start overflow-hidden gap-1 line-height">
        <Tooltip target={labelDom} onBeforeShow={() => true} position="bottom">{ getTooltipContent() }</Tooltip>
        <div
          ref={labelRef}
          className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis line-height"
        >
          { impactLabel }
        </div>
      </div>
      <NodeAction
        data-testid="tree-impact-node-action"
        className="flex-none flex align-items-center justify-content-end w-4rem line-height"
        mouseOverNode={mouseOverNode}
      >
        <div className="flex flex-none w-full gap-2 justify-content-end">
          <Button
            data-testid="btn-edit-impact"
            className="flex justify-content-center p-button-outlined edit-button"
            tooltip={t('actions.edit', { context: 'impact' })}
            tooltipOptions={{ position: 'bottom' }}
            onClick={openEditImpact}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button
            data-testid="btn-delete-impact"
            className="flex justify-content-center p-button-outlined p-button-danger"
            tooltip={t('actions.delete', { context: 'impact' })}
            tooltipOptions={{ position: 'bottom' }}
            onClick={removeProductReference}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </NodeAction>
      <div className="flex-none flex h-full w-19rem gap-4 line-height">
        <div className="flex flex-grow-1 justify-content-end gap-2 white-space-nowrap overflow-hidden text-overflow-ellipsis text-right line-height">
          { isImpactPending() && (
            <div className="flex justify-content-center align-items-center">
              <i className="pi pi-spin pi-spinner text-lg text-primary-500" />
            </div>
          ) }
          <DecimalPointComponent
            value={selectedDecimalViewType === DecimalViewType.PercentageValue ? 1 : impactAmount}
            enableTooltip
            decimalViewType={selectedDecimalViewType}
          />
        </div>
        <div className="flex-none flex align-items-center justify-content-start w-10rem pr-2 line-height">
          <ImpactBarComponent nodeKey={key} impactPercent={impactPercent} impactType={impactType} />
        </div>
      </div>
    </Node>
  )
}
