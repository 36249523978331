import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { EElementaryFlowColumn } from '../enum/impact-dataset-column'

import { IElementaryFlowResult } from '../interface/impact-dataset-result'
import { transformToElementaryFlowResult } from '../util/transform'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableBodyTemplateComponent } from './table/table-body-template.component'
import { IProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TableHeaderComponent } from './table/table-header-template.component'
import { ReferenceProductType } from '../enum/reference-product'

const TableAnalysisWrapper = styled.div`
  .p-datatable {
    .p-datatable-tbody {
      tr {
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .p-datatable-emptymessage {
    display:none !important;
  }
`

type TElementaryFlowComponentProp = {
  onRowClick: (id: string) => void,
  showResults: boolean
}
export const ElementaryFlowComponent = ({
  onRowClick,
  showResults
}: TElementaryFlowComponentProp) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { referenceProducts = [] } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)
  const { selectedEntity = {} } = useContext<IDashboardContext>(DashboardContext)

  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct
  const impactDatasetResults = transformToElementaryFlowResult([
    (currentReferenceProduct?.type === ReferenceProductType.ElementaryFlow ? currentReferenceProduct : {}),
    ...referenceProducts.filter(referenceProduct => referenceProduct.id !== currentReferenceProduct?.id)
  ])

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const bodyTemplate = (rowData: IElementaryFlowResult, column: EElementaryFlowColumn) => (
    <TableBodyTemplateComponent
      value={rowData}
      column={column}
      onRowClick={onRowClick}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      isMouseOver={rowData.id === mouseOverOn}
    />
  )

  const headerTemplate = (label: string) => <TableHeaderComponent label={label} sortDisabled />

  const setRowColorForSelected = (impactDataset: IElementaryFlowResult) => {
    const isSelected = currentReferenceProduct?.id === impactDataset.id
    return { 'bg-primary-50': isSelected }
  }

  return (
    <TableAnalysisWrapper className="flex w-full">
      <TableWrapperComponent>
        <DataTable
          value={showResults ? impactDatasetResults : []}
          rowClassName={setRowColorForSelected}
        >
          <Column
            field={EElementaryFlowColumn.DatasetName}
            className="border-round-left-lg"
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.DatasetName)}
            header={headerTemplate(t('labels.elementaryFlowName'))}
          />

          <Column
            field={EElementaryFlowColumn.Compartment}
            style={{ width: '15rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Compartment)}
            header={headerTemplate(t('labels.compartment'))}
          />

          <Column
            field={EElementaryFlowColumn.SubCompartment}
            style={{ width: '15rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.SubCompartment)}
            header={headerTemplate(t('labels.compartment', { context: 'sub' }))}
          />

          <Column
            field={EElementaryFlowColumn.Unit}
            style={{ width: '5rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Unit)}
            header={headerTemplate(t('labels.unit', { ns: 'common' }))}
          />

          <Column
            field={EElementaryFlowColumn.Action}
            className="border-round-right-lg"
            style={{ width: '6.5rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Action)}
            header={() => <></>}
          />
        </DataTable>
      </TableWrapperComponent>
    </TableAnalysisWrapper>
  )
}
