import { gql } from '@apollo/client'

export default gql`
  query lifecyclesPaginated($query: String, $spaceID: ID!, $currentPage: Int, $pageSize: Int, $sortBy: LifeCycleSortBy) {
    lifecyclesPaginated(query: $query, spaceID: $spaceID, currentPage: $currentPage, pageSize: $pageSize, sortBy: $sortBy) {
      items {
        id
        name
        description
        unit
        amount
        createdAt
        updatedAt
        product {
          id
          name
        }
        scenarios {
          id
          name
        }
      }
      total
    }
  }
`
