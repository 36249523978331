import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { store } from '../../../configureStore'
import { clearRootStateExceptSettingsAction } from '../../../redux/actions/clear.actions'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'

export const SignUp = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    // TODO : Added for compatibility with v0.9
    store.dispatch(clearRootStateExceptSettingsAction())
    loginWithRedirect({
      authorizationParams: {
        action: 'signup',
      }
    })
  }, [])
  return <ProgressSpinnerComponent size={2} />
}
