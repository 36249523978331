import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { AnalysisProvider } from './provider/analysis.provider'
import { Frame } from '../../component/layout'
import { AnalysisMode, AnalysisType } from './enum/analysis'

import { useUrlQuery } from '../shared/hook/use-url-query'
import { AnalysisLayout } from './layout/analysis.layout'
import { ProductDetailContainer } from '../product/container/product-detail.container'
import { ProductDetailProvider } from '../product/provider/product-detail.provider'
import { styledComponentTheme } from '../shared/constants/theme'
import { TFeatureContext } from '../dashboard/interface/feature-context.type'
import FeatureContext from '../dashboard/context/feature.context'
import { Feature } from '../shared/enum/feature'
import { enableNewScenarioV1Var } from '../../graphql/cache'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { store } from '../../configureStore'
import { setIsDetailPanelReadonlyV1Action, setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { useIsMounted } from '../shared/hook/use-is-mounted'

type TProductAnalysisEntryProps = {
  analysisMode?: AnalysisMode
}
export const ProductAnalysisEntry = ({
  analysisMode = AnalysisMode.Comparison
}: TProductAnalysisEntryProps) => {
  const urlQuery = useUrlQuery()
  const isMounted = useIsMounted()
  const { productId: primaryEntityId } = useParams() || {}
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const analysisInScenario = analysisMode === AnalysisMode.Scenario

  useEffect(() => {
    if (isMounted()) {
      // TODO : Added for compatibility with v0.9
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }

    return () => {
      store.dispatch(setIsDetailPanelReadonlyV1Action(false))
    }
  }, [ isMounted ])

  const secondaryEntityId = urlQuery.get('id') || ''
  const secondaryEntityType = urlQuery.get('type') || ''

  const enableLegacyComparison = isFeatureEnabled(Feature.LegacyComparisonScenario) || false
  enableNewScenarioV1Var(!enableLegacyComparison && analysisInScenario)

  return (
    <>
      { !enableLegacyComparison && (
        <AnalysisProvider {...{
          primaryEntityId,
          secondaryEntityId,
          secondaryEntityType,
          analysisMode,
          analysisType: AnalysisType.ProductToProduct
        }}
        >
          <ProductDetailProvider
            readonlyInventory
            noInventoryImpactBar
            showInventoryHeader={false}
            analysisMode={analysisMode}
          >
            <ThemeProvider theme={styledComponentTheme}>
              <Frame>
                { () => primaryEntityId && secondaryEntityId
            && (
              <ProductDetailContainer productId={primaryEntityId}>
                <AnalysisLayout />
              </ProductDetailContainer>
            ) }
              </Frame>
            </ThemeProvider>
          </ProductDetailProvider>
        </AnalysisProvider>
      ) }
    </>
  )
}
