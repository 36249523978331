import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  mutation createProcess($name: String!, $spaceID: ID!, $parentID: ID ) {
    createProcess(name: $name, spaceID: $spaceID, parentID: $parentID) {
      ...processFragment
    }
  }
  ${process}
`
