import React, { useState } from 'react'

import { TImpactDatasetProvider } from '../interface/impact-dataset.context'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import ImpactDatasetContext from './context/impact-dataset.context'

type TImpactDatasetProviderProps = {
  children?: React.ReactNode
}

export const ImpactDatasetProvider = ({
  children
}: TImpactDatasetProviderProps) => {
  const [ impactDataset, setImpactDataset ] = useState<TImpactDatasetProvider>({
    selectedDatasetViewType: ImpactDatasetViewType.DatabaseSearch,
    referenceProducts: []
  })

  const updateImpactDataset = (newState: TImpactDatasetProvider) => {
    setImpactDataset(prevState => ({ ...prevState, ...newState }))
  }

  const updateFilterValue = (variables: { [key: string]: any }) => {
    updateImpactDataset({ ...variables, currentPage: 1 })
  }

  return (
    <ImpactDatasetContext.Provider value={{
      ...impactDataset,
      updateImpactDataset,
      updateFilterValue,
    }}
    >
      { children }
    </ImpactDatasetContext.Provider>
  )
}
