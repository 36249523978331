import { gql } from '@apollo/client'
import process from '../fragment/process'

export default gql`
  mutation addProcessOutput($processID: ID!, $productID: ID!) {
    addProcessOutput(processID: $processID, productID: $productID){
      ...processFragment
    }
  }
  ${process}
`
