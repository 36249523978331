import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'

import { useTranslation } from 'react-i18next'
import { IControllerRender } from '../../interface/react-form-hook'
import { PHASE_TEMPLATES } from '../../constants/phase'

const GlobalStyle = createGlobalStyle`
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-overflow: ellipsis;
  }
`

type CreateLifeCycleComponentProps = {
  entities?: any
  createLifeCycleFromProduct?: boolean,
  showCreateLifeCycleDialog?: boolean,
  closeCreateLifeCycleDialog: () => void,
  handleFilterProducts: Function,
  handleCreateLifeCycle: (variables: any) => void
}
export const CreateLifeCycleComponent = ({
  entities: lifecycleProductsOptionGroup = [],
  createLifeCycleFromProduct = false,
  showCreateLifeCycleDialog = false,
  closeCreateLifeCycleDialog,
  handleCreateLifeCycle,
  handleFilterProducts
}: CreateLifeCycleComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const lifecycleTemplateOptions = PHASE_TEMPLATES.map(template => ({ label: template.name, value: template }))
  const defaultValues = {
    name: '', attach: !createLifeCycleFromProduct, product: null, template: lifecycleTemplateOptions[0].value
  }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })
  const [ selectedPhaseTemplate, setSelectedPhaseTemplate ] = useState(lifecycleTemplateOptions[0].value)
  const [ attachProduct, setAttachProduct ] = useState(!createLifeCycleFromProduct)

  useEffect(() => {
    reset()
  }, [ showCreateLifeCycleDialog ])

  const onSubmit = (data: any) => {
    const {
      name, attach, product, template
    } = data
    setAttachProduct(!createLifeCycleFromProduct)
    handleCreateLifeCycle({
      name,
      productID: attach ? product.id : null,
      phases: template.phaseList
    })
  }

  const onHide = () => {
    reset()
    setAttachProduct(!createLifeCycleFromProduct)
    closeCreateLifeCycleDialog()
  }

  const handleFilter = (event: any) => {
    handleFilterProducts(event.filter)
  }

  return (
    <>
      <GlobalStyle />
      <Dialog
        className="w-30rem"
        data-testid="create-lifeCycle-dialog"
        header={t('actions.headerCreateNew', { context: 'LifeCycle' })}
        visible={showCreateLifeCycleDialog}
        draggable={false}
        dismissableMask
        onHide={onHide}
      >
        <div className="w-full pb-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12 p-0">
              <label htmlFor="name" className="text-sm">
                {t('labels.lifecycle', { context: 'name' })}
              </label>
              <Controller
                name="name"
                control={control}
                rules={{ required: true, minLength: 2 }}
                render={({ field, fieldState }: IControllerRender) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    data-testid="create-lifeCycle-nameComp"
                    data-cy="create-lifecycle-name"
                    placeholder={t('labels.lifecycle', { context: 'name' })}
                    className={classNames('w-full', { 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {
                errors?.name
              && <small className="p-error">Life cycle name is required</small>
              }
            </div>
            { !createLifeCycleFromProduct && (
              <div className="col-12 px-0 pt-3">
                <Controller
                  name="attach"
                  control={control}
                  render={({ field, fieldState }: IControllerRender) => (
                    <Checkbox
                      inputId={field.name}
                      data-testid="create-lifeCycle-attachComp"
                      data-cy="attach-product-switch"
                      onChange={(e: any) => {
                        setAttachProduct(e.checked)
                        field.onChange(e.checked)
                      }}
                      checked={field.value}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
                <label htmlFor="attach" className="px-2 text-sm">
                  {t('labels.lifecycle', { context: 'attachProduct' })}
                </label>
              </div>
            )}
            { attachProduct && (
              <div className="col-12 px-0">
                <label htmlFor="product" className={classNames('text-sm', { 'p-error': errors.product })}>
                  {t('labels.product', { count: 1 })}
                </label>
                <Controller
                  name="product"
                  control={control}
                  rules={{ required: 'Product is required' }}
                  render={({ field, fieldState }: IControllerRender) => (
                    <Dropdown
                      filter
                      showClear
                      id={field.product}
                      value={field.value}
                      data-testid="create-lifeCycle-productComp"
                      data-cy="new-product-name-autocomplete"
                      placeholder={t('labels.product', { count: 1 })}
                      className={classNames('w-full', { 'p-invalid': fieldState.error })}
                      panelClassName="w-20rem"
                      onChange={(event: any) => field.onChange(event.value)}
                      onFilter={handleFilter}
                      options={lifecycleProductsOptionGroup}
                      optionLabel="label"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                    />
                  )}
                />
                {
                  errors?.product
              && <small className="p-error">{ errors?.product.message }</small>
                }
              </div>
            )}

            <div className="col-12 px-0">
              <label htmlFor="template" className={classNames('text-sm', { 'p-error': errors.template })}>
                {t('labels.lifecycle', { context: 'template' })}
              </label>
              <Controller
                name="template"
                control={control}
                rules={{ required: 'Product is required' }}
                render={({ field, fieldState }: IControllerRender) => (
                  <Dropdown
                    id={field.template}
                    value={field.value}
                    data-testid="create-lifeCycle-templateComp"
                    data-cy="create-lifecycle-phase-template"
                    placeholder={t('labels.lifecycle', { context: 'template' })}
                    className={classNames('w-full', { 'p-invalid': fieldState.error })}
                    onChange={(event: any) => {
                      setSelectedPhaseTemplate(event.value)
                      field.onChange(event.value)
                    }}
                    onFilter={handleFilter}
                    options={lifecycleTemplateOptions}
                  />
                )}
              />
              {
                errors?.template
              && <small className="p-error">{ errors?.template.message }</small>
              }
            </div>

            <div className="col-12 px-0">
              <label htmlFor="template" className={classNames('text-sm', { 'p-error': errors.template })}>
                {t('labels.phases')}
              </label>
              <ul>
                { selectedPhaseTemplate.phaseList.map((phase: any, index: number) => <li key={`selected-phase-${index}`}>{ phase.name }</li>)}
              </ul>
            </div>

            <div className="col-12 pt-6 px-0 flex justify-content-end">
              <div className="flex-none">
                <Button
                  type="submit"
                  data-testid="create-lifeCycle-submit"
                  data-cy="create-lifecycle-with-phase-template"
                  label={t('labels.create')}
                  iconPos="left"
                  loading={false}
                  className="p-button-primary justify-content-end"
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  )
}
