export enum Navigation {
    Root = '/',
    SignUp = '/signup',
    LoginCallback = '/callback',
    FailedAuthorization = '/authorization-failed',
    ResetPasswordEmail = '/password-reset/:email', // TODO: Remove this one as this is an old route
    Logout = '/logout',
    ResetPassword = '/reset-password',
    HelpCenter = '/help-center',
    Changelog = '/changelog',
    Dashboard = '/dashboard',
    Account = '/account',
    AccountDetail = '/account/:accountId',
    Workspace = '/account/:accountId/workspace',
    WorkspaceDetail = '/account/:accountId/workspace/:workspaceSlug',
    ProductOverview = '/account/:accountId/workspace/:workspaceSlug/products',
    ProductOverviewFolder = '/account/:accountId/workspace/:workspaceSlug/products/?folderId=:folderId',

    LifeCycleOverview = '/account/:accountId/workspace/:workspaceSlug/lifecycles',
    LifeCycleDetail = '/account/:accountId/workspace/:workspaceSlug/lifecycle/:lifecycleId/detail',
    LifeCycleDetailTabs = '/account/:accountId/workspace/:workspaceSlug/lifecycle/:lifecycleId/detail/?activeTabKey=:activeTab',
    LifeCycleInventory = '/account/:accountId/workspace/:workspaceSlug/lifecycle/:lifecycleId/inventory',
    LifeCycleScenario = '/account/:accountId/workspace/:workspaceSlug/lifecycle/:lifecycleId/inventory/?lifecycleScenarioId=:scenarioId',

    ObjectOverview = '/account/:accountId/workspace/:workspaceSlug/objects',
    ObjectOverviewFolder = '/account/:accountId/workspace/:workspaceSlug/objects/?folderId=:folderId',

    ProductObjectScenario = '/account/:accountId/workspace/:workspaceSlug/product/:productId/inventory/?productScenarioId=:scenarioId',
    ProductObjectComparison = '/account/:accountId/workspace/:workspaceSlug/product/:productId/inventory/?activeTabKey=:activeTabKey&compareEntityType=:compareEntityType&compareEntityId=:compareEntityId',
    ProductComparison = '/account/:accountId/workspace/:workspaceSlug/product/:productId/compare/',
    ProductComparisonTabs = '/account/:accountId/workspace/:workspaceSlug/product/:productId/compare/?type=Product&activeTabKey=:activeTab&id=:secondaryProductId',
    ProductScenario = '/account/:accountId/workspace/:workspaceSlug/product/:productId/scenario/',
    ProductScenarioTabs = '/account/:accountId/workspace/:workspaceSlug/product/:productId/scenario/?activeTabKey=:activeTab',
    ProductObjectInventory = '/account/:accountId/workspace/:workspaceSlug/product/:productId/inventory',
    ProductObjectInventoryTabs = '/account/:accountId/workspace/:workspaceSlug/product/:productId/inventory/?activeTabKey=:activeTab',
    ImpactDataset ='/account/:accountId/workspace/:workspaceSlug/impact-dataset/:inventoryItemId',

    Templates = '/templates',
    AccountSetting = '/accountsettings',
    AccountSettingBilling = '/accountsettings?activeTabKey=billingManagement',
    UserProfile = '/profile',
    CSV_T_SHIRT = '/importexamples/t-shirt.csv',
    CSV_LIST_OBJECTS = '/importexamples/list-objects.csv',
    CSV_LIST_PRODUCTS = '/importexamples/list-products.csv',
    CSV_TEMPLATE_DEMO = '/importexamples/template-demo.csv'
}

export enum ExternalSources {
    HubSpotScriptUrl = 'static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
    HubSpotCalendarForTrialUrl = 'https://meetings-eu1.hubspot.com/ecochain-helpdesk?embed=true',
    HubSpotCalendarForPaidUrl = 'https://meetings-eu1.hubspot.com/ecochain-helpdesk/mobius-specialist-call?embed=true',
    HelpCenterKnowledgeBase = 'https://helpcenter.ecochain.com/',
    HelpCenterMobius = 'https://helpcenter.ecochain.com/',
    HelpCenterChangelog = 'https://helpcenter.ecochain.com/mobius-updates',
    HelpCenterLifecycle = 'https://helpcenter.ecochain.com/step-2-how-to-create-a-products-life-cycle',
    HelpCenterProduct = 'https://helpcenter.ecochain.com/ecodesign/step-1-how-to-model-a-product-in-mobius',
    HelpCenterTwoFactor = 'https://helpcenter.ecochain.com/2-factor-authentication',
    HelpCenterBeginnerGuid = 'https://ecochain.com/knowledge/life-cycle-assessment-lca-guide/#who-needs-LCA',
    HelpCenterGettingStarted = 'https://www.youtube.com/playlist?list=PLUgfnuxtyMiqCBQ5QqWXQcsc7iH-6YnRZ',
    HelpCenterLCADatabases = 'https://helpcenter.ecochain.com/learn-about-lca-methods-and-databases-mobius/helix',
    HelpCenterNextSteps = 'https://www.youtube.com/playlist?list=PLUgfnuxtyMip066gd8KZ-9hqNYrwDGVFg',
    HelpCenterFundamentals = 'https://www.youtube.com/playlist?list=PLUgfnuxtyMionF3O1Zrwlt9My8rJZUidU',
    HelpCenterExercise = 'https://helpcenter.ecochain.com/mobius-exercise-apple-pie',
    HelpCenterTakeATour = '?appcue=36859032-ce2a-4d7c-80f7-b4b016ce8edd',
    EcoChainTranslator = 'https://translator.ecochain.com/',
    IntercomApiBaseUrl = 'https://api-iam.intercom.io',
    HelpCenterMobiusFreeCourse = 'https://academy.ecochain.com/course/getting-started-with-product-footprinting-for-business',
    HelpCenterAddingImpact = 'https://helpcenter.ecochain.com/en/collections/9679001-adding-impact',
}

export enum Environment {
    dev = 'dev',
    prd = 'prd',
    tst = 'tst',
    acc = 'acc',
    unknown = 'unknown'
}
