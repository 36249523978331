import React, { useContext } from 'react'
import styled from 'styled-components'

import { LifecycleHeaderComponent } from '../component/lifecycle-header.component'
import { LifecycleTaskbarComponent } from '../component/lifecycle-taskbar.component'

import { CardViewLayout } from './card-view.layout'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { ViewType } from '../../shared/enum'
import { TableViewLayout } from './table-view.layout'

const LifecycleWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`

export const LifecycleOverviewLayout = () => {
  const { selectedViewType } = useContext<TLifecycleContext>(LifecycleContext)
  return (
    <LifecycleWrapper data-testid="lifecycle-overview-wrapper" className="flex w-full h-screen flex-column">
      <div data-testid="lifecycle-overview-header" className="flex flex-none w-full h-7rem px-6 py-5">
        <LifecycleHeaderComponent />
      </div>
      <div data-testid="lifecycle-overview-taskbar" className="flex flex-none w-full h-3rem px-6 py-2">
        <LifecycleTaskbarComponent />
      </div>
      <div data-testid="lifecycle-overview-body" className="flex flex-none w-full px-5 py-3 flex-column">
        { selectedViewType === ViewType.Grid ? <CardViewLayout /> : <TableViewLayout /> }
      </div>
    </LifecycleWrapper>
  )
}
