import React, {
  useContext, useEffect, useRef
} from 'react'
import { classNames } from 'primereact/utils'

// import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OverlayPanel } from 'primereact/overlaypanel'
import { useReactiveVar } from '@apollo/client'

import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { DatabaseFilterListComponent } from './database-filter-list.component'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { getSelectedDatabasesStorageItem } from '../../../shared/util/impact'

type TDatabaseFilterComponent = {
  label?: string
}

export const DatabaseFilterComponent = ({ label }: TDatabaseFilterComponent) => {
  const { space: selectedSpace } = useReactiveVar(selectedWorkspaceVar) || {}
  const overlayPanel = useRef<OverlayPanel>(null)
  const {
    databaseFilterItems = [],
    hasActiveDatabaseFilter = false,
    updateImpactDataset
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { databases: defaultSelectedDatabases = [] } = selectedSpace || {}

  useEffect(() => {
    setDefaultFilterValues()
  }, [ defaultSelectedDatabases ])

  const setDefaultFilterValues = () => {
    if (hasActiveDatabaseFilter) return

    const storedDatabaseItems = getSelectedDatabasesStorageItem() || []
    const selectedDatabases = storedDatabaseItems?.length > 0
      ? storedDatabaseItems
      : defaultSelectedDatabases
    updateImpactDataset({ databaseFilterItems: selectedDatabases })
  }

  return (
    <>
      <OverlayPanel ref={overlayPanel}>
        <div className="flex flex-column w-full">
          <div className="flex w-full pb-3 line-height-1 text-sm font-medium border-bottom-1 border-gray-100">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
          </div>
          <div className="flex align-items-center pt-3">
            <DatabaseFilterListComponent />
          </div>
        </div>
      </OverlayPanel>

      <span
        data-testid="table-header-database-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {
          'text-primary-500': databaseFilterItems.length > 0
        })}
      >
        { label }
        <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
      </span>
    </>
  )
}
