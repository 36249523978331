import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

type TTrialExtendBannerProps = {
  upgradeOnly: boolean,
  extendTrial: () => void,
  upgradeNow: () => void,
  canUpgrade: () => boolean,
  canExtendTrial: () => boolean,
  isOnBillingPage: boolean
}

export const TrialExtendBanner = ({
  upgradeOnly,
  extendTrial,
  upgradeNow,
  canUpgrade,
  canExtendTrial,
  isOnBillingPage
}: TTrialExtendBannerProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])

  return (
    <div className="flex justify-content-center w-full">
      <div data-testid="import-warning-summary" className="text-base font-semibold py-1 line-height-2">
        { t('messages.infoSummary', { context: 'extendTrial' }) }
      </div>
      <div data-testid="import-warning-detail" className="text-sm px-3 line-height-4">
        { !upgradeOnly && <>{ t('messages.infoDetail', { context: 'extendOrUpgrade' }) }</>}
        { upgradeOnly && <>{ t('messages.infoDetail', { context: 'upgradeOnly' }) }</>}
      </div>
      <div className="flex h-2rem">
        { !upgradeOnly && canExtendTrial() && <Button label={t('actions.extendTrial')} onClick={extendTrial} className="p-button-sm p-button-outlined p-button-info mx-2 h-full" />}
        { !isOnBillingPage && canUpgrade() && <Button label={t('actions.upgradeNow')} onClick={upgradeNow} className="p-button-sm p-button-info h-full mx-2" />}
      </div>
    </div>
  )
}
