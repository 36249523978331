import React, { useContext } from 'react'
import styled from 'styled-components'
import { Chip } from 'primereact/chip'
import { Button } from 'primereact/button'

import { useTranslation } from 'react-i18next'
import { Category, LciDatabase } from '../../../../__generated__/graphql'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../../shared/util/impact'

const FilterChipsWrapper = styled.div`
  .p-chip {
    max-width: 15rem;
  }

  .p-chip-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .p-chip-remove-icon {
    padding-top: 0.15rem;
    font-size: 0.75rem;
  }

  .p-chip-remove-icon:focus {
    box-shadow: none;
  }
`
export const FilterChipsComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    updateFilterValue,
    categoryFilterItems = [],
    databaseFilterItems = [],
    locationQuery
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const removeCategoryFilterItem = (categoryToRemove: Category) => {
    const categoryToRemoveIndex = categoryFilterItems.findIndex(categoryFilterItem => categoryFilterItem.selectedCategory?.id === categoryToRemove.id)

    categoryFilterItems[categoryToRemoveIndex] = {
      ...categoryFilterItems[categoryToRemoveIndex],
      selectedCategory: null
    }
    const updatedFilterItems = categoryFilterItems
      .filter((value, index) => value && index <= categoryToRemoveIndex)
    updateFilterValue({ categoryFilterItems: updatedFilterItems })
  }

  const removeDatabaseFilterItem = (database: LciDatabase) => {
    const selectedDatabases = [ ...databaseFilterItems ]
    selectedDatabases.splice(selectedDatabases.findIndex(elt => elt.id === database.id), 1)
    updateFilterValue({ databaseFilterItems: selectedDatabases, hasActiveDatabaseFilter: true })
    setSelectedDatabasesStorageItem(selectedDatabases)
  }

  const clearLocationQuery = () => {
    const locationQuery = ''
    updateFilterValue({ locationQuery })
  }

  const clearFilters = () => {
    const updatedFilterItems = [
      { ...categoryFilterItems[0], selectedCategory: null }
    ]

    setSelectedDatabasesStorageItem([])
    updateFilterValue({ categoryFilterItems: updatedFilterItems, databaseFilterItems: [], locationQuery: '' })
  }

  return (
    <FilterChipsWrapper className="flex w-full flex-wrap gap-1">
      { categoryFilterItems.map((categoryFilterItem, index) => {
        const { selectedCategory } = categoryFilterItem
        return (
          <div key={`chip-filter-category-${index}`} className="flex">
            { selectedCategory
            && (
              <Chip
                removable
                label={selectedCategory.name}
                onRemove={() => removeCategoryFilterItem(selectedCategory)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            ) }
          </div>
        )
      })}

      { databaseFilterItems.map((databaseFilterItem, index) => (
        <div key={`chip-filter-database-${index}`} className="flex">
          { databaseFilterItem
          && (
            <Chip
              removable
              label={databaseFilterItem.name}
              onRemove={() => removeDatabaseFilterItem(databaseFilterItem)}
              className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
            />
          ) }
        </div>
      ))}

      { locationQuery && (
        <Chip
          removable
          label={locationQuery}
          onRemove={clearLocationQuery}
          className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
        />
      )}
      <Button className="p-button-outlined p-button-plain text-sm h-2rem px-1" onClick={clearFilters}>
        <span className="ml-1">{ t('labels.clearFilters') }</span>
      </Button>
    </FilterChipsWrapper>
  )
}
